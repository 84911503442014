import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { AvatarModule } from "primeng/avatar";
import { MenuModule } from "primeng/menu";
import { FieldsetModule } from "primeng/fieldset";
import { MessageModule } from "primeng/message";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { COMPONENTS } from "./components";
import { SharedModule } from "@app/@shared/shared.module";
import { SkeletonModule } from "primeng/skeleton";
import { SelectButtonModule } from "primeng/selectbutton";
import { ChipsModule } from "primeng/chips";
import { TableModule } from "primeng/table";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { InplaceModule } from "primeng/inplace";
import { DataViewModule } from "primeng/dataview";
import { AvatarGroupModule } from "primeng/avatargroup";
import { AccordionModule } from "primeng/accordion";
import { PanelModule } from "primeng/panel";
import { TimelineModule } from "primeng/timeline";
import { SidebarModule } from "primeng/sidebar";
import { DividerModule } from "primeng/divider";
import { CheckboxModule } from "primeng/checkbox";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ProgressBarModule } from "primeng/progressbar";
import { DialogModule } from "primeng/dialog";
import { BadgeModule } from "primeng/badge";
import { SliderModule } from "primeng/slider";
import { InputSwitchModule } from "primeng/inputswitch";
import { RippleModule } from "primeng/ripple";
import { DropdownModule } from "primeng/dropdown";
import { TagModule } from "primeng/tag";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    // PrimeNG modules
    AccordionModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipsModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DataViewModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    InplaceModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessageModule,
    PanelModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RippleModule,
    SelectButtonModule,
    SkeletonModule,
    SidebarModule,
    SliderModule,
    TableModule,
    TagModule,
    TimelineModule,
    ToastModule,
    TooltipModule.forRoot(),

    // CustomModules
    SharedModule,
  ],
  declarations: [COMPONENTS],
  exports: [
    // PrimeNG modules
    AccordionModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CheckboxModule,
    ChipsModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    InplaceModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MessageModule,
    PanelModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RippleModule,
    SelectButtonModule,
    SkeletonModule,
    SidebarModule,
    SliderModule,
    TableModule,
    TagModule,
    TimelineModule,
    ToastModule,
    TooltipModule,

    // Custom exports
    COMPONENTS,
  ],
})
export class UiModule {}
