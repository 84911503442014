import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import PublicKeyModel from "@shared/models/vault/public-key.model";
import { ChannelEnum } from "@shared/enums/channel.enum";
import WrappedKeyModel from "@shared/models/vault/wrapped-key.model";
import PairModel from "@shared/models/pair.model";

@Injectable({
  providedIn: "root",
})
export class KeyvaultService {
  private _baseUrl: string = environment.services.baseUrls.keyvaultApiUrl;

  wrappedKeys: PairModel[] = [];
  publicKeys: PairModel[] = [];

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  initSession(): Observable<Boolean> {
    let responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.NOTHING];

    const url = this._baseUrl + environment.services.methodUrls.session.initSession;
    const correlationId = v4();

    this.loaderService.addOperation("keyVaultInitSession");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map(() => {
          return true;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("keyVaultInitSession");
        }),
      );
  }

  getLocalRecordPublicKey(recordIdentifier: string): PublicKeyModel {
    let pairKeys = this.publicKeys.find((item) => {
      return item.key === recordIdentifier;
    });

    if (pairKeys && pairKeys.value.length > 0) {
      return pairKeys.value[0];
    } else {
      return null;
    }
  }

  containLocalRecordPublicKey(recordIdentifier: string): boolean {
    let pairKeys = this.publicKeys.find((item) => {
      return item.key === recordIdentifier;
    });

    return pairKeys != null;
  }

  getLocalRecordWrappedKeys(recordIdentifier: string): WrappedKeyModel[] {
    let pairKeys = this.wrappedKeys.find((item) => {
      return item.key === recordIdentifier;
    });

    return pairKeys.value;
  }

  containLocalRecordWrappedKeys(recordIdentifier: string) {
    let pairKeys = this.wrappedKeys.find((item) => {
      return item.key === recordIdentifier;
    });

    return pairKeys != null;
  }

  getRecordPublicKeys(
    recordIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<PublicKeyModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.keyvault.getRecordPublicKeys;
    const correlationId = v4();

    this.loaderService.addOperation("getRecordPublicKeys");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (!response["keys"]) return null;

          let publicKeys: PublicKeyModel[] = response["keys"].map((key: any) => {
            return new PublicKeyModel().deserialize(key);
          });

          let pairKeys: PairModel = new PairModel();
          pairKeys.key = recordIdentifier;
          pairKeys.value = publicKeys;

          this.publicKeys.push(pairKeys);

          return publicKeys;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordPublicKeys");
        }),
      );
  }

  getRecordWrappedPrivateKeys(
    recordIdentifier: string,
    encryptedToken: string,
    channel: ChannelEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<WrappedKeyModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.keyvault.getRecordWrappedPrivateKeys;
    const correlationId = v4();

    this.loaderService.addOperation("getRecordWrappedPrivateKeys");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          encryptedToken: encryptedToken,
          channel: channel,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (!response["keys"]) return null;

          let wrappedKeys: WrappedKeyModel[] = response["keys"].map((key: any) => {
            return new WrappedKeyModel().deserialize(key);
          });

          let pairKeys: PairModel = new PairModel();
          pairKeys.key = recordIdentifier;
          pairKeys.value = wrappedKeys;

          this.wrappedKeys.push(pairKeys);

          return wrappedKeys;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordWrappedPrivateKeys");
        }),
      );
  }

  sendUserToken(
    channel: ChannelEnum,
    forceRefresh: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.keyvault.sendUserToken;
    const correlationId = v4();

    this.loaderService.addOperation("sendUserToken");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          channel: channel,
          forceRefresh: forceRefresh,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("sendUserToken");
        }),
      );
  }
}
