import BaseModel from "@shared/models/base.model";
import UserModel from "@shared/models/user/user.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { RecordEventCodeEnum } from "@app/@shared/enums/record-event-code.enum";

export default class RecordEventModel extends BaseModel {
  // MINIMIZE (default)
  public code: RecordEventCodeEnum = RecordEventCodeEnum.CREATED;
  public statusDate: Date | null = null;

  public user: UserModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.statusDate) this.statusDate = DateHelper.parseDate(input.statusDate);

    if (input.user) this.user = new UserModel().deserialize(input.user);

    return this;
  }

  isForUser(userIdentifier: string): boolean {
    return this.user && this.user.userIdentifier === userIdentifier;
  }
}
