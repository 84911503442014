import { UserRoleEnum } from "@app/@shared/enums/user-role.enum";
import { DateHelper } from "@shared/helpers/date.helper";
import BaseModel from "@shared/models/base.model";
import DomainModel from "@shared/models/domain/domain.model";
import UnitModel from "@shared/models/domain/unit.model";
import UserModel from "@shared/models/user/user.model";

export default class SessionModel extends BaseModel {
  public sessionIdentifier: string | null = null;
  public userRole: UserRoleEnum | null = null;
  public lastConnection: Date | null = null;
  public locale: string | null = null;

  public domain: DomainModel | null = null;
  public unit: UnitModel | null = null;
  public user: UserModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.lastConnection) this.lastConnection = DateHelper.parseDate(input.lastConnection);

    if (input.domain) this.domain = new DomainModel().deserialize(input.domain);
    if (input.unit) this.unit = new UnitModel().deserialize(input.unit);
    if (input.user) this.user = new UserModel().deserialize(input.user);

    return this;
  }
}
