import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { environment } from "@env/environment";
import UserModel from "@shared/models/user/user.model";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { UserService } from "@shared/services/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "main-consents-panel-view",
  templateUrl: "./consents-panel.view.html",
  styleUrls: ["./consents-panel.view.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentsPanelView implements OnInit {
  @Input() showConsents: boolean;

  @Output() userChange = new EventEmitter<UserModel>();
  @Input()
  set user(user: UserModel) {
    this._user = user;
  }
  get user(): UserModel {
    return this._user;
  }
  private _user: UserModel = new UserModel();

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {}

  doUpdateUser(): void {
    this.userService.updateUser(this._user, [ResponseLevelEnum.USER_DETAILS]).subscribe((user) => {
      this._user = user;
      this.userChange.emit(this._user);
      this.showConsents = false;
    });
  }

  doAcceptAll() {
    this._user.properties["consent-debug-logs"] = true;
    this._user.properties["consent-newsletters"] = true;
    this._user.properties["consent-analytics"] = true;

    this.doUpdateUser();
  }
  doClose() {
    this.showConsents = false;
  }

  doEditProfile() {
    this.userService.updateUser(this._user, [ResponseLevelEnum.USER_DETAILS]).subscribe((user) => {
      this._user = user;
      this.userChange.emit(this._user);
      this.showConsents = false;

      this.router.navigate(["user-profile"]);
    });
  }
}
