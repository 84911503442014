<a
  data-button="noLabel"
  pButton
  [label]="null"
  [routerLink]="disabled ? null : ['/record-summary/', _cell.cellType.toLowerCase()]"
  [routerLinkActive]="['surface-0', 'text-primary', 'border-400']"
  data-attr="record-summary-cell"
  class="cell-item p-button-text p-button-plain no-underline w-full border-200 md:border-50 hover:surface-0 hover:border-400 hover:text-primary flex justify-content-between"
  [ngClass]="{ 'cell-item--disabled': disabled }"
>
  <div class="white-space-nowrap flex justify-content-between flex-grow-1">
    <span>
      {{ "RECORD.cell-types." + _cell.cellType + ".label" | translate: { "default": _cell.cellType } }}
    </span>
    <p-badge [value]="_cell.cellValue"></p-badge>
  </div>
</a>
