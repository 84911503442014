import { Component, OnInit } from "@angular/core";
import { UntilDestroy } from "@core";

@UntilDestroy()
@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.layout.html",
  styleUrls: ["./home-page.layout.scss"],
})
export class HomePageLayout implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
