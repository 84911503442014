import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { SessionService } from "./session.service";
import { UserRoleEnum } from "../enums/user-role.enum";
import UserUnitModel from "../models/user/user-unit.model";
import UserModel from "../models/user/user.model";
import { BehaviorSubject, filter, Observable, tap } from "rxjs";
import { BillingService } from "./billing.service";
import { BillingAccountScopeEnum } from "../enums/billing-account-scope.enum";
import { KeycloakService } from "keycloak-angular";
import SessionModel from "../models/session/session.model";
import { some } from "lodash";
import { KeycloakClientRoleEnum } from "../enums/keycloak/keycloak-client-role-enum";
import { KeycloakRealmRoleEnum } from "../enums/keycloak/keycloak-realm-role-enum";

const log = new Logger("AuthorizationService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  // current user unit observable
  private _currentUserUnit$: BehaviorSubject<UserUnitModel> = new BehaviorSubject(undefined);
  currentUserUnit$: Observable<UserUnitModel> = this._currentUserUnit$.asObservable();

  get currentUserUnit() {
    return this._currentUserUnit$.getValue();
  }
  set currentUserUnit(user: UserUnitModel) {
    this._currentUserUnit$.next(user);
  }

  // keycloak user roles observable
  private _keycloakUserRoles$: BehaviorSubject<string[]> = new BehaviorSubject(undefined);
  keycloakUserRoles$: Observable<string[]> = this._keycloakUserRoles$.asObservable();

  get keycloakUserRoles() {
    return this._keycloakUserRoles$.getValue();
  }

  constructor(
    private keycloakService: KeycloakService,
    private sessionService: SessionService,
    private billingService: BillingService,
  ) {
    // Initialize watching session to set _currentUserUnit$ value
    this.sessionService.session$
      .pipe(
        filter((session: SessionModel) => Boolean(session)),
        tap((session: SessionModel) => {
          let userUnit = session.user.units.find(
            (u: UserUnitModel) => session.unit.unitIdentifier === u.unit.unitIdentifier,
          );

          this._currentUserUnit$.next(userUnit);
        }),
        tap((session: SessionModel) => {
          this._keycloakUserRoles$.next(this.keycloakService.getUserRoles(true));
        }),
        untilDestroyed(this),
      )
      .subscribe((session: SessionModel) => {});
  }

  /**
   * Keycloak roles related authorizations
   */
  hasKeycloakRole(role: KeycloakClientRoleEnum | KeycloakRealmRoleEnum): boolean {
    return this.keycloakUserRoles.includes(role);
  }

  hasAnyKeycloakRole(roles: (KeycloakClientRoleEnum | KeycloakRealmRoleEnum)[]): boolean {
    return some(roles, (role: KeycloakClientRoleEnum | KeycloakRealmRoleEnum) => this.hasKeycloakRole(role));
  }

  /**
   * Unit related authorizations
   */
  canManageUnit(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return userUnit?.userRole && [UserRoleEnum.OWNER, UserRoleEnum.MANAGER].includes(userUnit.userRole);
  }

  canCreateChildUnit(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    if (userUnit?.unit?.isPublic) {
      return userUnit.userRole && [UserRoleEnum.OWNER, UserRoleEnum.MANAGER].includes(userUnit.userRole);
    } else {
      return userUnit?.userRole && UserRoleEnum.USER === userUnit?.userRole;
    }
  }

  canInviteUnitUser(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return this.canManageUnit(userUnit);
  }

  canUpdateUnitUserRole(user: UserModel, userUnit: UserUnitModel = this.currentUserUnit): boolean {
    // User cannot remove itself from unit
    const isCurrentUser = this.sessionService.session?.user?.userIdentifier === user.userIdentifier;

    return this.canManageUnit(userUnit) && !isCurrentUser;
  }

  /**
   * Billing account related authorizations
   */
  canManageBillingAccount(): boolean {
    return this.billingService.billingAccount?.scope === BillingAccountScopeEnum.OWNER;
  }

  canInviteBillingAccountUser() {
    return this.canManageBillingAccount();
  }
}
