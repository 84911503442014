import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "ui-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnInit {
  @Input() size: number = 40;
  @Input() strokeWidth: string = "4";

  @HostBinding("style.--color")
  @Input()
  color: string;

  constructor() {}

  ngOnInit(): void {}
}
