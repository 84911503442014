import { Component, OnInit } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { ActivatedRoute } from "@angular/router";
import { RecordService } from "@shared/services/record.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { UntilDestroy, untilDestroyed } from "@core";
import { combineLatest, filter, finalize, first, mergeMap, of, switchMap, throwError } from "rxjs";
import { SessionService } from "@shared/services/session.service";
import { catchError, tap } from "rxjs/operators";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import { KeycloakService } from "keycloak-angular";

@UntilDestroy()
@Component({
  selector: "app-oneway-view",
  templateUrl: "./oneway-view.layout.html",
  styleUrls: ["./oneway-view.layout.scss"],
})
export class OnewayViewLayout implements OnInit {
  record: RecordModel;

  isLoading: boolean = false;
  loadingError: any = null;

  constructor(
    private route: ActivatedRoute,
    public sessionService: SessionService,
    private recordService: RecordService,
    private keycloakService: KeycloakService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.loadingError = null;

    combineLatest([this.route.params, this.sessionService.session$])
      .pipe(
        filter(([params, session]) => Boolean(session)),
        switchMap(([params]) => {
          let recordIdentifier = params["recordIdentifier"];
          return this.recordService.getRecord(recordIdentifier, true, [ResponseLevelEnum.ALL]);
        }),
        catchError((e) => {
          this.loadingError = {
            title: "ERRORS." + e.error.code + ".title",
            subtitle: "ERRORS." + e.error.code + ".subtitle",
          };
          this.isLoading = false;
          return throwError(() => e);
        }),
        tap(() => (this.isLoading = false)),
        untilDestroyed(this),
      )
      .subscribe((record) => {
        this.record = record;
      });
  }

  doLogout(): void {
    this.keycloakService.logout();
  }
}
