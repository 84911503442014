import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { SessionService } from "@shared/services/session.service";
import SessionModel from "@shared/models/session/session.model";

@Component({
  selector: "session-user-role-card",
  templateUrl: "./user-role-card.component.html",
  styleUrls: ["./user-role-card.component.scss"],
})
/**
 * This component displays informations on the current user.
 */
export class UserRoleCardComponent implements OnInit, OnDestroy {
  @Input() session: SessionModel;
  @Input() showRole: boolean = true;
  constructor(private sessionService: SessionService) {}
  ngOnInit(): void {}
  ngOnDestroy() {}
}
