import BaseModel from "@shared/models/base.model";

export default class UnitModel extends BaseModel {
  // MINIMIZE (default)
  public unitIdentifier: string | null = null;
  public name = "";
  public unitSlug: string | null = null;
  public userRole?: string;

  // UNIT_DETAILS
  public avatar: string | null = null;
  public locale: string | null = null;
  public isPublic: boolean = false;

  public properties: Object = {};

  // UNIT_CUSTOMS
  public logo: string | null = null;
  public baseUrl: string | null = null;
  public title: string | null = null;

  public styles: Object = {};

  // UNIT_PARENT
  public parentUnit?: UnitModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.parentUnit) this.parentUnit = new UnitModel().deserialize(input.parentUnit);

    return this;
  }
}
