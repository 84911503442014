export enum RecordStatusEnum {
  DRAFT = "draft",
  CREATED = "created",
  SENT = "sent", // Standard record only
  DISPATCHING = "dispatching", // Campaign record only
  DISPATCHED = "dispatched", // Campaign record only
  PENDING = "pending",
  COMPLETED = "completed",
  CANCELED = "canceled",
  CLOSED = "closed",
  EXPIRED = "expired",
}
