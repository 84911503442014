import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@core";
import RecordModel from "@shared/models/record/record.model";

@UntilDestroy()
@Component({
  selector: "record-oneway-view",
  templateUrl: "./record-oneway-view.component.html",
  styleUrls: ["./record-oneway-view.component.scss"],
})
export class RecordOnewayViewComponent implements OnInit {
  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(this._record);
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor() {}

  ngOnInit(): void {}
}
