<div class="postcard-container measured-container" style="--measure: 50rem">
  <div class="postcard" style="--space: 1.5rem">
    <div class="postcard__header">
      <span class="postcard__topic" *ngIf="_record.title"> {{_record.title}}</span>
      <span class="postcard__topic" *ngIf="!_record.title"
        >{{'RECORD.fields.title.empty' | translate: {'default': '(No Title)'} }}</span
      >
      <span class="postcard__cell__content md:align-self-end">
        {{ "RECORD.fields.expire.label" | translate: { "default": "Expire" } }} {{ _record.expirationDate |
        humanDatePipe }} <i class="pi pi-calendar text-xs" [tooltip]="_record.expirationDate | date: 'medium'"></i
      ></span>
      <!-- div class="postcard__stamp">
        <record-postcard-stamp></record-postcard-stamp>
      </div -->
    </div>
    <div class="postcard__main switcher">
      <div class="postcard__cell stack">
        <span class="postcard__cell__label"
          >{{'RECORD.fields.recipients.label' | translate: {'default': 'Recipients'} }}</span
        >
        <span class="postcard__cell__content">
          <record-recipients id="recipients" [recipients]="record.recipients"></record-recipients>
        </span>
      </div>
    </div>
    <record-conversation
      *ngIf="displayMainMessage"
      [messages]="[recordMainMessage]"
      [record]="_record"
      [showReply]="false"
      variant="postcard"
    ></record-conversation>
    <div class="flex gap-2">
      <button
        pButton
        class="p-button-rounded p-button-link ml-auto"
        type="button"
        [label]="'COMMON.buttons.cancel' | translate: {'default': 'Cancel'}"
        (click)="doCancel()"
        [loading]="isCancelling"
        data-attr="oneway-record-cancel"
      ></button>
      <button
        pButton
        class="p-button-rounded bg-primary-linear"
        type="button"
        [label]="'COMMON.buttons.confirm-send' | translate: {'default': 'Confirm & Send'}"
        (click)="doConfirm()"
        [loading]="isConfirming"
        data-attr="oneway-record-confirm-send"
      ></button>
    </div>
  </div>
</div>
