import { Injectable } from "@angular/core";
import { DateTime, Duration } from "luxon";

@Injectable({
  providedIn: "root",
})
export class DateHelper {
  constructor() {}

  static parseDate(value: any): Date | null {
    const parsedDate = Date.parse(value);

    if (!Number.isNaN(parsedDate)) {
      return new Date(parsedDate);
    }

    return null;
  }

  static addDays = (date: Date, days: number): Date => {
    return DateTime.fromJSDate(date).plus({ days }).toJSDate();
  };

  static beginningOfDay = (date: Date): Date => {
    return DateTime.fromJSDate(date).startOf("day").toJSDate();
  };

  static endOfDay = (date: Date): Date => {
    return DateTime.fromJSDate(date).endOf("day").toJSDate();
  };

  static dateDiff = (from: Date, to: Date): Duration => {
    return DateTime.fromJSDate(from).diff(DateTime.fromJSDate(to));
  };

  static humanizeDate = (value: Date, locale: string) => {
    return DateTime.fromJSDate(value).toRelative({ locale: locale });
  };
}
