<a
  [routerLink]="['/oneway-view', record.recordIdentifier]"
  class="block no-underline border-round bg-white p-3 border-1 border-200 hover:border-400 cursor-pointer transition-all transition-duration-300 mb-2"
>
  <div class="flex flex-wrap gap-1 align-items-top">
    <record-avatar-group [recipients]="record.recipients" class="mb-auto"></record-avatar-group>
    <ui-badge
      aria-hidden
      class="ml-auto mb-auto hide-on-mobile"
      [variant]="'RECORD.statuses.' + record.status + '.badge' | translate: { 'default': 'info' }"
      [label]="'RECORD.statuses.' + record.status + '.label' | translate: { 'default': record.status }"
    ></ui-badge>
  </div>
  <span class="flex gap-1 pl-1 pt-1 font-medium text-color">
    <i
      class="mt-1 text-xs hide-on-mobile text-color"
      [tooltip]="'RECORD.types.' + record.type + '.label' | translate: { 'default': 'Other type' }"
      [ngClass]="icon"
    ></i>
    {{ record.title }}
  </span>
  <span aria-hidden class="flex justify-content-between flex-wrap gap-1 align-items-center text-color pl-1 pt-1">
    <div>
      {{ "RECORD.fields.updated-at.label" | translate: { "default": "Updated at " } }}
      {{ record.updatedAt | date: "mediumDate" }}
    </div>
    <div>
      {{ "RECORD.fields.expire.label" | translate: { "default": "Expire" } }}
      {{ record.expirationDate | humanDatePipe }}
    </div>
  </span>
</a>
