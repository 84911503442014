import { Uploader } from "ngx-uploadx";

export class FilesUploader extends Uploader {
  offset = 0;
  response = "";
  responseStatus = 200;

  async getFileUrl(): Promise<string> {
    return this.endpoint;
  }

  async getOffset(): Promise<number> {
    return this.offset;
  }

  async sendFileContent(): Promise<number> {
    const { end, body } = this.getChunk();

    const fileIdentifier = this.metadata["fileIdentifiers"][this.uploadId];

    const headers = {
      "Content-Type": "application/octet-stream",
      "Content-Range": `bytes ${this.offset}-${end - 1}/${this.size}`,
      "FileIdentifier": fileIdentifier,
    };

    await this.request({
      method: "POST",
      url: this.url,
      headers,
      body: body,
    });
    return this.getOffsetFromResponse();
  }

  private getOffsetFromResponse(): number {
    if (this.responseHeaders && this.responseStatus === 200) {
      let range: number = +this.responseHeaders["range"];
      if (isNaN(range)) {
        throw new Error("Range is missing!");
      }

      this.offset = range + 1;
    }

    return this.offset;
  }

  // abort not supported
  abort(): void {}
}
