import BaseModel from "@shared/models/base.model";
import UnitModel from "@shared/models/domain/unit.model";

export default class DomainModel extends BaseModel {
  // MINIMIZE (default)
  public name = "";
  public domainRealm = "";

  // DOMAIN_DETAILS
  public locale: string | null = null;
  public properties: Object = {};
  public defaultUnit: UnitModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.defaultUnit) this.defaultUnit = new UnitModel().deserialize(input.defaultUnit);

    return this;
  }
}
