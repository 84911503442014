<main-sub-header
  [title]="'LAYOUTS.subcribe.header.title' | translate: {'default': 'Subcribe'}"
  [subTitle]="'LAYOUTS.subcribe.header.subtitle' | translate: {'default': 'Upgrade your plan'}"
></main-sub-header>
<div class="py-3 padded-container measured-container justify-content-center with-sidebar"></div>
<main-consents-panel-view
  *ngIf="sessionService.currentUser"
  [showConsents]="showConsents"
  [(user)]="sessionService.currentUser"
  (close)="showConsents = false"
></main-consents-panel-view>
