import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import FileModel from "@shared/models/file/file.model";
import { UploadState, UploadxControlEvent, UploadxOptions, UploadxService } from "ngx-uploadx";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { FilesUploader } from "@app/main/components/file/files-upload/files-uploader";
import { FilesService } from "@shared/services/files.service";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@shared/services/record.service";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import { FileStatusEnum } from "@shared/enums/file-status.enum";
import { FileMimeEnum } from "@shared/enums/file-mime.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import { UploadDropDirective } from "@app/@shared/directives/upload-drop.directive";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import posthog from "posthog-js";

@Component({
  selector: "main-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"],
  providers: [UploadxService, UploadDropDirective],
  encapsulation: ViewEncapsulation.None,
})
export class FilesUploadComponent implements OnInit, OnDestroy {
  @ViewChild("uploadInput") input: ElementRef<HTMLInputElement>;
  _files: FileModel[] = [];
  @Output() filesChange = new EventEmitter<FileModel[]>();
  @Input()
  set files(files: FileModel[]) {
    this._files = files;
    this.filesChange.emit(files);
  }
  get files(): FileModel[] {
    return this._files;
  }

  record: RecordModel;
  message: RecordMessageModel;

  control!: UploadxControlEvent;

  state!: UploadState;
  uploads: UploadState[] = [];

  isUploading: boolean = false;
  enableFoldersUpload: boolean = false;
  uploadFolders?: boolean = null;
  @Output() uploadsChanged = new EventEmitter<UploadState[]>();
  @Output() uploadComplete = new EventEmitter<boolean>();
  @Output() uploading = new EventEmitter<boolean>();

  @Input() id: string = v4();
  @Input() title: string;
  @Input() multiple: boolean = true;
  @Input() onlyPdf: boolean = false;
  @Input() fileSizeLimit: number;
  @Input() showUploadProgress: boolean = true;
  @Input() disabled: boolean = false;

  //** https://github.com/kukhariev/ngx-uploadx **//
  options: UploadxOptions = {
    endpoint: environment.services.baseUrls.filesApiUrl + environment.services.methodUrls.files.recordUploadChunk,
    token: () => this.keycloakService.getToken(),
    authorize: (request, token) => {
      request.headers["Authorization"] = `Bearer ${token}`;
      request.headers["application"] = environment.application;
      request.headers["correlationId"] = "";
      return request;
    },
    autoUpload: false,
    chunkSize: 10485760,
    // maxChunkSize: 10485760,
    concurrency: 4,
    storeIncompleteHours: 24,
    retryConfig: {
      maxAttempts: 30,
      maxDelay: 60_000,
      shouldRetry: (code, attempts) => {
        return code === 503 || ((code < 400 || code >= 501) && attempts < 5);
      },
    },
    uploaderClass: FilesUploader,
    metadata: {
      fileIdentifiers: [],
    },
  };

  constructor(
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private keycloakService: KeycloakService,
    private filesService: FilesService,
    private recordService: RecordService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.options.multiple = this.multiple;
    if (this.deviceDetectorService.isDesktop()) {
      this.enableFoldersUpload = true;
    }
  }

  ngOnDestroy() {}

  cancel(uploadId?: string): void {
    this.control = { action: "cancel", uploadId };
  }

  pause(uploadId?: string): void {
    this.control = { action: "pause", uploadId };
  }

  upload(uploadId?: string): void {
    this.control = { action: "upload", uploadId };
  }

  doStartUpload(record: RecordModel, message: RecordMessageModel): void {
    this.record = record;
    this.message = message;

    if (this.record) {
      this.uploading.emit(true);
      this.isUploading = true;
      this.uploads.forEach((upload) => {
        if (upload.status == "added") {
          this.filesService
            .recordStartUpload(upload.file.name, upload.file.type, upload.size)
            .subscribe((fileIdentifier) => {
              this.options.metadata["fileIdentifiers"][upload.uploadId] = fileIdentifier;
              this.upload(upload.uploadId);
            });
        }
      });
    }
  }

  doRemove(uploadId?: string): void {
    this.uploads = this.uploads.filter((item) => item.uploadId !== uploadId);
    this.uploadsChanged.emit(this.uploads);
  }

  checkFile(state: UploadState): void {
    const target = this.uploads.find((item) => item.uploadId === state.uploadId);
    if (target) {
      Object.assign(target, state);
    } else {
      if (this.multiple == false) {
        this.uploads = [];
      }

      if (!this.onlyPdf || (this.onlyPdf && state.file.type == FileMimeEnum.PDF)) {
        if (this.fileSizeLimit) {
          if (state.file.size / (1024 * 1024) < this.fileSizeLimit) {
            this.uploads.push(state);
            this.uploadsChanged.emit(this.uploads);
          } else {
            this.messageService.add(
              MessageHelper.createTextMessage(
                MessageSeverityEnum.SEVERITY_WARN,
                this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.title"),
                this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.body", {
                  limit: this.fileSizeLimit,
                }),
              ),
            );
          }
        } else {
          this.uploads.push(state);
          this.uploadsChanged.emit(this.uploads);
        }
      } else {
        this.messageService.add(
          MessageHelper.createTextMessage(
            MessageSeverityEnum.SEVERITY_WARN,
            this.translateService.instant("COMPONENTS.file-upload.errors.pdf-only.title"),
            this.translateService.instant("COMPONENTS.file-upload.errors.pdf-only.body"),
          ),
        );
      }
    }
  }

  onStateChanged(state: UploadState): void {
    this.state = state;

    this.checkFile(state);

    if (state.status == "complete") {
      this.filesService
        .recordFinishUpload(this.options.metadata["fileIdentifiers"][state.uploadId], this.record.recordIdentifier, "")
        .subscribe((file) => {
          let attachment = new RecordMessageAttachmentModel();
          attachment.file = file;
          attachment.file.statusCode = FileStatusEnum.FILE_UPLOADED;

          this.recordService
            .addRecordAttachment(this.record.recordIdentifier, this.message.messageIdentifier, attachment)
            .subscribe((message) => {
              this._files.push(file);
              this.filesChange.emit(this._files);

              posthog.capture("file-uploaded", { fileSize: file.size, mimeType: file.mimeType });

              if (this._files.length == this.uploads.length) {
                this.uploadComplete.emit(true);
                this.uploading.emit(false);
                this.isUploading = false;
              }
            });
        });
    }
  }
  handleFoldersUpload(uploadFolderState = null) {
    this.uploadFolders = uploadFolderState ? true : null;
    setTimeout(() => {
      this.input.nativeElement.click();
    }, 0);
  }
}
