import { ISerializable } from "@shared/models/iserializable";

export default class BaseModel implements ISerializable {
  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson() {
    return JSON.parse(this.serialize());
  }
}
