<div>
  <div class="flex flex-wrap gap-2 justify-content-between align-items-end mb-3">
    <h2 class="font-semibold m-0 text-xl">
      {{ "RECORD.cell-types." + cellType + ".description" | translate: { "default": cellType } }}
    </h2>
    <p-dropdown
      name="record-sort-dropdown"
      *ngIf="cellDetails && cellDetails.length > 0"
      class="summary-dropdown ml-auto"
      placeholder="{{ 'LAYOUTS.record-summary.content.table.sort-by' | translate: { 'default': 'Sort by' } }}"
      (onChange)="handleSorting($event)"
      [(ngModel)]="selectedOrderBy"
      [options]="orderByOptions"
      optionLabel="label"
      optionValue="value"
      data-attr="record-summary-order"
    ></p-dropdown>
  </div>
  <p-table
    data-paginator="hide"
    [value]="cellDetails"
    responsiveLayout="stack"
    class="summary-table"
    [loading]="isLoading"
  >
    <ng-template pTemplate="body" let-record>
      <record-summary-item [record]="record"></record-summary-item>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <p class="text-gray-500">{{ "COMMON.text.empty-message" | translate: { "default": "No data available" } }}</p>
    </ng-template>
  </p-table>
  <div class="mb-3 flex align-items-center" *ngIf="limitCount <= totalCount">
    <div class="text-sm text-color ml-auto mr-2">
      {{ limitOffset + 1 }} {{ "COMMON.text.to" | translate: { "default": "to" } }}
      {{ limitOffset + limitCount < totalCount ? limitOffset + limitCount : totalCount }}
      {{ "COMMON.text.of" | translate: { "default": "of" } }} {{ totalCount }}
      {{ "COMMON.text.records" | translate: { "default": "transfers" } }}
    </div>
    <p-button
      type="button"
      icon="pi pi-chevron-left"
      (click)="prev()"
      [disabled]="this.limitOffset == 0"
      styleClass="p-button-text pagination-button"
      data-attr="record-summary-previous"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-refresh"
      (click)="reset()"
      styleClass="p-button-text pagination-button"
      data-attr="record-summary-refresh"
    ></p-button>
    <p-button
      type="button"
      icon="pi pi-chevron-right"
      (click)="next()"
      [disabled]="this.limitOffset >= this.totalCount - this.limitOffset"
      styleClass="p-button-text pagination-button"
      data-attr="record-summary-next"
    ></p-button>
  </div>
</div>
