import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordMessageModel from "@shared/models/record/record-message.model";
import { RecordService } from "@shared/services/record.service";
import RecordModel from "@shared/models/record/record.model";
import FileModel from "@shared/models/file/file.model";

@Component({
  selector: "record-all-files",
  templateUrl: "./record-all-files.component.html",
  styleUrls: ["./record-all-files.component.scss"],
})
export class RecordAllFilesComponent implements OnInit, OnDestroy {
  _files: FileModel[];

  _record: RecordModel;
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this._files = [];
    this.extractAllFiles(record.messages);
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor(private recordService: RecordService) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  extractAllFiles(messages: RecordMessageModel[]): void {
    messages.forEach((message) => {
      if (message.attachments) {
        message.attachments.forEach((attachment) => {
          this._files.push(attachment.file);
        });
      }
      this.extractAllFiles(message.replyMessages);
    });
  }
}
