import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MessageFormatEnum } from "@shared/enums/message-format.enum";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import CreateRecordModel from "@shared/models/record/create-record.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@shared/services/record.service";
import { FilesUploadComponent } from "@app/main/components/file/files-upload/files-upload.component";
import { CryptographyService } from "@shared/services/cryptography.service";
import { KeyvaultService } from "@shared/services/keyvault.service";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { RecordRoleEnum } from "@shared/enums/record-role.enum";
import { ChannelEnum } from "@shared/enums/channel.enum";
import { DateHelper } from "@shared/helpers/date.helper";
import { Calendar } from "primeng/calendar";
import { WindowHelper } from "@shared/helpers/window.helper";
import { catchError, mergeMap, throwError } from "rxjs";
import PublicKeyModel from "@shared/models/vault/public-key.model";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "record-oneway-form-view",
  templateUrl: "./record-oneway-form.view.html",
  styleUrls: ["./record-oneway-form.view.scss"],
})
export class RecordOnewayFormView implements OnInit {
  @ViewChild(FilesUploadComponent)
  public files: FilesUploadComponent;

  @ViewChild(Calendar) public expirationCalendar: Calendar;

  minExpirationDate: Date = DateHelper.endOfDay(DateHelper.addDays(new Date(), +1));
  maxExpirationDate: Date = DateHelper.endOfDay(DateHelper.addDays(new Date(), +30));
  expiration: Date = DateHelper.endOfDay(DateHelper.addDays(new Date(), +30));
  isSendable: boolean = false;
  isSubmitting: boolean = false;
  enableTouchUI: boolean = false;

  @Input()
  set defaultRecipient(value: string) {
    if (value) {
      let recipient: RecordRecipientModel = new RecordRecipientModel();
      recipient.role = RecordRoleEnum.WRITER;
      recipient.channel = ChannelEnum.EMAIL;
      recipient.emailAddress = value;

      this._data.recipients.push(recipient);
      this.checkSendable();
      this.dataChange.emit(this._data);
    }
  }

  @Input()
  set defaultTitle(value: string) {
    this._data.title = value;
    this.checkSendable();
    this.dataChange.emit(this._data);
  }

  _message: string;

  _data: CreateRecordModel = new CreateRecordModel();
  @Output() dataChange = new EventEmitter<CreateRecordModel>();
  @Input()
  set data(data: CreateRecordModel) {
    this._data = data;
  }
  get data(): CreateRecordModel {
    return this._data;
  }

  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private messageService: MessageService,
    private recordService: RecordService,
    private cryptographyService: CryptographyService,
    private keyvaultService: KeyvaultService,
  ) {}

  ngOnInit(): void {
    if (this.deviceDetectorService.isMobile()) {
      this.enableTouchUI = true;
    }
  }

  onRecipientsChange(event): void {
    this._data.recipients = event;
    this.checkSendable();
    this.dataChange.emit(this._data);
  }

  onTitleChange(event): void {
    this._data.title = event.target.value;
    this.checkSendable();
    this.dataChange.emit(this._data);
  }

  onMessageChange(event): void {
    this._message = event.target.value;
    this.checkSendable();
    this.dataChange.emit(this._data);
  }

  onUploadCompleted(event): void {
    this.recordService.getRecord(this._record.recordIdentifier, false, [ResponseLevelEnum.ALL]).subscribe((record) => {
      this._record = record;
      this.recordChange.emit(this._record);
      WindowHelper.disableWarningOnClose();
      this.isSubmitting = false;
    });
  }

  onExpirationChange(value): void {
    this._data.expirationDate = value;
    this.checkSendable();
    this.dataChange.emit(this._data);
  }

  setExpirationAt(days: number = 1) {
    let expiration = DateHelper.endOfDay(DateHelper.addDays(new Date(), days));
    this.expirationCalendar.value = expiration;
    this.expirationCalendar.updateInputfield();
    this.expirationCalendar.toggle();
    this.onExpirationChange(expiration);
  }

  checkSendable() {
    this.isSendable = !(
      this._data == undefined ||
      this._data.recipients == undefined ||
      this._data.recipients.length == 0 ||
      this._data.title == undefined ||
      this._data.title.length == 0
    );
  }

  doSend() {
    this._data.type = RecordTypeEnum.ONEWAY;

    if (!this._data.expirationDate) {
      this._data.expirationDate = DateHelper.endOfDay(DateHelper.addDays(new Date(), +30));
    }
    this.isSubmitting = true;
    WindowHelper.enableWarningOnClose();

    this.recordService
      .createRecord(this._data, [ResponseLevelEnum.ALL])
      .pipe(
        mergeMap((record: RecordModel) => {
          this._record = record;
          return this.keyvaultService.getRecordPublicKeys(record.recordIdentifier, [ResponseLevelEnum.MINIMIZE]);
        }),
        mergeMap((publicKeys: PublicKeyModel[]) => {
          return this.cryptographyService.encryptText(publicKeys[0], this._message);
        }),
        mergeMap((encryptedText: string) => {
          // Add message
          let message = new RecordMessageModel();
          message.format = MessageFormatEnum.ENCRYPTED_TEXT;
          message.body = encryptedText;

          return this.recordService.addRecordMessage(this._record.recordIdentifier, null, message, [
            ResponseLevelEnum.MINIMIZE,
          ]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          this.isSubmitting = false;
          return throwError(() => error);
        }),
      )
      .subscribe((message) => {
        this._record.messages.push(message);

        // Attach files
        if (this.files.uploads && this.files.uploads.length > 0) {
          this.files.doStartUpload(this._record, message);
        } else {
          this.recordChange.emit(this._record);
          WindowHelper.disableWarningOnClose();
          this.isSubmitting = false;
        }
      });
  }
}
