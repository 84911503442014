<div *ngIf="record && sessionService.currentUser">
  <p-timeline *ngIf="record.events.length > 0" [value]="record.events" data-timeline="truncated opposite:none">
    <ng-template pTemplate="marker" let-event>
      <span
        class="custom-marker"
        [attr.data-origin]="
          (sessionService.currentUser$ | async).userIdentifier !== event.user.userIdentifier ? 'other' : 'self'
        "
      >
        {{ event.user | getUserInitials }}
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <div class="flex flex-column">
        <span class="text-sm text-color">{{
          "RECORD.events.codes." + event.code | translate: { default: event.code }
        }}</span>
        <span
          class="text-sm text-color-secondary"
          *ngIf="!((sessionService.currentUser$ | async).userIdentifier !== event.user.userIdentifier)"
          >{{ "RECORD.record-events.you" | translate: { "default": "You" } }}, {{ event.statusDate | humanDatePipe }}
          <i class="pi pi-calendar text-xs" [tooltip]="event.statusDate | date: 'medium'"></i>
        </span>
        <span
          class="text-sm text-color-secondary"
          *ngIf="(sessionService.currentUser$ | async).userIdentifier !== event.user.userIdentifier"
          >{{ event.user | getUserFullName }}
        </span>
        <span
          class="text-sm text-color-secondary"
          *ngIf="(sessionService.currentUser$ | async).userIdentifier !== event.user.userIdentifier"
          >{{ event.statusDate | humanDatePipe }}
          <i class="pi pi-calendar text-xs" [tooltip]="event.statusDate | date: 'medium'"></i>
        </span>
      </div>
    </ng-template>
  </p-timeline>
  <div *ngIf="record.events.length == 0" class="flex flex-column p-3">
    <span class="text-sm text-color">{{ "RECORD.record-events.empty" | translate: { "default": "No event" } }}</span>
  </div>
</div>
