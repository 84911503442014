import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { BehaviorSubject, filter, tap } from "rxjs";
import UnitModel from "../models/domain/unit.model";
import { SessionService } from "./session.service";
import { StylesHelper } from "../helpers/styles.helper";
import chroma from "chroma-js";

const log = new Logger("ThemingService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class ThemingService {
  // isApplying observable
  private _isApplying$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isApplying$ = this._isApplying$.asObservable();

  // isApplied observable
  private _isApplied$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isApplied$ = this._isApplied$.asObservable();

  // logo URL observable
  private _logoUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  logoUrl$ = this._logoUrl$.asObservable();
  // logo URL observable
  private _avatarUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  avatarUrl$ = this._avatarUrl$.asObservable();

  // Custom Styles Observable
  private _customStyles$: BehaviorSubject<string> = new BehaviorSubject(null);
  customStyles$ = this._customStyles$.asObservable();

  constructor(private sessionService: SessionService) {
    // Initialize watching session to set _currentUnit$ value
    this.sessionService.unit$
      .pipe(
        filter((unit: UnitModel) => Boolean(unit)),
        tap((unit: UnitModel) => {
          this.loadThemeInfo(unit);
        }),
        untilDestroyed(this),
      )
      .subscribe((unit: UnitModel) => {});
  }

  getContrastRatio(color: string, background: string): number {
    return chroma.contrast(color, background);
  }
  isReadable(color: string, background: string, requirement = 4.5): boolean {
    return this.getContrastRatio(color, background) > requirement;
  }
  getReadableTextColor(background: string): string {
    return this.isReadable("white", background) ? "white" : "black";
  }
  getRGBString(color: string): string {
    return chroma(color).rgb().join(",");
  }
  isColorValid(color: string): boolean {
    return chroma.valid(color);
  }

  loadThemeInfo(unit: UnitModel) {
    let customStylesValues = [];

    // Extract styles from Unit properties
    log.info("Setting custom theme " + unit.title + " from session unit");
    // Setting up avatar
    this._logoUrl$.next(unit.logo ?? null);
    this._avatarUrl$.next(unit.avatar ?? null);
    // Setting up colors
    if (Object.keys(unit.styles).length > 0) {
      let styles = unit.styles;
      log.info("Setting custom theme for unit", unit);

      // Set other supplied variables
      for (const [variable, value] of Object.entries(styles)) {
        value && this.isColorValid(value) && customStylesValues.push(`--${variable}: ${value}`);
      }

      // Handling primary color
      let customPrimary = styles["primary-color"];
      if (Boolean(customPrimary) && this.isColorValid(customPrimary)) {
        // Generate primary color variants
        let customPrimaryRGB = this.getRGBString(customPrimary);
        let customPrimaryText = this.getReadableTextColor(customPrimary);
        let customPrimaryLigher = chroma(customPrimary).brighten();
        let customPrimaryDarker = chroma(customPrimary).darken();
        customStylesValues.push(`--primary-color-rgb: ${customPrimaryRGB}`);
        customStylesValues.push(`--primary-color-text: ${customPrimaryText}`);
        customStylesValues.push(`--primary-color-hover: ${customPrimaryLigher}`);
        customStylesValues.push(
          `--primary-linear-color: linear-gradient(${customPrimaryLigher} 0%, ${customPrimaryDarker} 100%) ${customPrimary}`,
        );
        //  Generate and apply primay color palette
        let scale = chroma.scale(["white", customPrimary, "black"]).colors(10);
        scale.forEach((value, index) => {
          let suffix = index === 0 ? 50 : index * 100;
          customStylesValues.push(`--primary-${suffix}: ${value}`);
        });
      }
    }
    this._customStyles$.next(customStylesValues.join(";"));
  }
}
