import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";

export class MissingI18nHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    let value: string;
    if (params.interpolateParams) {
      value = params.interpolateParams["default"];
    }
    return "[" + (value || params.key) + "]";
  }
}
