import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UploadxModule } from "ngx-uploadx";

import { MainModule } from "@app/main/main.module";
import { UiModule } from "@app/@ui/ui.module";
import { SharedModule } from "@app/@shared/shared.module";
import { COMPONENTS } from "./components";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Other modules
    UploadxModule,

    // Custom modules
    MainModule,
    SharedModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class RecordModule {}
