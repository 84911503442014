<form [formGroup]="form" class="field flex flex-column" (submit)="submit()">
  <label for="response"
    >{{('RECORD.record-conversation.fields.response.label' | translate : {'default': 'Response'})}}
    <i
      class="pi pi-lock text-xs"
      [tooltip]="'RECORD.record-conversation.fields.response.tooltip' | translate : {'default': 'This message is client-side encrypted.'}"
    ></i
  ></label>
  <div class="conversation-reply-editor" id="response" uploadDrop>
    <div class="message-input-field">
      <!-- Label only visible to screen readers -->
      <label for="recordMessage" class="sr-only" translate>RECORD.record-message-form.fields.message-body.label</label>
      <textarea
        id="recordMessage"
        [rows]="3"
        pInputTextarea
        formControlName="messageBody"
        class="w-full"
        [ngClass]="{'p-disabled': isSubmitting || isUploading}"
        [readonly]="isSubmitting || isUploading"
      ></textarea>
    </div>

    <ul class="uploads flex flex-wrap gap-2" *ngIf="uploads && uploads?.length > 0">
      <li
        [tooltip]="item?.name"
        placement="top"
        [delay]="1000"
        class="uploads__file"
        [ngClass]="{ 'p-disabled': isSubmitting || isUploading }"
        *ngFor="let item of uploads"
      >
        <div class="flex flex-wrap align-items-center gap-2">
          <div class="file__icon flex align-items-center justify-content-center border-round w-1rem h-1rem">
            <ng-template #fileIcon>
              <i class="pi pi-file text-lg"></i>
            </ng-template>
            <ng-container *ngIf="isUploading; else fileIcon">
              <ui-spinner [size]="20" *ngIf="item.status === 'uploading'"></ui-spinner>
              <i class="pi pi-file text-lg" *ngIf="!['complete', 'error', 'uploading'].includes(item.status)"></i>
              <i class="pi pi-check text-green-500 text-lg" *ngIf="item.status === 'complete'"></i>
              <i class="pi pi-times p-error text-lg" *ngIf="item.status === 'error'"></i>
            </ng-container>
          </div>
          <div class="stack" style="--space: 0.25rem; --align: start">
            <span class="file__name">{{ item.name }}</span>
          </div>
        </div>
        <!-- Button icon to close -->
        <button
          [disabled]="isUploading || isSubmitting"
          (click)="doRemove(item.uploadId)"
          pButton
          type="button"
          icon="pi pi-times"
          class="p-button-text p-button-plain p-button-sm file__button"
          data-attr="record-message-file-remove"
        ></button>

        <p-progressBar
          styleClass="file__upload-progress"
          *ngIf="isUploading && item.status === 'uploading'"
          [value]="item.progress"
          [showValue]="false"
          [style]="{ 'height': '6px', 'width': '100%' }"
        ></p-progressBar>
      </li>
    </ul>

    <div class="flex flex-wrap gap-1 justify-content-between p-1">
      <label
        pButton
        class="add-attachments-btn p-button p-button-link flex-grow-1 sm:flex-grow-0"
        [ngClass]="{'p-disabled': isSubmitting || isUploading}"
        icon="pi pi-paperclip"
        type="button"
        [label]="'RECORD.record-conversation.buttons.add-attachments.label' | translate:{default: 'Add attachments'}"
        data-attr="record-message-add-attachments"
      >
        <input
          id="messageAttachments"
          class="conversation-reply-upload-input"
          type="file"
          [disabled]="isSubmitting || isUploading"
          [uploadx]="uploadOptions"
          [control]="control"
          (state)="onStateChanged($event)"
        />
      </label>

      <button
        pButton
        class="bg-primary-linear flex-grow-1 sm:flex-grow-0"
        icon="pi pi-send"
        iconPos="right"
        type="submit"
        [label]="'RECORD.record-conversation.buttons.reply.label' | translate:{default: 'Reply'}"
        [disabled]="isLoading || form.invalid"
        [loading]="isSubmitting || isUploading"
        data-attr="record-message-reply"
      ></button>
    </div>

    <div class="upload-drop-overlay">
      <div class="upload-drop-overlay-container flex flex-column gap-3 align-items-center justify-content-center">
        <div class="text-lg">
          {{ 'RECORD.record-message-form.attachments.title' | translate:{default: 'Attachments'} }}
        </div>
        <div class="text-sm text-gray-500">
          {{ 'RECORD.record-message-form.fields.attachments.subtitle' | translate:{default: 'Drop files to add
          attachments'} }}
        </div>
      </div>
    </div>
  </div>
</form>
