<div class="measured-container cover" style="--measure: 50rem">
  <div class="cover__centered border-round py-5 px-3 bg-white shadow-1">
    <div class="stack text-center" style="--space: 1.5rem; --align: center">
      <div
        class="flex align-items-center justify-content-center bg-green-100 border-round"
        style="width: 3rem; height: 3rem"
      >
        <i class="pi pi-check text-green-500 text-2xl"></i>
      </div>
      <h2 class="m-0">{{('RECORD.record-confirmation.title' | translate : {'default': 'Congratulations !'})}}</h2>
      <div
        class="text-color m-0"
        [innerHtml]="('RECORD.record-confirmation.body' | translate : {'default': 'You have successfully made a new transfer.'})"
      ></div>
      <div class="flex flex-wrap justify-content-center gap-2 w-full">
        <button
          pButton
          [routerLink]="['/oneway-view/' + record?.recordIdentifier]"
          class="p-button-outlined p-button-rounded"
          [label]="'COMMON.buttons.view' | translate: {'default': 'View'}"
          data-attr="record-confirm-view"
        ></button>
        <button
          pButton
          (click)="doAnother()"
          class="bg-primary-linear p-button-rounded"
          [label]="'COMMON.buttons.new-transfer' | translate: {'default': 'Transfer new files'}"
          data-attr="record-confirm-another"
        ></button>
      </div>
    </div>
  </div>
  <main-input-clipboard-copy
    id="record-link"
    name="record-link"
    [label]="'COMMON.buttons.link-to-clipboard' | translate: {'default': 'Get link to clipboard'}"
    [value]="recordUrl"
  ></main-input-clipboard-copy>
</div>
