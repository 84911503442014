<footer class="flex flex-wrap gap-2 measured-container padded-container border-top-1 border-200 pt-4 pb-8 md:pb-4 mt-4">
  <div class="flex flex-column gap-3 w-full">
    <p class="text-color text-center md:text-left m-0" style="font-weight: 500">
      {{('FOOTER.baseline' | translate : {'default': 'A french secure service to collect and transfer personal
      data.'})}}
    </p>
    <div
      class="flex flex-column gap-3 justify-content-center md:flex-row md:justify-content-between w-full align-items-center"
    >
      <div class="footer__links flex gap-2 flex-wrap justify-content-center md:justify-content-start">
        <a
          pButton
          class="p-button-secondary p-button-sm p-button-outlined p-button-rounded py-2 no-underline hover:underline"
          target="_blank"
          [href]="'FOOTER.links.paprwork.url' | translate : {'default': 'https://www.paprwork.io'}"
          [label]="'FOOTER.links.paprwork.label' | translate : {'default': 'Paprwork'}"
          data-attr="footer-link-paprwork"
        ></a>
        <a
          pButton
          class="p-button-secondary p-button-sm p-button-outlined p-button-rounded py-2 no-underline hover:underline"
          target="_blank"
          [href]="'FOOTER.links.legal-terms.url' | translate : {'default': 'https://www.paprwork.io/legal-terms/'}"
          [label]="'FOOTER.links.legal-terms.label' | translate : {'default': 'Legal terms'}"
          data-attr="footer-link-legal"
        ></a>
        <a
          pButton
          class="p-button-secondary p-button-sm p-button-outlined p-button-rounded py-2 no-underline hover:underline"
          target="_blank"
          [href]="'FOOTER.links.privacy.url' | translate : {'default': 'https://www.paprwork.io/privacy/'}"
          [label]="'FOOTER.links.privacy.label' | translate : {'default': 'Privacy'}"
          data-attr="footer-link-privacy"
        ></a>
      </div>
      <div
        class="footer__socials flex gap-3 justify-content-center md:justify-content-start flex-grow-1 md:flex-grow-0"
      >
        <a
          class="pi pi-linkedin social-button align-self-start"
          target="_blank"
          aria-label="LinkedIn"
          [href]="'FOOTER.socials.linkedin.url' | translate : {'default': 'https://www.linkedin.com/company/paprwork/'}"
          data-attr="footer-social-linkedin"
        ></a>
        <a
          class="pi pi-instagram social-button align-self-start"
          target="_blank"
          aria-label="Instagram"
          [href]="'FOOTER.socials.instagram.url' | translate : {'default': 'https://www.instagram.com/paprwork_france/'}"
          data-attr="footer-social-instagram"
        ></a>
        <a
          class="pi pi-facebook social-button align-self-start"
          target="_blank"
          aria-label="Facebook"
          [href]="'FOOTER.socials.facebook.url' | translate : {'default': 'https://www.facebook.com/logiciel.collecte.paprwork'}"
          data-attr="footer-social-facebook"
        ></a>
      </div>
    </div>
  </div>
</footer>
