import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { environment } from "@env/environment";

@Component({
  selector: "main-disclaimer-panel-view",
  templateUrl: "./disclaimer-panel.view.html",
  styleUrls: ["./disclaimer-panel.view.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DisclaimerPanelView implements OnInit {
  @Input() showDisclaimer: boolean;

  constructor() {}

  ngOnInit(): void {}

  closeDisclaimer() {
    this.showDisclaimer = false;
  }
}
