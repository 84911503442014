import { Pipe, PipeTransform } from "@angular/core";
import UserModel from "../models/user/user.model";

@Pipe({
  name: "getUserInitials",
})
export class GetUserInitialsPipe implements PipeTransform {
  transform(user: UserModel) {
    if (user?.givenName && user?.familyName) {
      return user.givenName[0].toUpperCase() + user.familyName[0].toUpperCase();
    } else if (user?.familyName) {
      return "@" + user.familyName[0].toUpperCase();
    } else if (user?.userName) {
      return "@" + user.userName[0].toUpperCase();
    } else {
      return "NA";
    }
  }
}
