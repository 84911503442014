import { Component, OnInit } from "@angular/core";
import CellSummaryModel from "@shared/models/record/cell-summary.model";
import { RecordService } from "@shared/services/record.service";
import { DateHelper } from "@shared/helpers/date.helper";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@core";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";
import { SessionService } from "@shared/services/session.service";

@UntilDestroy()
@Component({
  selector: "app-subscribe",
  templateUrl: "./subscribe.layout.html",
  styleUrls: ["./subscribe.layout.scss"],
})
export class SubscribeLayout implements OnInit {
  showConsents: boolean = true;

  constructor(public sessionService: SessionService) {}

  ngOnInit(): void {
    if (this.sessionService.currentUser) {
      this.showConsents = this.sessionService.currentUser.properties["consent-analytics"] === undefined;
    }
  }
}
