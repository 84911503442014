import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordMessageModel from "@shared/models/record/record-message.model";
import { MessageFormatEnum } from "@shared/enums/message-format.enum";
import { CryptographyService } from "@shared/services/cryptography.service";
import WrappedKeyModel from "@shared/models/vault/wrapped-key.model";
import RecordModel from "@shared/models/record/record.model";
import { SessionService } from "@shared/services/session.service";

@Component({
  selector: "record-message",
  templateUrl: "./record-message.component.html",
  styleUrls: ["./record-message.component.scss"],
})
export class RecordMessageComponent implements OnInit, OnDestroy {
  _wrappedKeys: WrappedKeyModel[];
  @Input()
  set wrappedKeys(keys: WrappedKeyModel[]) {
    this._wrappedKeys = keys;
    this.doDecrypt();
  }
  get wrappedKeys(): WrappedKeyModel[] {
    return this._wrappedKeys;
  }

  _message: RecordMessageModel;
  @Output() messageChange = new EventEmitter<RecordMessageModel>();
  @Input()
  set message(message: RecordMessageModel) {
    this._message = message;
    this.doDecrypt();
    this.messageChange.emit(this._message);
  }
  get message(): RecordMessageModel {
    return this._message;
  }

  @Input() record: RecordModel;
  @Input() isLoading: boolean = false;
  @Input() isDecrypting: boolean = false;
  @Input() variant: "conversation" | "postcard" = "conversation";
  _decryptedMessage: string;

  constructor(private cryptographyService: CryptographyService, public sessionService: SessionService) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  get displayLoading() {
    return (
      Object.values<string>([
        MessageFormatEnum.ENCRYPTED_TEXT,
        MessageFormatEnum.ENCRYPTED_HTML,
        MessageFormatEnum.ENCRYPTED_MARKDOWN,
      ]).includes(this.message.format) &&
      (this.isLoading || this.isDecrypting)
    );
  }

  doDecrypt(): void {
    let body = this.message.body;
    if (body) {
      if (MessageFormatEnum.ENCRYPTED_TEXT == this.message.format && this.wrappedKeys != null) {
        this.isDecrypting = true;
        this.cryptographyService
          .decryptText(this.wrappedKeys, body)
          .then((decrypted) => {
            this._decryptedMessage = decrypted;
          })
          .finally(() => {
            this.isDecrypting = false;
          });
      } else if (MessageFormatEnum.TEXT == this.message.format) {
        this._decryptedMessage = body;
      }
    }
  }
}
