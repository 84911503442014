import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { filter, finalize, mergeMap, Observable, of, zip } from "rxjs";
import RecordMessageModel from "@shared/models/record/record-message.model";
import { MessageFormatEnum } from "@shared/enums/message-format.enum";
import { CryptographyService } from "@shared/services/cryptography.service";
import { KeyvaultService } from "@shared/services/keyvault.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { ChannelEnum } from "@shared/enums/channel.enum";
import PublicKeyModel from "@shared/models/vault/public-key.model";
import WrappedKeyModel from "@shared/models/vault/wrapped-key.model";
import { RecordService } from "@shared/services/record.service";
import RecordModel from "@shared/models/record/record.model";
import { UntilDestroy, untilDestroyed } from "@core";
import { WindowHelper } from "@app/@shared/helpers/window.helper";
import { RecordMessageFormView } from "@app/record/components/record-message-form/record-message-form.view";

@UntilDestroy()
@Component({
  selector: "record-conversation",
  templateUrl: "./record-conversation.component.html",
  styleUrls: ["./record-conversation.component.scss"],
})
export class RecordConversationComponent implements OnInit, OnDestroy {
  @ViewChild(RecordMessageFormView) recordMessageForm: RecordMessageFormView;

  _record: RecordModel;
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.getKeys(record);
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  _messages: RecordMessageModel[];
  @Output() messagesChange = new EventEmitter<RecordMessageModel[]>();
  @Input()
  set messages(message: RecordMessageModel[]) {
    this._messages = message;
  }
  get messages(): RecordMessageModel[] {
    return this._messages;
  }

  _selectedMessage: RecordMessageModel;
  @Output() selectedMessageChange = new EventEmitter<RecordMessageModel>();
  @Input()
  set selectedMessage(message: RecordMessageModel) {
    this._selectedMessage = message;
    this.selectedMessageChange.emit(this._selectedMessage);
  }
  get selectedMessage(): RecordMessageModel {
    return this._selectedMessage;
  }

  @Input() messageFormat: string = MessageFormatEnum.ENCRYPTED_TEXT;
  @Input() showReply: boolean = true;
  @Input() enableSignature: boolean = true;

  _publicKey: PublicKeyModel;
  _wrappedKeys: WrappedKeyModel[];
  isLoadingKeys: boolean = false;
  isSubmitting: boolean = false;
  @Input() variant: "conversation" | "postcard" = "conversation";

  constructor(
    private cryptographyService: CryptographyService,
    private keyvaultService: KeyvaultService,
    private recordService: RecordService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  private getPublicKeys(record: RecordModel): Observable<PublicKeyModel[]> {
    if (!this.keyvaultService.containLocalRecordPublicKey(record.recordIdentifier)) {
      return this.keyvaultService.getRecordPublicKeys(record.recordIdentifier, [ResponseLevelEnum.MINIMIZE]);
    } else {
      return of([this.keyvaultService.getLocalRecordPublicKey(record.recordIdentifier)]);
    }
  }

  private getWrappedPrivateKeys(record: RecordModel): Observable<WrappedKeyModel[]> {
    if (!this.keyvaultService.containLocalRecordWrappedKeys(record.recordIdentifier)) {
      return this.keyvaultService.getRecordWrappedPrivateKeys(record.recordIdentifier, null, ChannelEnum.SESSION, [
        ResponseLevelEnum.MINIMIZE,
      ]);
    } else {
      return of(this.keyvaultService.getLocalRecordWrappedKeys(record.recordIdentifier));
    }
  }

  getKeys(record: RecordModel): void {
    this.isLoadingKeys = true;

    zip(this.getPublicKeys(record), this.getWrappedPrivateKeys(record))
      .pipe(
        finalize(() => (this.isLoadingKeys = false)),
        untilDestroyed(this),
      )
      .subscribe(([publicKeys, wrappedPrivateKeys]) => {
        if (publicKeys && publicKeys.length > 0) {
          this._publicKey = publicKeys[0];
        }

        this._wrappedKeys = wrappedPrivateKeys;
      });
  }

  handleSubmit(message: RecordMessageModel) {
    this.isSubmitting = true;
    WindowHelper.enableWarningOnClose();

    this.recordService
      .addRecordMessage(this.record.recordIdentifier, null, message, [ResponseLevelEnum.ALL])
      .pipe(
        finalize(() => (this.isSubmitting = false)),
        untilDestroyed(this),
      )
      .subscribe((message: RecordMessageModel) => {
        this.recordMessageForm.doUploadFiles(message);

        if (!this.recordMessageForm.uploads || this.recordMessageForm.uploads.length == 0) {
          // Updates messages list immediately
          this.messages.push(message);
          this.recordChange.emit(this._record);
          this.messagesChange.emit(this._messages);
          WindowHelper.disableWarningOnClose();
        }
      });
  }

  handleUploadCompleted(completed: boolean): void {
    this.recordService.getRecord(this._record.recordIdentifier, false, [ResponseLevelEnum.ALL]).subscribe((record) => {
      this._record = record;
      this.messages = this._record.messages;
      this.recordChange.emit(this._record);
      this.messagesChange.emit(this._messages);
      WindowHelper.disableWarningOnClose();
    });
  }
}
