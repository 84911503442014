import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordMessageModel from "@shared/models/record/record-message.model";
import { RecordService } from "@shared/services/record.service";
import RecordModel from "@shared/models/record/record.model";
import FileModel from "@shared/models/file/file.model";
import { SessionService } from "@shared/services/session.service";
import UserModel from "@shared/models/user/user.model";

@Component({
  selector: "record-events",
  templateUrl: "./record-events.component.html",
  styleUrls: ["./record-events.component.scss"],
})
export class RecordEventsComponent implements OnInit, OnDestroy {
  _record: RecordModel;

  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor(private recordService: RecordService, public sessionService: SessionService) {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
