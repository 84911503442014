<p-avatarGroup *ngIf="recipients.length > 6">
  <p-avatar
    *ngFor="let recipient of recipients.slice(0, 6)"
    shape="circle"
    [label]="recipient | getRecipientInitials"
    pTooltip="{{ recipient | getRecipientFullName }}"
  ></p-avatar>
  <p-avatar shape="circle" [label]="'+' + (recipients.length - 6)"></p-avatar>
  <div *ngIf="showLastRecipient">{{ recipients[recipients.length - 1] | getRecipientFullName }}</div>
</p-avatarGroup>
<p-avatarGroup *ngIf="recipients.length <= 6">
  <p-avatar
    *ngFor="let recipient of recipients"
    shape="circle"
    [label]="recipient | getRecipientInitials"
    pTooltip="{{ recipient | getRecipientFullName }}"
  ></p-avatar>
  <div *ngIf="showLastRecipient">{{ recipients[recipients.length - 1] | getRecipientFullName }}</div>
</p-avatarGroup>
