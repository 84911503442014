import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Logger } from "@app/@core";
import FileModel from "@shared/models/file/file.model";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { KeycloakService } from "keycloak-angular";
import RecordModel from "@shared/models/record/record.model";

/** https://github.com/VadimDez/ng2-pdf-viewer **/

const logger = new Logger("PdfViewerComponent");

@Component({
  selector: "main-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  _file: FileModel;
  @Output() fileChange = new EventEmitter<FileModel>();
  @Input()
  set file(file: FileModel) {
    this._file = file;
    this.fileChange.emit(file);
  }
  get file(): FileModel {
    return this._file;
  }

  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(this._record);
  }
  get record(): RecordModel {
    return this._record;
  }

  @Input() height: number = 500;
  @Output() loadingCompleted = new EventEmitter<boolean>();
  @Output() allPagesRendered = new EventEmitter<boolean>();

  pdfLoaded: boolean = false;
  totalPages: number;
  currentPage: number;
  zoom: number = 50;
  accessToken: string;

  get currentZoom() {
    return this.zoom / 100;
  }

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit(): void {
    this.keycloakService.getToken().then((accessToken) => {
      this.accessToken = accessToken;
    });
  }

  ngOnDestroy() {}

  onPageChanged() {
    if (this.currentPage === this.totalPages) {
      this.allPagesRendered.emit(true);
    }
  }

  onPageRendered(e: CustomEvent) {
    logger.info("Page rendered", e);
  }

  onPagesInitialized(e: CustomEvent) {
    logger.info("Pages initialized", e);

    if (this.totalPages > 0) {
      this.currentPage = 1;
    }
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    logger.info("File loading complete: ", pdf);

    this.pdfLoaded = true;
    this.totalPages = pdf.numPages;
    this.loadingCompleted.emit(true);
  }

  onTextLayerRendered(e: CustomEvent) {
    logger.info("Text layer rendered", e);
  }

  goToPreviousPage() {
    if (this.totalPages > 0 && this.currentPage > 1) {
      this.currentPage -= 1;
      this.onPageChanged();
      logger.info("Going to next page");
      logger.info("Current page", this.currentPage);
    }
  }

  goToNextPage() {
    if (this.totalPages > 0 && this.currentPage < this.totalPages) {
      this.currentPage += 1;
      this.onPageChanged();
      logger.info("Going to next page");
      logger.info("Current page", this.currentPage);
    }
  }

  isFirstPage() {
    return this.totalPages > 0 && this.currentPage === 1;
  }

  isLastPage() {
    return this.totalPages > 0 && this.currentPage === this.totalPages;
  }

  decreaseZoom() {
    if (this.zoom > 10) {
      this.zoom -= 10;
      logger.info("Decreasing zoom");
      logger.info("Current zoom", this.zoom);
    }
  }

  increaseZoom() {
    this.zoom += 10;
    logger.info("Increasing zoom");
    logger.info("Current zoom", this.zoom);
  }

  resetZoom() {
    this.zoom = 50;
    logger.info("Resetting zoom");
    logger.info("Current zoom", this.zoom);
  }

  isMinZoom() {
    return this.zoom === 10;
  }
}
