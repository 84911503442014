import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "ui-page-spinner",
  templateUrl: "./page-spinner.component.html",
  styleUrls: ["./page-spinner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PageSpinnerComponent implements OnInit {
  @Input() title?: string;
  @Input() subtitle?: string;

  constructor() {}

  ngOnInit(): void {}
}
