<div *ngIf="user" class="flex flex-column">
  <label class="switch-field consent-field" for="consent-debug-logs">
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.debug-logs.label" | translate: { "default": "Send errors logs" } }}
      </span>
      <span class="field-text__subtitle">{{
        "USER.fields.consents.debug-logs.tooltip"
          | translate: { "default": "We self-host and protect your
        data." }
      }}</span>
    </div>
    <p-inputSwitch
      inputId="consent-debug-logs"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="user.properties['consent-debug-logs']"
      [ngModelOptions]="{ standalone: true }"
      (onChange)="this.userChange.emit(this._user)"
    ></p-inputSwitch>
  </label>

  <label class="switch-field consent-field" for="consent-analytics">
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.analytics.label" | translate: { "default": "Send usage" } }}
      </span>
      <span class="field-text__subtitle">
        {{
          "USER.fields.consents.analytics.tooltip"
            | translate: { "default": "We self-host and protect your
        data." }
        }}
      </span>
    </div>
    <p-inputSwitch
      inputId="consent-analytics"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="user.properties['consent-analytics']"
      [ngModelOptions]="{ standalone: true }"
      (onChange)="this.userChange.emit(this._user)"
    ></p-inputSwitch
  ></label>
  <label class="switch-field consent-field" for="consent-newsletters">
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.newsletters.label" | translate: { "default": "Receive newsletters" } }}
      </span>
      <span class="field-text__subtitle">
        {{
          "USER.fields.consents.newsletters.tooltip"
            | translate: { "default": "You will receive some news from
        us." }
        }}
      </span>
    </div>
    <p-inputSwitch
      inputId="consent-newsletters"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="user.properties['consent-newsletters']"
      [ngModelOptions]="{ standalone: true }"
      (onChange)="this.userChange.emit(this._user)"
    ></p-inputSwitch>
  </label>
</div>
