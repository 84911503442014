import { Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from "@angular/core";
import { DatePipe as AngularDatePipe, DATE_PIPE_DEFAULT_TIMEZONE } from "@angular/common";
import { I18nService } from "../i18n/i18n.service";

@Pipe({ name: "date" })
export class DatePipe extends AngularDatePipe implements PipeTransform {
  constructor(
    private i18nService: I18nService,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DATE_PIPE_DEFAULT_TIMEZONE) @Optional() defaultTimezone?: string | null,
  ) {
    super(locale, defaultTimezone);
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string,
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = "mediumDate",
    timezone?: string,
    locale?: string,
  ): string | null {
    return super.transform(value, format, timezone, locale || this.i18nService.language);
  }
}
