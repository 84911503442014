<p-sidebar
  [(visible)]="showConsents"
  position="bottom"
  [baseZIndex]="10000"
  [style]="{height:'auto'}"
  styleClass="consents-sidebar"
>
  <div style="--align: center; --space: 0.5rem">
    <ng-container>
      <h3 class="mt-0">{{('COMMON.text.consents.title' | translate : {'default': 'Give your consent'})}}</h3>
      <session-user-consents-edit [(user)]="user"></session-user-consents-edit>
      <div class="flex gap-2">
        <button pButton (click)="doEditProfile()" class="p-button-outlined ml-auto" data-attr="consents-edit-profile">
          {{('COMMON.buttons.edit-profile' | translate : {'default': 'Edit profile'})}}
        </button>
        <button pButton (click)="doUpdateUser()" class="p-button-outlined" data-attr="consents-update">
          {{('COMMON.buttons.update' | translate : {'default': 'Update'})}}
        </button>
        <button pButton (click)="doAcceptAll()" data-attr="consents-accept-all">
          {{('COMMON.buttons.accept-all' | translate : {'default': 'Accept all'})}}
        </button>
      </div>
    </ng-container>
  </div>
</p-sidebar>
