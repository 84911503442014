import { Injectable } from "@angular/core";
import PublicKeyModel from "@shared/models/vault/public-key.model";
import random from "js-crypto-random";
import WrappedKeyModel from "@shared/models/vault/wrapped-key.model";
import { CryptoHelper } from "@shared/helpers/crypto.helper";
import keyutils from "js-crypto-key-utils";
import { Key } from "js-crypto-key-utils/dist/key";
import { SessionService } from "@shared/services/session.service";

@Injectable({
  providedIn: "root",
})
export class CryptographyService {
  constructor(private readonly sessionService: SessionService) {}

  async encryptText(publicKey: PublicKeyModel, text2encrypt: string): Promise<string> {
    if (!text2encrypt || text2encrypt.length === 0) {
      return text2encrypt;
    }

    // Generate random AES Key
    const aesRandomKey = random.getRandomBytes(32);

    // Encrypt Data with AES
    const encryptedData: Uint8Array = await CryptoHelper.AESEncrypt(CryptoHelper.encode(text2encrypt), aesRandomKey);

    // Encrypt AES Key with Public RSA
    const rsaKey: Key = new keyutils.Key("pem", publicKey.publicKey || "");
    const encryptedKey: Uint8Array = await CryptoHelper.RSAEncrypt(aesRandomKey, await CryptoHelper.toJWK(rsaKey));

    return (
      "-----UUID-----\n" +
      publicKey.keyIdentifier +
      "\n-----KEY-----\n" +
      CryptoHelper.toBase64(encryptedKey) +
      "\n-----DATA-----\n" +
      CryptoHelper.toBase64(encryptedData)
    );
  }

  async decryptText(wrappedKeys: WrappedKeyModel[], text2decrypt: string): Promise<string> {
    if (!text2decrypt || text2decrypt.length === 0) {
      return text2decrypt;
    }

    // Split data
    text2decrypt = text2decrypt.split("\n").join("");

    const indexOfUuid = text2decrypt.indexOf("-----UUID-----");
    const indexOfKey = text2decrypt.indexOf("-----KEY-----");
    const indexOfData = text2decrypt.indexOf("-----DATA-----");

    /**
     * Splits text data in to 3 parts :
     *  UUID : the identifier of the key that was used to encrypt data
     *  Key : The AES private key, encrypted via RSA
     *  Data : The encrypted data, encrypted via AES with the AES private key
     */
    const keyIdentifier = text2decrypt.substring(indexOfUuid + 14, indexOfKey);
    const wrappedKey: WrappedKeyModel | undefined = wrappedKeys.find(
      (key: WrappedKeyModel) => key.keyIdentifier === keyIdentifier,
    );
    const keyEncrypted = CryptoHelper.fromBase64(text2decrypt.substring(indexOfKey + 13, indexOfData));
    const dataEncrypted = CryptoHelper.fromBase64(text2decrypt.substring(indexOfData + 14));

    // Unwrap RSA Private Key
    let sessionIdentifier = this.sessionService.session.sessionIdentifier;
    let domainRealm = this.sessionService.session.domain.domainRealm;

    const wrapperKey = await CryptoHelper.computeHash(CryptoHelper.encode(sessionIdentifier + "-" + domainRealm));
    const privateKey = await CryptoHelper.AESDecrypt(CryptoHelper.fromBase64(wrappedKey?.wrappedKey ?? ""), wrapperKey);
    const rsaKey: Key = new keyutils.Key("pem", CryptoHelper.decode(privateKey));

    // Decrypt AES Key with RSA
    const aesDecryptedKey = await CryptoHelper.RSADecrypt(keyEncrypted, await CryptoHelper.toJWK(rsaKey));

    // Decrypt Data with AES
    const decryptedData: Uint8Array = await CryptoHelper.AESDecrypt(dataEncrypted, aesDecryptedKey);

    return CryptoHelper.decode(decryptedData);
  }
}
