import BaseModel from "@shared/models/base.model";
import { ChannelEnum } from "@shared/enums/channel.enum";
import { RecordRoleEnum } from "@shared/enums/record-role.enum";

export default class RecordRecipientModel extends BaseModel {
  // MINIMIZE (default)
  public identityIdentifier: string | null = null;
  public channel?: ChannelEnum;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public emailAddress: string | null = null;
  public phoneNumber: string | null = null;
  public phoneNumberObject: {
    [key: string]: string;
  } = null; // Intl-tel-input specific object
  public role?: RecordRoleEnum;

  constructor() {
    super();
  }

  get fullName() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].join(" ");
    } else {
      return null;
    }
  }
}
