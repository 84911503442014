import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";

@Component({
  selector: "record-header",
  templateUrl: "./record-header.view.html",
  styleUrls: ["./record-header.view.scss"],
})
export class RecordHeaderView implements OnInit {
  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(this._record);
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor() {}

  ngOnInit(): void {}
}
