<form class="p-fluid with-sidebar" style="--sidebar-width-value: 600; --content-min-value: 40; --space: 1rem">
  <div class="with-sidebar__sidebar">
    <main-files-upload
      id="files"
      (uploadComplete)="onUploadCompleted($event)"
      [multiple]="true"
      [onlyPdf]="false"
      [disabled]="isSubmitting"
    ></main-files-upload>
  </div>
  <div class="border-round bg-white p-3 shadow-1 with-sidebar__main align-self-baseline" style="--space: 1rem">
    <div class="field">
      <label for="recipients-input"
        >{{('RECORD.fields.recipients.label' | translate : {'default': 'Recipients'})}}
        <i
          class="pi pi-question-circle text-xs"
          [tooltip]="'RECORD.fields.recipients.tooltip' | translate : {'default': 'Only you and recipients can read this record. All of them will be authenticate.'}"
        ></i
      ></label>
      <div id="recipients-input" class="stack" style="--space: 0.15rem">
        <record-recipients-chips
          recipientsInputId="recipients-input"
          [maxRecipient]="5"
          [disabled]="isSubmitting"
          [recipients]="data.recipients"
          (recipientsChange)="onRecipientsChange($event)"
        ></record-recipients-chips>
      </div>
      <small class="text-color-secondary field-text__subtitle ml-1">
        {{('RECORD.fields.recipients.limits' | translate : {'default': 'Free plan limits to 3 recipients'})}}
        <a *ngIf="false" [routerLink]="['/subscribe']"
          >{{('COMMON.text.upgrade-plan' | translate : {'default': '(Upgrade plan)'})}}</a
        >
      </small>
    </div>
    <div class="field">
      <label for="recordTitle"
        >{{('RECORD.fields.title.label' | translate : {'default': 'Title'})}}
        <i
          class="pi pi-lock-open text-xs"
          [tooltip]="'RECORD.fields.title.tooltip' | translate : {default: 'Warn, this data still in clear text and is populate on notifications. It\'s encrypted on our database.'}"
        ></i
      ></label>
      <input
        id="recordTitle"
        type="text"
        maxlength="140"
        [value]="_data.title"
        [readonly]="isSubmitting"
        [ngClass]="{'p-disabled': isSubmitting}"
        (change)="onTitleChange($event)"
        pInputText
        class="w-full"
      />
    </div>
    <div class="field">
      <label for="recordMessage"
        ><span>{{('RECORD.fields.message.label' | translate : {'default': 'Message'})}}</span>
        <span class="text-gray-500 text-sm">
          {{('COMMON.fields.optional' | translate : {'default': '(optional)'})}}</span
        >&nbsp;<i
          class="pi pi-lock text-xs"
          [tooltip]="'RECORD.fields.message.tooltip' | translate : {default: 'This message is client-side encrypted.'}"
        ></i
      ></label>
      <textarea
        id="recordMessage"
        [readonly]="isSubmitting"
        [ngClass]="{'p-disabled': isSubmitting}"
        [rows]="3"
        (change)="onMessageChange($event)"
        pInputTextarea
        class="w-full"
      ></textarea>
    </div>
    <div class="field">
      <label for="expiration" class="w-full"
        >{{('RECORD.fields.expiration.label' | translate:{'default':'Expires on'})}}
        <i
          class="pi pi-question-circle text-xs"
          [tooltip]="'RECORD.fields.expiration.tooltip' | translate : {'default': 'After this date, nobody can read content of this record.'}"
        ></i
      ></label>
      <p-calendar
        id="expiration"
        [(ngModel)]="expiration"
        [showButtonBar]="false"
        [disabled]="isSubmitting"
        styleClass="w-12rem"
        [ngClass]="{'p-disabled': isSubmitting}"
        inputId="expiration"
        [minDate]="minExpirationDate"
        [maxDate]="maxExpirationDate"
        [ngModelOptions]="{standalone: true}"
        [touchUI]="enableTouchUI"
        [readonlyInput]="true"
        (onSelect)="onExpirationChange($event)"
      >
        <ng-template pTemplate="footer">
          <div class="flex">
            <button
              pButton
              (click)="setExpirationAt(1)"
              class="p-button-text"
              [label]="'COMMON.buttons.expiration1Day' | translate: {'default': '1 day'}"
              data-attr="record-expiration-1d"
            ></button>
            <button
              pButton
              (click)="setExpirationAt(7)"
              class="p-button-text"
              [label]="'COMMON.buttons.expiration7Days' | translate: {'default': '7 days'}"
              data-attr="record-expiration-7d"
            ></button>
            <button
              pButton
              (click)="setExpirationAt(30)"
              class="p-button-text"
              [label]="'COMMON.buttons.expiration30days' | translate: {'default': '30 days'}"
              data-attr="record-expiration-30d"
            ></button>
          </div>
        </ng-template>
      </p-calendar>
      <small class="text-color-secondary field-text__subtitle ml-1">
        {{('RECORD.fields.expiration.limits' | translate : {'default': 'Free plan limit is 30 days'})}}
        <a *ngIf="false" [routerLink]="['/subscribe']"
          >{{('COMMON.text.upgrade-plan' | translate : {'default': '(Upgrade plan)'})}}</a
        >
      </small>
    </div>
    <div class="field">
      <label class="switch-field" for="notify">
        <div class="field-text">
          <span class="field-text__title">
            {{('RECORD.fields.notify.label' | translate : {'default': 'Send notifications'})}}
          </span>
          <small class="field-text__subtitle">
            {{('RECORD.fields.notify.tooltip' | translate : {'default': 'We will send notifications by mail to you and
            recipients.'})}}
          </small>
        </div>

        <p-inputSwitch
          inputId="notify"
          [readonly]="isSubmitting"
          [ngClass]="{'p-disabled': isSubmitting}"
          [(ngModel)]="_data.notify"
          [ngModelOptions]="{standalone: true}"
        ></p-inputSwitch>
      </label>
    </div>
    <div class="flex justify-content-end mt-3">
      <button
        pButton
        class="p-button-rounded bg-primary-linear w-max ml-auto mt-3"
        type="button"
        label="{{('COMMON.buttons.next-step' | translate : {'default': 'Next step'})}}"
        [disabled]="!isSendable"
        [loading]="isSubmitting"
        (click)="doSend()"
        data-attr="oneway-record-next-step"
      ></button>
    </div>
  </div>
</form>
