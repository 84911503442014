import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { filter, map, Observable } from "rxjs";
import { Logger, UntilDestroy } from "@core";
import { AuthorizationService } from "../services/authorization.service";
import UserUnitModel from "../models/user/user-unit.model";
import { UserRoleEnum } from "../enums/user-role.enum";

const log = new Logger("Shared/CanAccessOneway");

/**
 * Provides a guard for components that can be accessed only when a unit has a billing account
 */
@UntilDestroy()
@Injectable()
export class CanAccessOnewaySendGuard implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authorizationService.currentUserUnit$.pipe(
      filter((userUnit: UserUnitModel) => Boolean(userUnit)),
      map((userUnit: UserUnitModel) => {
        if (!userUnit.unit.isPublic && userUnit.userRole === UserRoleEnum.GUEST) {
          log.debug("Cannot send oneway, redirecting...");
          this.router.navigate(["/record-summary/records_received"], { replaceUrl: true });

          return false;
        }

        return true;
      }),
    );
  }
}
