import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { FileStatusEnum } from "@shared/enums/file-status.enum";
import { MessageFormatEnum } from "@shared/enums/message-format.enum";
import FileModel from "@shared/models/file/file.model";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordModel from "@shared/models/record/record.model";
import PublicKeyModel from "@shared/models/vault/public-key.model";
import { CryptographyService } from "@shared/services/cryptography.service";
import { FilesService } from "@shared/services/files.service";
import { RecordService } from "@shared/services/record.service";
import { FilesUploader } from "@app/main/components/file/files-upload/files-uploader";
import { UntilDestroy, untilDestroyed } from "@core";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { isEmpty } from "lodash";
import { UploadState, UploadxControlEvent, UploadxOptions } from "ngx-uploadx";
import { filter, map, mergeMap, Observable, of, zip } from "rxjs";
import { FileMimeEnum } from "@shared/enums/file-mime.enum";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: "record-message-form",
  templateUrl: "./record-message-form.view.html",
  styleUrls: ["./record-message-form.view.scss"],
})
export class RecordMessageFormView implements OnInit {
  _record: RecordModel;
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  _message: RecordMessageModel;
  set message(value: RecordMessageModel) {
    this._message = value;
    this.messageFormat = value.format;
  }
  get message(): RecordMessageModel {
    return this._message;
  }

  @Output() onSubmit = new EventEmitter();
  @Output() onUploadCompleted = new EventEmitter<boolean>();
  @Output() uploading = new EventEmitter<boolean>();

  @Input() messageFormat: string = MessageFormatEnum.ENCRYPTED_TEXT;
  @Input() publicKey: PublicKeyModel;
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;

  form: FormGroup;
  control: UploadxControlEvent;
  uploads: UploadState[] = [];
  files: FileModel[] = [];
  isUploading: boolean = false;

  @Input() multiple: boolean = true;
  @Input() onlyPdf: boolean = false;
  @Input() fileSizeLimit: number;

  constructor(
    private formBuilder: FormBuilder,
    private keycloakService: KeycloakService,
    private filesService: FilesService,
    private recordService: RecordService,
    private cryptographyService: CryptographyService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group(
      {
        messageBody: [null, []],
      },
      {
        validators: this.messageNotEmptyValidator(),
      },
    );
  }

  messageNotEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let { messageBody } = control.value,
        uploads = this.uploads;

      if (isEmpty(messageBody) && (!uploads || (uploads && uploads.length === 0))) {
        return { messageEmpty: true };
      }

      return null;
    };
  }

  reset() {
    this.form.reset();
    this.uploads = [];
    this.files = [];
    this._message = undefined;
  }

  get encryptedMessage(): Observable<RecordMessageModel> {
    let { messageBody } = this.form.value;

    if (this._message == undefined) {
      this._message = new RecordMessageModel();
      this._message.format = this.messageFormat;
    }

    if (MessageFormatEnum.ENCRYPTED_TEXT == this._message.format) {
      if (messageBody && messageBody.length > 0 && this.record.recordIdentifier && this.publicKey) {
        return of(messageBody).pipe(
          mergeMap((text: string) => this.cryptographyService.encryptText(this.publicKey, messageBody)),
          map((encrypted) => {
            this._message.body = encrypted;
            return this._message;
          }),
        );
      }
    } else if (MessageFormatEnum.TEXT == this._message.format) {
      this._message.body = messageBody;
    }

    return of(this._message);
  }

  submit() {
    this.encryptedMessage
      .pipe(
        filter(
          (message: RecordMessageModel) =>
            message && ((message.body && message.body.length > 0) || (this.uploads && this.uploads.length > 0)),
        ),
        map((message: RecordMessageModel) => message),
        untilDestroyed(this),
      )
      .subscribe((message) => {
        this.onSubmit.emit(message);
      });
  }

  doUploadFiles(message: RecordMessageModel) {
    if (this.uploads && this.uploads.length > 0) {
      this.doStartUpload(this._record, message);
    } else {
      this.reset();
    }
  }

  handleUploadsChanged(uploads: UploadState[]) {
    this.form.updateValueAndValidity();
  }

  handleUploadCompleted(): void {
    this.reset();
    this.isUploading = false;
    this.uploading.emit(false);
    this.onUploadCompleted.emit(true);
  }

  // Upload

  //** https://github.com/kukhariev/ngx-uploadx **//
  uploadOptions: UploadxOptions = {
    endpoint: environment.services.baseUrls.filesApiUrl + environment.services.methodUrls.files.recordUploadChunk,
    token: () => this.keycloakService.getToken(),
    authorize: (request, token) => {
      request.headers["Authorization"] = `Bearer ${token}`;
      request.headers["application"] = environment.application;
      request.headers["correlationId"] = "";
      return request;
    },
    autoUpload: false,
    chunkSize: 10485760,
    // maxChunkSize: 10485760,
    concurrency: 4,
    storeIncompleteHours: 24,
    retryConfig: {
      maxAttempts: 30,
      maxDelay: 60_000,
      shouldRetry: (code, attempts) => {
        return code === 503 || ((code < 400 || code >= 501) && attempts < 5);
      },
    },
    uploaderClass: FilesUploader,
    metadata: {
      fileIdentifiers: [],
    },
  };

  cancel(uploadId?: string): void {
    this.control = { action: "cancel", uploadId };
  }

  pause(uploadId?: string): void {
    this.control = { action: "pause", uploadId };
  }

  upload(uploadId?: string): void {
    this.control = { action: "upload", uploadId };
  }

  doStartUpload(record: RecordModel, message: RecordMessageModel): void {
    this.record = record;
    this.message = message;

    if (this.record) {
      this.isUploading = true;
      this.uploading.emit(true);
      this.uploads.forEach((upload) => {
        if (upload.status == "added") {
          this.filesService
            .recordStartUpload(upload.file.name, upload.file.type, upload.size)
            .subscribe((fileIdentifier) => {
              this.uploadOptions.metadata["fileIdentifiers"][upload.uploadId] = fileIdentifier;
              this.upload(upload.uploadId);
            });
        }
      });
    }
  }

  doRemove(uploadId?: string): void {
    this.uploads = this.uploads.filter((item) => item.uploadId !== uploadId);
    this.form.updateValueAndValidity();
  }

  checkFile(state: UploadState): void {
    const target = this.uploads.find((item) => item.uploadId === state.uploadId);
    if (target) {
      Object.assign(target, state);
    } else {
      if (this.multiple == false) {
        this.uploads = [];
      }

      if (!this.onlyPdf || (this.onlyPdf && state.file.type == FileMimeEnum.PDF)) {
        if (this.fileSizeLimit) {
          if (state.file.size / (1024 * 1024) < this.fileSizeLimit) {
            this.uploads.push(state);
            this.form.updateValueAndValidity();
          } else {
            this.messageService.add(
              MessageHelper.createTextMessage(
                MessageSeverityEnum.SEVERITY_WARN,
                this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.title"),
                this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.body", {
                  limit: this.fileSizeLimit,
                }),
              ),
            );
          }
        } else {
          this.uploads.push(state);
          this.form.updateValueAndValidity();
        }
      } else {
        this.messageService.add(
          MessageHelper.createTextMessage(
            MessageSeverityEnum.SEVERITY_WARN,
            this.translateService.instant("COMPONENTS.file-upload.errors.pdf-only.title"),
            this.translateService.instant("COMPONENTS.file-upload.errors.pdf-only.body"),
          ),
        );
      }
    }
  }

  onStateChanged(state: UploadState): void {
    this.checkFile(state);

    if (state.status == "complete") {
      this.filesService
        .recordFinishUpload(
          this.uploadOptions.metadata["fileIdentifiers"][state.uploadId],
          this.record.recordIdentifier,
          "",
        )
        .pipe(
          mergeMap((file) => {
            let attachment = new RecordMessageAttachmentModel();
            attachment.file = file;
            attachment.file.statusCode = FileStatusEnum.FILE_UPLOADED;

            return zip(
              of(attachment),
              this.recordService.addRecordAttachment(
                this.record.recordIdentifier,
                this.message.messageIdentifier,
                attachment,
              ),
            );
          }),
        )
        .subscribe(([attachment, message]) => {
          this.files.push(attachment.file);

          if (this.files.length == this.uploads.length) {
            this.handleUploadCompleted();
          }
        });
    }
  }
}
