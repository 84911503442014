import { Pipe, PipeTransform } from "@angular/core";
import UserModel from "../models/user/user.model";

@Pipe({
  name: "getUserFullName",
})
export class GetUserFullNamePipe implements PipeTransform {
  transform(user: UserModel) {
    if (user?.fullName) {
      return user.fullName;
    } else {
      return user?.userName;
    }
  }
}
