import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThemingService } from "@app/@shared/services/theming.service";
import { SessionService } from "@shared/services/session.service";
import { AuthorizationService } from "@shared/services/authorization.service";

@Component({
  selector: "main-header",
  templateUrl: "./header.view.html",
  styleUrls: ["./header.view.scss"],
})
export class HeaderView implements OnInit {
  @Input()
  title: string = "";

  constructor(
    public sessionService: SessionService,
    public themingService: ThemingService,
    public authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {}
}
