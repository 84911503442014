export enum RecordEventCodeEnum {
  // Statuses
  CREATED = "created",
  SENT = "sent", // standard record only
  DISPATCHING = "dispatching", // campaign record only
  DISPATCHED = "dispatched", // campaign record only
  CANCELED = "canceled",
  CLOSED = "closed",
  EXPIRED = "expired",
  COMPLETED = "completed",

  // Conversation
  OPENED = "opened",
  RECIPIENTS_ADDED = "recipients_added",
  RECIPIENTS_REMOVED = "recipients_removed",
  UPDATED_EXPIRATION = "updated_expiration",
  UPDATED = "updated",

  // Messages
  MESSAGE_ADDED = "message_added",
  MESSAGE_REPLIED = "message_replied",
  REMINDER_SENT = "reminder_sent",
  INTERNAL_MESSAGE_ADDED = "internal_message_added",
  INTERNAL_MESSAGE_REPLIED = "internal_message_replied",
  MESSAGE_BROADCASTED = "message_broadcasted",

  // Files
  FILE_UPLOADED = "file_uploaded",
  CLOUD_FILE_UPLOADED = "cloud_file_uploaded",
  FILE_DOWNLOADED = "file_downloaded",

  // Forms
  FORM_ADDED = "form_added",
  FORM_ITEMS_ANSWERED = "form_items_answered",

  // Signature
  SIGNATURE_ADDED = "signature_added",
  SIGNATURE_CANCELED = "signature_canceled",
  SIGNATURE_DECLINED = "signature_declined",
  SIGNATURE_EXPIRED = "signature_expired",
  SIGNATURE_ERROR = "signature_error",
  SIGNATURE_COMPLETED = "signature_completed",
  SIGNATURE_SIGNER_COMPLETED = "signature_signer_completed",
  SIGNATURE_SIGNER_DECLINED = "signature_signer_declined",

  // Invitation
  INVITATION_ADDED = "invitation_added",
  INVITATION_DECLINED = "invitation_declined",
  INVITATION_ACCEPTED = "invitation_accepted",
}
