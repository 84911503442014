import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import RecordModel from "@shared/models/record/record.model";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import RecordMessageModel from "@shared/models/record/record-message.model";
import CreateRecordModel from "@shared/models/record/create-record.model";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import CellSummaryModel from "@shared/models/record/cell-summary.model";
import { SessionService } from "@shared/services/session.service";
import { RecordTypeEnum } from "../enums/record-type.enum";

@Injectable({
  providedIn: "root",
})
export class RecordService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private sessionService: SessionService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getRecord(
    recordIdentifier: string,
    triggerEvents: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url = this._baseUrl + environment.services.methodUrls.record.getRecord;
    const correlationId = v4();

    this.loaderService.addOperation("getRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          triggerEvents,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecord");
        }),
      );
  }

  createRecord(
    record: CreateRecordModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.createRecord.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("createRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...record.toJson(),
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createRecord");
        }),
      );
  }

  updateRecord(
    record: RecordModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecord.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          record: record.toJson(),
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecord");
        }),
      );
  }

  updateRecordStatus(
    recordIdentifier: string,
    status: RecordStatusEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordStatus.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordStatus");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          status,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordStatus");
        }),
      );
  }

  addRecordMessage(
    recordIdentifier: string,
    parentMessageIdentifier: string,
    message: RecordMessageModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordMessage.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordMessage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          parentMessageIdentifier: parentMessageIdentifier,
          message: message,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordMessage");
        }),
      );
  }

  updatedRecordMessage(
    recordIdentifier: string,
    message: RecordMessageModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordMessage.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordMessage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          message: message,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordMessage");
        }),
      );
  }

  addRecordAttachment(
    recordIdentifier: string,
    messageIdentifier: string,
    attachment: RecordMessageAttachmentModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordAttachment.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordAttachment");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          messageIdentifier: messageIdentifier,
          attachment: attachment,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordAttachment");
        }),
      );
  }

  getRecordsSummary(
    createdBegin: Date,
    recordTypes: RecordTypeEnum[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<CellSummaryModel[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.getRecordsSummary.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("getRecordsSummary");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          createdBegin: createdBegin,
          recordTypes,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (!response["cells"]) return null;

          return response["cells"].map((cell: any) => {
            return new CellSummaryModel().deserialize(cell);
          });
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordsSummary");
        }),
      );
  }

  getRecordsSummaryCell(
    createdBegin: Date,
    cellType: string,
    recordTypes: RecordTypeEnum[],
    orderBy: string,
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<CellSummaryModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.getRecordsSummaryCell.replace(
        "{unitSlug}",
        (this.sessionService.unit && this.sessionService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("getRecordsSummaryCell");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset: limitOffset,
            limitCount: limitCount,
          },
          createdBegin: createdBegin,
          cellType: cellType,
          recordTypes,
          orderBy: orderBy,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new CellSummaryModel().deserialize(response["cell"]).addPagination(response);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordsSummaryCell");
        }),
      );
  }
}
