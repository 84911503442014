import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "main-sub-header",
  templateUrl: "./sub-header.view.html",
  styleUrls: ["./sub-header.view.scss"],
})
export class SubHeaderView implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;

  constructor() {}

  ngOnInit(): void {}
}
