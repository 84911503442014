import BaseModel from "@shared/models/base.model";
import UnitModel from "@shared/models/domain/unit.model";
import IdentityModel from "@shared/models/user/identity.model";
import { DateHelper } from "@shared/helpers/date.helper";

export default class WrappedKeyModel extends BaseModel {
  // MINIMIZE (default)
  public keyIdentifier: string | null = null;
  public algorithm: string | null = null;
  public wrappedKey: string | null = null;
  public transformation: string | null = null;
  public activationDate: Date | null = null;
  public expirationDate: Date | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.activationDate) this.activationDate = DateHelper.parseDate(input.activationDate);

    if (input.expirationDate) this.expirationDate = DateHelper.parseDate(input.expirationDate);

    return this;
  }
}
