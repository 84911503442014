import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getMimeTypeIcon",
})
export class GetMimeTypeIconPipe implements PipeTransform {
  transform(mimeType: string) {
    switch (mimeType) {
      case "application/pdf":
        return "pi pi-file-pdf";
      case "text/plain":
        return "pi pi-file";
      case "text/html":
      case "text/css":
      case "text/javascript":
        return "pi pi-code";
      case "application/zip":
      case "application/x-7z-compressed":
      case "application/x-bzip":
      case "application/x-bzip2":
      case "application/x-rar-compressed":
      case "application/x-tar":
        return "pi pi-folder";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.oasis.opendocument.text":
        return "pi pi-file-word";
      case "application/csv":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.oasis.opendocument.spreadsheet":
        return "pi pi-file-excel";
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.oasis.opendocument.presentation":
        return "pi pi-file";
      case "image/gif":
      case "image/png":
      case "image/jpeg":
      case "image/bmp":
      case "image/webp":
      case "image/x-icon":
      case "image/svg+xml":
      case "image/tiff":
        return "pi pi-image";
      case "video/x-msvideo":
      case "video/mpeg":
      case "video/ogg":
      case "video/webm":
      case "video/mp4":
        return "pi pi-video";
      case "audio/aac":
      case "audio/midi":
      case "audio/mpeg":
      case "audio/webm":
      case "audio/ogg":
      case "audio/wav":
      case "audio/x-wav":
        return "pi pi-file";
      default:
        return "pi pi-file";
    }
  }
}
