<div class="stack" *ngIf="messages && messages.length > 0">
  <div *ngFor="let message of messages">
    <record-message
      *ngIf="message"
      [record]="record"
      [message]="message"
      [wrappedKeys]="_wrappedKeys"
      [isLoading]="isLoadingKeys"
      [variant]="variant"
    ></record-message>
  </div>
  <record-message-form
    *ngIf="showReply"
    [isLoading]="isLoadingKeys"
    [isSubmitting]="isSubmitting"
    [record]="record"
    [publicKey]="_publicKey"
    (onUploadCompleted)="handleUploadCompleted($event)"
    (onSubmit)="handleSubmit($event)"
  ></record-message-form>
</div>
