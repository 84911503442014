<div *ngIf="record">
  <header class="padded-container surface-0 border-bottom-1 border-200">
    <div class="measured-container flex gap-2 flex-wrap justify-content-between align-items-center py-3">
      <div class="header__title stack">
        <h1 *ngIf="record.title" class="text-xl font-medium ph-no-capture">{{record.title}}</h1>
        <h2 *ngIf="record.topic" class="text-lg font-medium ph-no-capture">{{record.topic}}</h2>
        <span class="text-sm text-color-secondary ph-no-capture"
          >{{ "RECORD.fields.sent-by.label" | translate: { "default": "Sent by " } }}{{record.owner |
          getUserFullName}}</span
        >
      </div>
      <div>
        <ui-badge
          [variant]="'RECORD.statuses.' + record.status + '.badge' | translate: { 'default': 'info' }"
          [label]="'RECORD.statuses.' + record.status + '.label' | translate : {default: record.status}"
        >
        </ui-badge>
      </div>
    </div>
  </header>
</div>
