import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import FileModel from "@shared/models/file/file.model";
import { FilesService } from "@shared/services/files.service";
import { KeycloakService } from "keycloak-angular";
import RecordModel from "@shared/models/record/record.model";
import { FileHelper } from "@app/@shared/helpers/file.helper";

@Component({
  selector: "main-file-download",
  templateUrl: "./file-download.component.html",
  styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent implements OnInit, OnDestroy {
  _file: FileModel;
  @Output() fileChange = new EventEmitter<FileModel>();
  @Input()
  set file(file: FileModel) {
    this._file = file;
    this.fileChange.emit(file);
  }
  get file(): FileModel {
    return this._file;
  }

  @Input()
  record: RecordModel;
  isPreviewable: boolean = false;
  token: string;

  constructor(private keycloakService: KeycloakService, private filesService: FilesService) {}

  ngOnInit(): void {
    this.keycloakService.getToken().then((token) => {
      this.token = token;
    });
    this.isPreviewable = FileHelper.isPreviewable(this.file.mimeType);
  }

  doDownload() {
    this.filesService.recordDownloadFile(this.file, this.record.recordIdentifier).subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.rel = "noopener";
      link.download = this.file.name || "download";

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          link.removeEventListener("click", clickHandler);
        }, 150);
      };
      link.addEventListener("click", clickHandler, false);
      link.click();
    });
  }

  doPreview() {
    this.filesService.recordDownloadFile(this.file, this.record.recordIdentifier).subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      let newTab = window.open(url);
      let fileName = this.file.name;

      setTimeout(function () {
        newTab.document.title = fileName;
      }, 10);
    });
  }

  ngOnDestroy() {}
}
