<main-sub-header
  [title]="'LAYOUTS.record-summary.header.title' | translate: {'default': 'Summary'}"
  [subTitle]="'LAYOUTS.record-summary.header.subtitle' | translate: {'default': 'Summary of your sent/received records.'}"
></main-sub-header>
<div class="py-3 padded-container measured-container justify-content-center with-sidebar">
  <div
    class="with-sidebar__sidebar auto-grid align-self-baseline mt-2 md:mt-7 mb-2"
    style="--grid-min-item-size: 10rem; --space: var(--space-3xs, 0.125rem)"
  >
    <ng-container *ngFor="let item of cells">
      <record-summary-cell *ngIf="!hiddenCells.includes(item.cellType)" [cell]="item"></record-summary-cell>
    </ng-container>
  </div>
  <div class="with-sidebar__main">
    <record-summary-details #details [cellType]="selectedCellType"></record-summary-details>
  </div>
</div>
<main-consents-panel-view
  *ngIf="sessionService.currentUser"
  [showConsents]="showConsents"
  [(user)]="sessionService.currentUser"
  (close)="showConsents = false"
></main-consents-panel-view>
