import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Logger } from "@app/@core";

const log = new Logger("NavigationService");

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  constructor(private router: Router) {}

  /**
   * Redirects to the specified URI
   * Useful when trying to reload a route, as navigation to the same route does not reload the state
   * @param uri the URI to redirect to, accepts the same parameters as router.navigate function
   */
  redirectTo(commands: any[], extras?: NavigationExtras) {
    this.router.navigateByUrl("/home", { skipLocationChange: true }).then(() => this.router.navigate(commands, extras));
  }
}
