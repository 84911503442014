import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@env/environment";
import { Logger } from "@core";
import { KeycloakProfile } from "keycloak-js";
import posthog from "posthog-js";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { KeycloakService } from "keycloak-angular";
import { SessionService } from "@shared/services/session.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { Title } from "@angular/platform-browser";
import { filter, merge, switchMap, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { I18nService } from "./@shared/i18n/i18n.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { setTheme } from "ngx-bootstrap/utils";
import { LocalStorageKeysEnum } from "./@shared/enums/local-storage-keys.enum";
import { ErrorCodeEnum } from "./@shared/enums/error-code.enum";
import { LocalStorageService } from "./@shared/services/local-storage.service";
import UnitModel from "./@shared/models/domain/unit.model";
import { ThemingService } from "./@shared/services/theming.service";

const log = new Logger("AppComponent");

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // PWA logic
  // isOnline: boolean;
  // modalVersion: boolean;
  // modalPwaEvent: any;
  // modalPwaPlatform: string | undefined;

  isLoggedIn = false;
  showDisclaimer: boolean = environment.disclaimer;
  title: string = "";
  userProfile: KeycloakProfile | null = null;

  currentUnit: string = "";

  constructor(
    private readonly keycloak: KeycloakService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private titleService: Title,
    private i18nService: I18nService,
    public sessionService: SessionService,
    public themingService: ThemingService,
  ) {
    setTheme("bs4");

    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data["title"]) {
              return child.snapshot.data["title"];
            } else {
              return null;
            }
          }
          return null;
        }),
        switchMap((title) => {
          return this.sessionService.unit$.pipe(
            filter((unit: UnitModel) => Boolean(unit)),
            map((unit: UnitModel) => {
              return { title, unit };
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(({ title, unit }) => {
        if (title) {
          this.title = unit.title
            ? this.translateService.instant(title) + " - " + unit.title
            : this.translateService.instant(title);
          this.titleService.setTitle(this.title);
        }
      });
  }

  async ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug("Application initialization");

    // this.enablePwa();
    this.enableLiveChat();

    // Check oAuth
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
      this.enableUserTracking(this.userProfile);
    }

    this.loadSessionInfo();
  }

  getSubdomain(): string {
    const domain = window.location.hostname;
    let subdomain;

    if (
      domain.indexOf(".") < 0 ||
      domain.split(".")[0] === "oneway" ||
      domain.split(".")[0] === "oneway-dev" ||
      domain.split(".")[0] === "oneway-staging" ||
      domain.split(".")[0] === "www"
    ) {
      subdomain = "";
    } else {
      subdomain = domain.split(".")[0];
    }

    return subdomain;
  }

  loadSessionInfo() {
    this.currentUnit = this.getSubdomain();

    this.sessionService
      .loadSessionInfo(
        this.currentUnit || this.localStorageService.getCurrentUnit(this.userProfile.id) || environment.defaultUnit,
        [ResponseLevelEnum.ALL],
      )
      .pipe(
        catchError((error) => {
          console.log("App Component", error);
          if (
            error.error?.code &&
            [ErrorCodeEnum.PERSISTANCE_ID_NOT_FOUND, ErrorCodeEnum.SECURITY_NOT_ALLOWED].includes(error.error.code)
          ) {
            this.localStorageService.clear(LocalStorageKeysEnum.CURRENT_UNIT);
            return this.sessionService.loadSessionInfo(environment.defaultUnit, [ResponseLevelEnum.ALL]);
          }

          return throwError(() => error);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {});
  }

  /*
    For now, we don't make use of updating application version
    Someday, the UI will include hints on how to add web app to home screen
    and a dialog when a new version of the app is available
  */
  // private enablePwa() {
  //   this.updateOnlineStatus();

  //   window.addEventListener("online", this.updateOnlineStatus.bind(this));
  //   window.addEventListener("offline", this.updateOnlineStatus.bind(this));

  //   if (this.swUpdate.isEnabled) {
  //     this.swUpdate.versionUpdates.pipe(
  //       filter((evt: any): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
  //       map((evt: any) => {
  //         log.info(`currentVersion= [${evt.currentVersion} | latestVersion= [${evt.latestVersion}]`);
  //         this.modalVersion = true;
  //       }),
  //     );
  //   }

  //   this.loadModalPwa();
  // }

  // private updateOnlineStatus(): void {
  //   this.isOnline = window.navigator.onLine;
  // }

  // updateVersion(): void {
  //   this.modalVersion = false;
  //   window.location.reload();
  // }

  // closeVersion(): void {
  //   this.modalVersion = false;
  // }

  // private loadModalPwa(): void {
  //   if (this.platform.ANDROID) {
  //     window.addEventListener("beforeinstallprompt", (event: any) => {
  //       event.preventDefault();
  //       this.modalPwaEvent = event;
  //       this.modalPwaPlatform = "ANDROID";
  //     });
  //   }

  //   if (this.platform.IOS && this.platform.SAFARI) {
  //     const isInStandaloneMode = "standalone" in window.navigator && (<any>window.navigator)["standalone"];
  //     if (!isInStandaloneMode) {
  //       this.modalPwaPlatform = "IOS";
  //     }
  //   }
  // }

  // addToHomeScreen(): void {
  //   this.modalPwaEvent.prompt();
  //   this.modalPwaPlatform = undefined;
  // }

  // closePwa(): void {
  //   this.modalPwaPlatform = undefined;
  // }

  private enableLiveChat() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = environment.crisp.websiteId;

    (function () {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
    window.$crisp.push(["safe", true]);
  }

  enableUserTracking(profile: KeycloakProfile) {
    // We can associate user profile to tracking apps here
    // to access attributes : https://github.com/mauriciovigolo/keycloak-angular/issues/89
    if (environment.posthog.enabled) {
      posthog.init(environment.posthog.apiKey, {
        api_host: environment.posthog.host,
        persistence: "localStorage",
        capture_pageview: false,
        mask_all_text: true,
        loaded: (posthog) => {
          posthog.register({
            // add extra tags for filtering
            "app": environment.posthog.app,
            "environment": environment.posthog.environment,
          });
          let routeInfo = this.activatedRoute.firstChild.snapshot;
          let params = { ...routeInfo.params };
          posthog.capture("$pageview", {
            "path": routeInfo.routeConfig.path,
            "params": params,
          });
          if (profile["attributes"]["consent-analytics"] && profile["attributes"]["consent-analytics"][0] === "true") {
            posthog.identify(
              profile.id, // distinct_id, required
              {
                email: profile.email,
                emailVerified: profile.emailVerified,
                firstName: profile.firstName,
                lastName: profile.lastName,
              },
            );
          }
          const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
          onNavigationEnd.subscribe((event: NavigationEnd) => {
            let routeInfo = this.activatedRoute.firstChild.snapshot;
            let params = { ...routeInfo.params };
            posthog.capture("$pageview", {
              "path": routeInfo.routeConfig.path,
              "params": params,
            });
          });
        },
      });
    }
    if (environment.sentry.enabled) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        integrations: [
          new BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
          new posthog.SentryIntegration(posthog, "paprwork", 6, "sentry.nestor.tools"),
        ],
        environment: environment.sentry.environment,
        // release: environment.sentry.release, //this is not needed anymore as the webpack plugin is inferencing this from env
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentry.tracesSampleRate,
        autoSessionTracking: true,
      });
      if (profile["attributes"]["consent-debug-logs"] && profile["attributes"]["consent-debug-logs"][0] === "true") {
        Sentry.setUser({ id: profile.id, email: profile.email });
      }
    }
  }

  ngOnDestroy() {}
}
