<div class="file">
  <i [class]="file.mimeType | getMimeTypeIcon"></i>
  <div class="file__content">
    <span class="file__name"> {{ file.name }}</span>
    <span class="file__description text-xs text-color-secondary">{{ file.size | filesize }} - {{ file.mimeType }}</span>
  </div>
  <div class="file__actions">
    <button
      *ngIf="isPreviewable"
      pButton
      class="file__actions__button p-button-text"
      icon="pi pi-eye"
      (click)="doPreview()"
    ></button>
    <button pButton class="file__actions__button p-button-text" icon="pi pi-download" (click)="doDownload()"></button>
  </div>
</div>
