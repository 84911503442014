<div [class]="containerClass">
  <!-- Button for sharing link -->
  <button
    pButton
    (click)="doShareRecord()"
    icon="pi pi-share-alt"
    class="p-button-outlined p-button-rounded p-button-sm"
    [label]="'COMMON.buttons.share' | translate: { 'default': 'Get link' }"
    data-attr="record-actions-get-link"
  ></button>
  <!-- Modal for sharing link -->
  <p-dialog
    class="overflow-hidden"
    [header]="'RECORD.actions.share.header' | translate: { 'default': 'Share link' }"
    [modal]="true"
    [(visible)]="showShareDialog"
    [draggable]="false"
    [breakpoints]="{ '1440': '50vw', '960px': '60vw', '640px': '100vw' }"
    [style]="{ width: '40vw', overflow: 'hidden' }"
    [dismissableMask]="true"
  >
    <label class="sr-only" for="record-link">
      {{ "RECORD.actions.share.header" | translate: { "default": "Share link" } }}
    </label>
    <main-input-clipboard-copy
      id="record-url"
      name="record-url"
      [label]="'COMMON.buttons.link-to-clipboard' | translate: { 'default': 'Get link to clipboard' }"
      [value]="recordUrl"
    ></main-input-clipboard-copy>
  </p-dialog>

  <div class="flex gap-2">
    <!-- Revoke & cancel buttons -->
    <button
      pButton
      *ngIf="isOwner"
      (click)="doCancelRecord()"
      class="p-button-outlined p-button-rounded p-button-sm"
      [label]="'COMMON.buttons.revoke' | translate: { 'default': 'Revoke' }"
      [loading]="isCancelling"
      [disabled]="isCancelling"
      data-attr="record-actions-revoke"
    ></button>
    <button
      pButton
      *ngIf="isOwner && !record.isClosed"
      (click)="doCloseRecord()"
      class="bg-primary-linear p-button-rounded p-button-sm"
      [label]="'COMMON.buttons.finish' | translate: { 'default': 'Finish' }"
      [loading]="isClosing"
      [disabled]="isClosing"
      data-attr="record-actions-close"
    ></button>
    <!-- Revoking and archiving dialog -->
    <p-confirmDialog *ngIf="isOwner" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  </div>
</div>
