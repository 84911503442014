<ng-container *ngIf="sessionService.isLoaded$ | async; else loadingTemplate">
  <div [style]="themingService.customStyles$ | async" class="root-container min-h-screen flex flex-column">
    <main-header [title]="title"></main-header>
    <div class="root-container__main flex flex-grow-1">
      <main class="flex flex-column w-full mt-8 flex-grow-1">
        <router-outlet></router-outlet>
      </main>
    </div>
    <main-footer-view></main-footer-view>
    <main-disclaimer-panel-view
      [showDisclaimer]="showDisclaimer"
      (close)="showDisclaimer = false"
    ></main-disclaimer-panel-view>
    <ui-toaster></ui-toaster>
    <ui-loader class="loader"></ui-loader>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="h-full w-full flex align-items-center justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
