export enum ErrorCodeEnum {
  COMMON_OK = "COMMON_OK",
  COMMON_ID_MISSING = "COMMON_ID_MISSING",
  COMMON_DATA_NOT_FOUND = "COMMON_DATA_NOT_FOUND",
  COMMON_PARAM_MISSING = "COMMON_PARAM_MISSING",
  COMMON_INVALID_DATE_FORMAT = "COMMON_INVALID_DATE_FORMAT",
  COMMON_FIELD_FORMAT_INVALID = "COMMON_FIELD_FORMAT_INVALID",
  COMMON_OPERATION_TIMEOUT = "COMMON_OPERATION_TIMEOUT",
  EMAIL_ERROR = "EMAIL_ERROR",
  EXTERNAL_SERVICE_ERROR = "EXTERNAL_SERVICE_ERROR",
  STATUS_NOT_FOUND = "STATUS_NOT_FOUND",
  LIST_NOT_FOUND = "LIST_NOT_FOUND",
  LIST_VALUE_NOT_FOUND = "LIST_VALUE_NOT_FOUND",
  LIST_PARAM_NOT_FOUND = "LIST_PARAM_NOT_FOUND",
  MAPPING_CLASS_NOT_FOUND = "MAPPING_CLASS_NOT_FOUND",
  SECURITY_USERNAME_INVALID = "SECURITY_USERNAME_INVALID",
  SECURITY_REALM_INVALID = "SECURITY_REALM_INVALID",
  SECURITY_UNIT_INVALID = "SECURITY_UNIT_INVALID",
  SECURITY_NOT_ALLOWED = "SECURITY_NOT_ALLOWED",
  SECURITY_SYSTEM_ERROR = "SECURITY_SYSTEM_ERROR",
  SECURITY_SESSION_INVALID = "SECURITY_SESSION_INVALID",
  PERSISTANCE_ID_NOT_UNIQUE = "PERSISTANCE_ID_NOT_UNIQUE",
  PERSISTANCE_DATA_DUPLICATE = "PERSISTANCE_DATA_DUPLICATE",
  PERSISTANCE_ID_MISSING = "PERSISTANCE_ID_MISSING",
  PERSISTANCE_ID_NOT_FOUND = "PERSISTANCE_ID_NOT_FOUND",
  PERSISTANCE_DATA_NOT_FOUND = "PERSISTANCE_DATA_NOT_FOUND",
  PERSISTANCE_DATA_MISSING = "PERSISTANCE_DATA_MISSING",
  PERSISTANCE_SEQUENCE_MISSING = "PERSISTANCE_SEQUENCE_MISSING",
  PERSISTANCE_CRITERION_MISSING = "PERSISTANCE_CRITERION_MISSING",
  PERSISTANCE_CRITERION_WRONG_TYPE = "PERSISTANCE_CRITERION_WRONG_TYPE",
  PERSISTANCE_INDEX_FAILED = "PERSISTANCE_INDEX_FAILED",
}
