import { Injectable } from "@angular/core";
import { LocalStorageKeysEnum } from "../enums/local-storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public exists(key: string): boolean {
    return !!this.get(key);
  }

  public set(key: string, value: any) {
    localStorage.setItem(key, value.toString());
    return this;
  }

  public clear(key: string) {
    localStorage.removeItem(key);
    return this;
  }

  public getCurrentUnit(id: string): string {
    const currentUnitPair = this.get(LocalStorageKeysEnum.CURRENT_UNIT);

    if (currentUnitPair) {
      const [userIdentifier, unitSlug] = currentUnitPair.split("/");
      if (id === userIdentifier) return unitSlug;
    }

    return null;
  }

  public setCurrentUnit(id: string, unitSlug: string) {
    this.set(LocalStorageKeysEnum.CURRENT_UNIT, `${id}/${unitSlug}`);
  }
}
