export const environment = {
  production: true,
  disclaimer: true,
  hmr: false,
  application: "paprwork-v3-oneway",
  applicationUrl: "https://oneway-staging.post-in.fr",
  defaultLanguage: "en-GB",
  supportedLanguages: ["en-GB", "fr-FR"],
  defaultUnit: "common-unit",
  services: {
    baseUrls: {
      mainApiUrl: "https://api-staging.post-in.fr/main/api",
      keyvaultApiUrl: "https://api-staging.post-in.fr/keyvault/keys",
      filesApiUrl: "https://api-staging.post-in.fr/files",
      partnerApiUrl: "https://api-staging.post-in.fr/partners/partners",
    },
    methodUrls: {
      session: {
        initSession: "/session/getSessionInfo",
        getSessionInfo: "/session/{unitSlug}/getSessionInfo",
      },
      domain: {
        getUnit: "/domain/getUnit",
        saveUnit: "/domain/{unitSlug}/saveUnit",
      },
      user: {
        updateUser: "/user/updateUser",
        updateUserIdentity: "/user/updateUserIdentity",
        attachUserToUnit: "/user/{unitSlug}/attachUserToUnit",
      },
      billing: {
        getBillingAccount: "/billing/getBillingAccount",
      },
      record: {
        getRecord: "/record/getRecord",
        createRecord: "/record/{unitSlug}/createRecord",
        updateRecord: "/record/{unitSlug}/updateRecord",
        updateRecordStatus: "/record/{unitSlug}/updateRecordStatus",
        addRecordMessage: "/record/{unitSlug}/addRecordMessage",
        updateRecordMessage: "/record/{unitSlug}/updateRecordMessage",
        addRecordAttachment: "/record/{unitSlug}/addRecordAttachment",
        getRecordsSummary: "/record/{unitSlug}/getRecordsSummary",
        getRecordsSummaryCell: "/record/{unitSlug}/getRecordsSummaryCell",
      },
      keyvault: {
        getRecordWrappedPrivateKeys: "/record/private/getRecordWrappedPrivateKeys",
        getRecordPublicKeys: "/record/public/getRecordPublicKeys",
        sendUserToken: "/user/token/sendUserToken",
      },
      files: {
        recordStartUpload: "/record/startUpload",
        recordUploadChunk: "/record/uploadChunk",
        recordFinishUpload: "/record/finishUpload",
      },
      signature: {
        createSignature: "/signature/createSignature",
        cancelSignature: "/signature/cancelSignature",
        declineSignature: "/signature/declineSignature",
        startRecipientSignature: "/signature/startRecipientSignature",
        validateRecipientSignature: "/signature/validateRecipientSignature",
      },
    },
  },
  keycloak: {
    url: "https://auth-staging.post-in.fr/auth",
    realm: "paprwork-staging",
    clientId: "paprwork-oneway-staging",
  },
  sentry: {
    enabled: true,
    dsn: "https://55220686cb9742b6bb545c1fe2cf3725@traces-staging.post-in.fr/6",
    tracingOrigins: ["api-staging.post-in.fr"],
    tracesSampleRate: 1.0,
    environment: "staging",
    release: "staging",
  },
  crisp: {
    websiteId: "d9611cfe-73a4-40f5-a1fc-a577ae226569",
  },
  posthog: {
    enabled: true,
    host: "https://analytics-staging.post-in.fr",
    apiKey: "phc_cJ6uIpLroTS335olozNzRjOJaD5CASdJxXA0aAQEFeO",
    environment: "staging",
    app: "oneway",
  },
};
