import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import CellSummaryModel from "@shared/models/record/cell-summary.model";

@Component({
  selector: "record-summary-cell",
  templateUrl: "./record-summary-cell.component.html",
  styleUrls: ["./record-summary-cell.component.scss"],
})
export class RecordSummaryCellComponent implements OnInit {
  @Output() cellSelected = new EventEmitter<CellSummaryModel>();

  _cell: CellSummaryModel;
  @Output() cellChange = new EventEmitter<CellSummaryModel>();
  @Input()
  set cell(cell: CellSummaryModel) {
    this._cell = cell;
    this.cellChange.emit(this._cell);
  }
  get cell(): CellSummaryModel {
    return this._cell;
  }

  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  doClick(): void {
    this.cellSelected.emit(this._cell);
  }
}
