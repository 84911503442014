<header class="padded-container flex align-items-center fixed surface-0 w-full shadow-1 z-5 h-5rem">
  <div class="measured-container flex flex-wrap align-items-center justify-content-between py-2 w-full">
    <div class="flex gap-1 flex-wrap align-items-center">
      <a [routerLink]="['/oneway-send']" class="p-button-text" data-attr="header-paprwork">
        <img
          height="40px"
          class="logo-large hidden lg:block"
          [src]="(themingService.logoUrl$ | async) ?? '/assets/images/logo-colored.svg'"
          alt="Logo"
        />
        <img
          width="40px"
          height="40px"
          [src]="(themingService.avatarUrl$ | async) ?? '/assets/images/logo-icon.svg'"
          class="logo-small lg:hidden"
          alt="Paprwork Logo"
        />
        <span class="sr-only"> {{('TITLES.home' | translate : {'default': 'Home'})}} </span>
      </a>
    </div>
    <div class="ml-auto flex gap-1 align-items-center">
      <a
        *ngIf="sessionService.unit.isPublic || sessionService.userRole != 'GUEST'"
        [tooltip]="'SESSION.user-menu.transfers.new.label' | translate : {'default': 'New transfer'}"
        placement="bottom"
        [delay]="150"
        class="header-link no-underline p-3 border-round"
        routerLink="oneway-send"
        data-attr="header-oneway-send"
      >
        <i aria-hidden="true" class="flex pi pi-plus text-3xl text-primary"></i>
        <span class="sr-only"
          >{{("SESSION.user-menu.transfers.new.label" | translate: {'default': 'New transfer'} )}}</span
        >
      </a>
      <a
        [tooltip]="'SESSION.user-menu.transfers.summary.label' | translate : {'default': 'Summary'}"
        placement="bottom"
        [delay]="150"
        class="header-link no-underline p-3 border-round"
        routerLink="record-summary/records_sent"
        data-attr="header-record-summary"
      >
        <i aria-hidden="true" class="flex pi pi-history text-3xl text-primary"></i>
        <span class="sr-only"
          >{{("SESSION.user-menu.transfers.summary.label" | translate: {'default': 'Summary'} )}}</span
        >
      </a>
      <session-user-menu></session-user-menu>
    </div>
  </div>
</header>
