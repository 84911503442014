import { Component, OnInit } from "@angular/core";

@Component({
  selector: "main-footer-view",
  templateUrl: "./footer.view.html",
  styleUrls: ["./footer.view.scss"],
})
export class FooterView implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
