import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SafeHtmlPipe } from "@shared/pipes/safe-html.pipe";
import { GetUserInitialsPipe } from "./pipes/get-user-initials.pipe";
import { GetUserFullNamePipe } from "@shared/pipes/get-user-full-name.pipe";
import { GetRecipientInitialsPipe } from "@shared/pipes/get-recipient-initials.pipe";
import { UploadDropDirective } from "./directives/upload-drop.directive";
import { GetRecipientFullNamePipe } from "@shared/pipes/get-recipient-full-name.pipe";
import { GetRecordTypeIconPipe } from "@shared/pipes/get-record-type-icon.pipe";
import { GetUnitInitialsPipe } from "@shared/pipes/get-unit-initials.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { DatePipe } from "./pipes/date.pipe";
import { HumanDatePipe } from "@shared/pipes/human-date.pipe";
import { GetMimeTypeIconPipe } from "./pipes/get-mime-type-icon.pipe";
import { GUARDS } from "./guards";

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    DatePipe,
    FileSizePipe,
    GetMimeTypeIconPipe,
    GetRecipientInitialsPipe,
    GetRecipientFullNamePipe,
    GetRecordTypeIconPipe,
    GetUnitInitialsPipe,
    GetUserFullNamePipe,
    GetUserInitialsPipe,
    SafeHtmlPipe,
    HumanDatePipe,

    UploadDropDirective,
  ],
  exports: [
    // Libs
    TranslateModule,

    // Custom
    DatePipe,
    FileSizePipe,
    GetMimeTypeIconPipe,
    GetRecipientInitialsPipe,
    GetRecipientFullNamePipe,
    GetRecordTypeIconPipe,
    GetUnitInitialsPipe,
    GetUserFullNamePipe,
    GetUserInitialsPipe,
    SafeHtmlPipe,
    HumanDatePipe,

    UploadDropDirective,
  ],
  providers: [GUARDS],
})
export class SharedModule {}
