import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18nService } from "@shared/i18n/i18n.service";
import IdentityModel from "@shared/models/user/identity.model";
import UserModel from "@shared/models/user/user.model";
import { SessionService } from "@shared/services/session.service";
import { UserService } from "@shared/services/user.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { mergeMap } from "rxjs";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { StringHelper } from "@shared/helpers/string.helper";
import { TranslateService } from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: "session-user-consents-edit",
  templateUrl: "./user-consents-edit.component.html",
  styleUrls: ["./user-consents-edit.component.scss"],
})
export class UserConsentsEditComponent implements OnInit {
  _user: UserModel = new UserModel();

  @Input() isLoading: boolean = false;

  @Output() userChange = new EventEmitter<UserModel>();
  @Input()
  set user(user: UserModel) {
    this._user = user;
  }
  get user(): UserModel {
    return this._user;
  }

  constructor() {}

  ngOnInit(): void {}
}
