import { Injectable } from "@angular/core";
import { includes } from "lodash";

@Injectable({
  providedIn: "root",
})
export class FileHelper {
  static FILE_TYPES = {
    "audio": ["audio/*"],
    "image": ["image/*"],
    "video": ["video/*"],
    "pdf": ["pdf"],
    "csv": ["csv"],
    "text": ["txt"],
    "json": ["json"],
    "xml": ["xml"],
    "yaml": ["yml", "yaml"],
    "document": ["doc", "docx", "odt"],
    "spreadsheet": ["xls", "xlsx", "ods"],
    "presentation": ["ppt", "pptx", "odp"],
    "archive": ["zip", "rar", "targz", "bz", "bz2", "tar", "gz", "jar", "7z"],
  };

  static PREVIEWABLE_MIME_TYPES = [
    "application/pdf",
    "text/plain",
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/bmp",
    "image/webp",
    "image/x-icon",
    "image/svg+xml",
    "image/tiff",
    "video/x-msvideo",
    "video/mpeg",
    "video/ogg",
    "video/webm",
    "video/mp4",
    "audio/aac",
    "audio/midi",
    "audio/mpeg",
    "audio/webm",
    "audio/ogg",
    "audio/wav",
    "audio/x-wav",
  ];

  constructor() {}

  static isPreviewable(mimeType: string) {
    return this.PREVIEWABLE_MIME_TYPES.includes(mimeType);
  }

  static isDotFile(filename: string) {
    return filename.indexOf(".") === 0;
  }

  static isValidMimeType(mimeType: string, asteriskMimeType: string): boolean {
    let group = mimeType.split("/")[0],
      asteriskGroup = asteriskMimeType.split("/")[0];
    return group === asteriskGroup;
  }

  static isAccepted(file: File, accepted: string[]) {
    let extension = FileHelper.getExtension(file.name),
      mimeType = file.type;

    return accepted.some(
      (typeOrExtension: string) =>
        typeOrExtension === mimeType ||
        FileHelper.isValidMimeType(mimeType, typeOrExtension) ||
        typeOrExtension === extension ||
        this.FILE_TYPES[typeOrExtension]?.includes(extension),
    );
  }

  static getExtension(filename: string) {
    return filename.split(".").pop();
  }

  static getSizeFromKilobytes(kilobytes: number) {
    return kilobytes * 1024;
  }

  static getSizeFromMegabytes(megabytes: number) {
    return megabytes * (1024 * 1024);
  }

  static generateAvailableName = (fileName: string, takenNames: string[]) => {
    if (!includes(takenNames, fileName)) return fileName;

    const [name, ...fileNameChunks] = (fileName || "").split(".");
    var desiredName = name,
      nameIndex = 0,
      newFileName;

    do {
      nameIndex++;
      desiredName = `${name} (${nameIndex})`;
      newFileName = [desiredName, ...fileNameChunks].join(".");
    } while (includes(takenNames, newFileName));

    return newFileName;
  };
}
