import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from "@angular/router";
import { filter, map, Observable } from "rxjs";
import { Logger, UntilDestroy } from "@core";
import { AuthorizationService } from "../services/authorization.service";
import { KeycloakRealmRoleEnum } from "../enums/keycloak/keycloak-realm-role-enum";
import { KeycloakClientRoleEnum } from "../enums/keycloak/keycloak-client-role-enum";

const log = new Logger("Shared/CanAccessOneway");

/**
 * Provides a guard for components that can be accessed only when a unit has a billing account
 */
@UntilDestroy()
@Injectable()
export class CanAccessOnewayGuard implements CanActivate {
  constructor(private router: Router, private authorizationService: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authorizationService.keycloakUserRoles$.pipe(
      filter((userRoles: (KeycloakRealmRoleEnum | KeycloakClientRoleEnum)[]) => Boolean(userRoles)),
      map((userRoles: (KeycloakRealmRoleEnum | KeycloakClientRoleEnum)[]) => {
        if (
          this.authorizationService.hasAnyKeycloakRole([
            KeycloakClientRoleEnum.ONEWAY_FREE,
            KeycloakClientRoleEnum.ONEWAY_FULL,
          ])
        ) {
          return true;
        }

        log.debug("Cannot acces oneway features, redirecting...");
        this.router.navigate(["/errors/forbidden"], { replaceUrl: true });

        return false;
      }),
    );
  }
}
