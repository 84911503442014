<ng-container *ngIf="message; else emptyMessage" [ngSwitch]="variant">
  <ng-container *ngSwitchCase="'postcard'" [ngTemplateOutlet]="postcardLayout"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="conversationLayout"></ng-container>
</ng-container>

<ng-template #emptyMessage>
  <span class="text-gray-500">{{ "COMMON.empty-message" | translate: { "default": "No message" } }}</span>
</ng-template>

<ng-template #conversationLayout>
  <div
    class="message flex gap-2 align-items-end"
    [attr.data-message-sender]="
      sessionService.currentUser && message.isSender(sessionService.currentUser!.userIdentifier!) ? 'self' : 'other'
    "
  >
    <p-avatar
      *ngIf="(message.body && _decryptedMessage) || (message?.attachments && message.attachments!.length > 0)"
      class="mt-4"
      shape="circle"
      [label]="message.sender! | getUserInitials"
    ></p-avatar>
    <div class="message__content stack">
      <div *ngIf="message.body" class="message__body stack p-3">
        <ng-container *ngIf="!displayLoading && !isDecrypting; else loadingTemplate">
          <div class="message__body__text">{{ _decryptedMessage }}</div>
        </ng-container>
      </div>
      <div
        *ngIf="message?.attachments && message.attachments!.length > 0"
        class="auto-grid"
        style="--space: var(--space-2xs, 0.125rem); --grid-placement: auto-fill"
      >
        <div class="border-1 border-200 border-round" *ngFor="let attachment of message.attachments">
          <main-file-download [file]="attachment.file!" [record]="record"></main-file-download>
        </div>
      </div>
      <div
        *ngIf="(message.body && _decryptedMessage) || (message?.attachments && message.attachments!.length > 0)"
        class="message__header flex flex-wrap justify-content-between align-items-center gap-2"
      >
        <span class="text-sm text-color-secondary">{{
          sessionService.currentUser && message.isSender(sessionService.currentUser!.userIdentifier!)
            ? ("RECORD.record-message.you" | translate: { "default": "You" })
            : (message.sender! | getUserFullName)
        }}</span>
        <span class="text-sm text-color-secondary"
          >{{ message.createdAt | humanDatePipe }}
          <i class="pi pi-calendar text-xs" [tooltip]="record.createdAt | date: 'medium'"></i
        ></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #postcardLayout>
  <div class="stack" style="--space: inherit">
    <!-- Private message -->
    <div class="postcard__cell stack" *ngIf="message.body?.length > 0">
      <span class="postcard__cell__label">{{
        "RECORD.fields.conversation.label" | translate: { "default": "Messages", "count": record.messages.length }
      }}</span>
      <ng-container *ngIf="!displayLoading && !isDecrypting; else loadingTemplate">
        <span class="postcard__cell__content pre-line ph-no-capture" *ngIf="_decryptedMessage">
          {{ _decryptedMessage }}
        </span>
      </ng-container>
    </div>

    <!-- Attachments -->
    <div class="stack" *ngIf="message?.attachments && message.attachments!.length > 0">
      <span class="postcard__cell__label">
        {{ "RECORD.fields.attachments.label" | translate: { default: "Attachments" } }}
      </span>
      <div
        class="postcard__cell__content auto-grid ph-no-capture"
        style="--space: var(--cell-gutter, 0.125rem); --grid-placement: auto-fill"
      >
        <span class="border-1 border-200 border-round" *ngFor="let attachment of message.attachments">
          <main-file-download [file]="attachment.file!" [record]="record"></main-file-download>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="flex flex-row gap-2 align-items-center h-full">
    <ui-spinner [size]="20"></ui-spinner>
    <span class="text-gray-500">{{
      "COMPONENTS.record-message.decrypting" | translate: { "default": "Decrypting message..." }
    }}</span>
  </div>
</ng-template>
