<p-sidebar
  [(visible)]="showDisclaimer"
  position="bottom"
  [baseZIndex]="10000"
  [style]="{height:'auto'}"
  styleClass="disclaimer-sidebar"
>
  <div class="stack" style="--align: center; --space: 0.5rem">
    <ng-container>
      <h3 class="mt-0">{{('COMMON.text.disclaimer.title' | translate : {'default': 'Disclaimer'})}}</h3>
      <div class="text-center">
        <div
          [innerHtml]="('COMMON.text.disclaimer.description' | translate : {'default': 'Staging environment, only for demo.'})"
        ></div>

        <br /><a href="https://oneway.paprwork.io"
          >{{('COMMON.text.disclaimer.link' | translate : {'default': 'Use production version instead'})}}</a
        >
      </div>
      <button class="p-button-rounded p-buton-sm" pButton (click)="closeDisclaimer()" data-attr="disclaimer-close">
        {{('COMMON.text.disclaimer.button' | translate : {'default': 'I understand'})}}
      </button>
    </ng-container>
  </div>
</p-sidebar>
