import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@shared/services/record.service";
import { SessionService } from "@shared/services/session.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";

@Component({
  selector: "record-tags",
  templateUrl: "./record-tags.component.html",
  styleUrls: ["./record-tags.component.scss"],
})
export class RecordTagsComponent implements OnInit, OnDestroy {
  _record: RecordModel;
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  _isEditing: boolean = false;

  constructor(private recordService: RecordService, public sessionService: SessionService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  onEditSave() {
    this.recordService.updateRecord(this._record, [ResponseLevelEnum.ALL]).subscribe((record) => {
      this.record = record;
      this._isEditing = false;
    });
  }
}
