import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { RecordTypeEnum } from "@app/@shared/enums/record-type.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordModel from "@shared/models/record/record.model";

@Component({
  selector: "record-summary-item",
  templateUrl: "./record-summary-item.component.html",
  styleUrls: ["./record-summary-item.component.scss"],
})
export class RecordSummaryItemComponent implements OnInit, OnChanges {
  @Input() record: RecordModel;

  RecordTypeEnum = RecordTypeEnum;
  icon: string;

  constructor(private i18nService: I18nService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record) {
      this.icon = this.setRecordTypeIcon();
    }
  }

  setRecordTypeIcon() {
    switch (this.record.type) {
      case RecordTypeEnum.SIGNATURE:
        return "pi pi-pencil";
      case RecordTypeEnum.TRANSFER:
        return "pi pi-paperclip";
      case RecordTypeEnum.FORM:
        return "pi pi-shopping-bag";
      case RecordTypeEnum.BATCH_TRANSFER:
      case RecordTypeEnum.BATCH_SIGNATURE:
      case RecordTypeEnum.BATCH_FORM:
        return "pi pi-copy";
      case RecordTypeEnum.ONEWAY:
        return "pi pi-paperclip";
      default:
        return "pi pi-envelope";
    }
  }
}
