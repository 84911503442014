import BaseModel from "@shared/models/base.model";
import UserModel from "@shared/models/user/user.model";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { MessageTypeEnum } from "@app/@shared/enums/message-type.enum";
import { get } from "lodash";

export default class RecordMessageModel extends BaseModel {
  // DEFAULT (default)
  public messageIdentifier: string | null = null;
  public subject?: string;
  public body: string | null = "";
  public format?: string;
  public type?: string;
  public sender: UserModel | null = null;
  public metadata: JsonValue = {};

  public createdAt: Date | null = null;
  public updatedAt: Date | null = null;

  // RECORD_MESSAGES
  public replyMessages: RecordMessageModel[] = [];

  public attachments?: RecordMessageAttachmentModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.sender) this.sender = new UserModel().deserialize(input.sender);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.updatedAt) this.updatedAt = DateHelper.parseDate(input.updatedAt);

    if (input.replyMessages && Array.isArray(input.replyMessages))
      this.replyMessages = input.replyMessages.map((message: any) => new RecordMessageModel().deserialize(message));

    if (input.attachments && Array.isArray(input.attachments))
      this.attachments = input.attachments.map((attachment: any) =>
        new RecordMessageAttachmentModel().deserialize(attachment),
      );

    return this;
  }

  isSender(userIdentifier: string): boolean {
    return this.sender && this.sender.userIdentifier === userIdentifier;
  }

  get signers(): JSONObject | null {
    return this.isSignatureMessage ? get(this.metadata, ["signature", "recipients"], null) : null;
  }

  isSigner(identityIdentifier: string): boolean {
    if (this.isSignatureMessage) {
      let signers = this.signers;

      if (signers) {
        return Boolean(get(signers, [identityIdentifier]));
      }
    }

    return false;
  }

  hasSigned(identityIdentifier: string): boolean {
    return (
      this.isSignatureMessage &&
      this.isSigner(identityIdentifier) &&
      get(this.signers, [identityIdentifier, "signed"], false)
    );
  }

  get isSignatureMessage() {
    return this.type === MessageTypeEnum.SIGNATURE;
  }

  get isSignatureAvailable() {
    return (
      this.isSignatureMessage &&
      !(this.isSignatureCompleted || this.isSignatureDeclined || this.isSignatureExpired || this.isSignatureError)
    );
  }

  get isSignatureInactive() {
    return this.isSignatureDeclined || this.isSignatureExpired || this.isSignatureError;
  }

  get isSignatureCompleted() {
    return this.isSignatureMessage && get(this.metadata, ["signature", "completed"], false);
  }

  get isSignatureDeclined() {
    return this.isSignatureMessage && get(this.metadata, ["signature", "declined"], false);
  }

  get isSignatureExpired() {
    return this.isSignatureMessage && get(this.metadata, ["signature", "expired"], false);
  }

  get isSignatureError() {
    return this.isSignatureMessage && get(this.metadata, ["signature", "error"], false);
  }
}
