import { Component, Input, OnInit } from "@angular/core";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";

@Component({
  selector: "record-avatar-group",
  templateUrl: "./record-avatar-group.component.html",
  styleUrls: ["./record-avatar-group.component.scss"],
})
export class RecordAvatarGroupComponent implements OnInit {
  @Input() showLastRecipient: boolean = false;
  @Input() recipients: RecordRecipientModel[];

  constructor() {}

  ngOnInit(): void {}
}
