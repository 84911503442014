import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18nService } from "@shared/i18n/i18n.service";
import IdentityModel from "@shared/models/user/identity.model";
import UserModel from "@shared/models/user/user.model";
import { SessionService } from "@shared/services/session.service";
import { UserService } from "@shared/services/user.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { mergeMap } from "rxjs";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";

@UntilDestroy()
@Component({
  selector: "session-user-profile-edit-view",
  templateUrl: "./user-profile-edit.view.html",
  styleUrls: ["./user-profile-edit.view.scss"],
})
export class UserProfileEditView implements OnInit {
  _identity: IdentityModel = new IdentityModel();
  _user: UserModel = new UserModel();

  localeOptions: JsonArray;

  @Input() isLoading: boolean = false;
  isValid: boolean = true;
  isChanged: boolean = false;

  userFamilyNameError: string = null;
  userGivenNameError: string = null;

  @Output() identityChange = new EventEmitter<IdentityModel>();
  @Output() userChange = new EventEmitter<UserModel>();

  @Input()
  set user(user: UserModel) {
    this._user = user;
    if (this._user && this._user.identities && this._user.identities.length > 0) {
      this._identity = this._user.identities[0];
    }
    this.checkIsValid();
    this.userChange.emit(this._user);
  }
  get user(): UserModel {
    return this._user;
  }

  @Input()
  set identity(identity: IdentityModel) {
    this._identity = identity;
    this.identityChange.emit(this._identity);
  }
  get identity(): IdentityModel {
    return this._identity;
  }

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.localeOptions = this.i18nService.languagesItems;
  }

  onLocaleChange(event): void {
    this.isChanged = true;
    this._user.locale = event.value;

    this.i18nService.language = this._user.locale;

    this.checkIsValid();
  }

  onFamilyNameChange(event): void {
    this.isChanged = true;
    this._user.familyName = event.target.value;
    this._identity.lastName = event.target.value;

    this.checkIsValid();
  }

  onGivenNameChange(event): void {
    this.isChanged = true;
    this._user.givenName = event.target.value;
    this._identity.firstName = event.target.value;

    this.checkIsValid();
  }

  checkIsValid() {
    this.isValid = true;
  }

  doUpdateUser(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant("SESSION.actions.update-refresh.header", {
        default: "Update & refresh",
      }),
      message: this.translateService.instant("SESSION.actions.update-refresh.body", {
        default: "You will update your profile & this page will be reload to apply new settings.",
      }),
      acceptLabel: this.translateService.instant("COMMON.buttons.update"),
      rejectLabel: this.translateService.instant("COMMON.buttons.cancel"),
      acceptButtonStyleClass: "bg-primary-linear p-button-rounded",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.userService
          .updateUserIdentity(this._user.userIdentifier, this._identity, [ResponseLevelEnum.NOTHING])
          .pipe(
            mergeMap(() => {
              return this.userService.updateUser(this._user, [ResponseLevelEnum.USER_DETAILS]);
            }),
            untilDestroyed(this),
          )
          .subscribe((user) => {
            this._user = user;
            this.userChange.emit(this._user);

            this.isChanged = false;

            window.location.reload();
          });
      },
    });
  }
}
