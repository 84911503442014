export enum ResponseLevelEnum {
  ALL = "ALL",
  MINIMIZE = "MINIMIZE",
  NOTHING = "NOTHING",
  PAGING = "PAGING",

  /**
   * Configuration relative levels
   */
  CONFIGURATION_DETAILS = "CONFIGURATION_DETAILS",

  /**
   * Domain relative levels
   */
  DOMAIN_DETAILS = "DOMAIN_DETAILS",

  /**
   * Record relative levels
   */
  RECORD_DETAILS = "RECORD_DETAILS",
  RECORD_MESSAGES = "RECORD_MESSAGES",
  RECORD_ATTACHMENTS = "RECORD_ATTACHMENTS",

  /**
   * Template relative levels
   */
  TEMPLATE_CONTENT = "TEMPLATE_CONTENT",

  /*
    Unit relative levels
  */
  UNIT_DETAILS = "UNIT_DETAILS",

  /*
    User relative levels
  */
  USER_DETAILS = "USER_DETAILS",
  USER_BILLING_ACCOUNT = "USER_BILLING_ACCOUNT",
  BILLING_ACCOUNT_USERS = "BILLING_ACCOUNT_USERS",
}
