import BaseModel from "@shared/models/base.model";

export default class PairModel extends BaseModel {
  public key: string;
  public value: any;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
