export enum RecordTypeEnum {
  ONEWAY = "ONEWAY",

  SIGNATURE = "SIGNATURE",
  BATCH_SIGNATURE = "BATCH_SIGNATURE",

  FORM = "FORM",
  BATCH_FORM = "BATCH_FORM",

  TRANSFER = "TRANSFER",
  BATCH_TRANSFER = "BATCH_TRANSFER",

  INVITATION = "INVITATION",
}
