import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { UploadxModule } from "ngx-uploadx";
import { CodeInputModule } from "angular-code-input";

import { COMPONENTS } from "./components";
import { SessionModule } from "@app/session/session.module";
import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@ui/ui.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // Other modules
    PdfViewerModule,
    UploadxModule,
    CodeInputModule,

    // Custom modules
    SessionModule,
    SharedModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class MainModule {}
