import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { environment } from "@env/environment";
import { RecordTypeEnum } from "@app/@shared/enums/record-type.enum";
import { NavigationService } from "@app/@shared/services/navigation.service";
import { SessionService } from "@shared/services/session.service";

@Component({
  selector: "record-confirmation",
  templateUrl: "./record-confirmation.view.html",
  styleUrls: ["./record-confirmation.view.scss"],
})
export class RecordConfirmationView implements OnInit {
  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(this._record);
  }
  get record(): RecordModel {
    return this._record;
  }

  constructor(private navigationService: NavigationService, private sessionService: SessionService) {}

  ngOnInit(): void {}

  get recordUrl(): string {
    let baseUrl = this.sessionService.unit.baseUrl || environment.applicationUrl;

    if (this._record.type == "ONEWAY") {
      return `${baseUrl}/oneway-view/${this._record.recordIdentifier}`;
    } else {
      return `${baseUrl}/record-view/${this._record.recordIdentifier}`;
    }
  }

  doAnother() {
    let redirectUrl;
    switch (this.record.type) {
      case RecordTypeEnum.FORM:
        redirectUrl = ["/collect-send"];
        break;
      case RecordTypeEnum.SIGNATURE:
        redirectUrl = ["/sign-send"];
        break;
      case RecordTypeEnum.TRANSFER:
        redirectUrl = ["/transfer-send"];
        break;
      default:
        redirectUrl = ["/oneway-send"];
        break;
    }

    this.navigationService.redirectTo(redirectUrl, { replaceUrl: true });
  }
}
