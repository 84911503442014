import { Clipboard } from "@angular/cdk/clipboard";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "main-input-clipboard-copy",
  templateUrl: "./input-clipboard-copy.component.html",
  styleUrls: ["./input-clipboard-copy.component.scss"],
})
export class InputClipboardCopyComponent implements OnInit {
  @Input() id: string = "input-clipboard-copy";
  @Input() name?: string = "input-clipboard-copy";
  @Input() value: string;
  @Input() label: string;

  @Output() onCopySuccess = new EventEmitter<boolean>();

  copySuccess: boolean = false;

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {}

  doCopyToClipboard() {
    let result = this.clipboard.copy(this.value);
    this.copySuccess = result;
    this.onCopySuccess.emit(true);
    // after 5 seconds, reset the copy success flag
    setTimeout(() => {
      this.copySuccess = false;
      this.onCopySuccess.emit(false);
    }, 5000);
  }
}
