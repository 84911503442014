import { Pipe, PipeTransform } from "@angular/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";

@Pipe({
  name: "getRecipientFullName",
})
export class GetRecipientFullNamePipe implements PipeTransform {
  transform(recipient: RecordRecipientModel) {
    if (recipient?.fullName) {
      return recipient.fullName;
    } else {
      return recipient?.emailAddress;
    }
  }
}
