<div class="flex h-full flex-column">
  <div class="dropzone-wrapper border-round bg-white p-3 shadow-1 flex flex-column flex-grow-1">
    <div uploadDrop class="dropzone flex flex-column gap-3 justify-content-center align-items-center">
      <div *ngIf="uploads && uploads.length > 0" class="uploads-wrapper border-round shadow-1">
        <ul class="uploads auto-grid p-3" style="--grid-placement: auto-fit; --space: 0.5rem">
          <li
            [tooltip]="item?.name"
            placement="top"
            [delay]="1000"
            class="uploads__file overflow-hidden"
            [ngClass]="{ 'p-disabled': disabled || isUploading }"
            *ngFor="let item of uploads"
          >
            <div class="flex gap-2 align-items-center min-w-0">
              <div
                class="file__icon flex align-items-center justify-content-center border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <ng-template #fileIcon>
                  <i class="pi pi-file text-2xl"></i>
                </ng-template>
                <ng-container *ngIf="isUploading; else fileIcon">
                  <ui-spinner [size]="20" *ngIf="item.status === 'uploading'"></ui-spinner>
                  <i class="pi pi-file text-lg" *ngIf="!['complete', 'error', 'uploading'].includes(item.status)"></i>
                  <i class="pi pi-check text-green-500 text-lg" *ngIf="item.status === 'complete'"></i>
                  <i class="pi pi-times p-error text-lg" *ngIf="item.status === 'error'"></i>
                </ng-container>
              </div>
              <div class="stack min-w-0 overflow-hidden" style="--space: 0.25rem; --align: start">
                <span class="file__name">{{ item.name }}</span>
                <span class="file__size text-sm text-color-secondary"
                  >{{ item.size | filesize }} -
                  {{ item?.file?.type || ("COMMON.unknown-mime-type" | translate: { "default": "Unknown" }) }}</span
                >
              </div>
            </div>
            <!-- Button icon to close -->
            <button
              [disabled]="isUploading || disabled"
              (click)="doRemove(item.uploadId)"
              pButton
              type="button"
              icon="pi pi-times"
              class="p-button-text p-button-plain p-button-sm file__button flex-shrink-0"
              data-attr="file-upload-remove"
            ></button>

            <p-progressBar
              styleClass="file__upload-progress"
              *ngIf="isUploading && item.status === 'uploading'"
              [value]="item.progress"
              [showValue]="false"
              [style]="{ 'height': '6px', 'width': '100%' }"
            ></p-progressBar>
          </li>
        </ul>
      </div>
      <input
        #uploadInput
        [id]="id"
        type="file"
        [uploadx]="options"
        [control]="control"
        (state)="onStateChanged($event)"
        [attr.directory]="uploadFolders"
        [attr.webkitdirectory]="uploadFolders"
        [attr.mozdirectory]="uploadFolders"
        [attr.msdirectory]="uploadFolders"
        [attr.odirectory]="uploadFolders"
      />
      <div class="flex-grow-1 flex flex-column justify-content-center py-3">
        <label [for]="id" class="dropzone__title">
          <div class="flex gap-1 align-items-center justify-content-center">
            <span *ngIf="enableFoldersUpload" class="dropzone__title__text">
              {{
                title ||
                  ("COMPONENTS.file-upload.title.desktop"
                    | translate: { "default": "Click here to add files or drag them here" })
              }}
            </span>
            <span *ngIf="!enableFoldersUpload" class="dropzone__title__text">
              {{
                title ||
                  ("COMPONENTS.file-upload.title.mobile"
                    | translate: { "default": "Click here to add files or drag them here" })
              }}
            </span>
          </div>
        </label>
        <div class="dropzone__buttons">
          <div class="flex flex-column align-items-center gap-1">
            <button
              (click)="handleFoldersUpload(null)"
              pButton
              type="button"
              icon="pi pi-file"
              aria-labelledby="files-btn-label"
              class="p-button-rounded p-button-outlined text-lg"
              data-attr="file-upload-file"
            ></button>
            <span class="text-color" id="files-btn-label">
              {{ "COMPONENTS.file-upload.buttons.files" | translate: { "default": "Add files" } }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <small class="text-color-secondary mt-2 ml-auto mr-2 block"
      ><i
        class="pi pi-lock text-xs"
        [tooltip]="'COMPONENTS.file-upload.sub-text' | translate: { 'default': 'Files are server-side encrypted' }"
      ></i>
      <a *ngIf="false" [routerLink]="['/subscribe']">{{
        "COMMON.text.upgrade-plan" | translate: { "default": "(Upgrade plan)" }
      }}</a></small
    >
  </div>
</div>
