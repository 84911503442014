import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WindowHelper {
  constructor() {}

  static enableWarningOnClose() {
    window.addEventListener("beforeunload", this.callback);
  }

  static disableWarningOnClose() {
    window.removeEventListener("beforeunload", this.callback);
  }

  private static callback(e) {
    var confirmationMessage = "";

    e.preventDefault();
    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage; // Gecko, WebKit, Chrome <34
  }
}
