import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@app/@ui/ui.module";
import { COMPONENTS } from "./components";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // Custom modules
    SharedModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class SessionModule {}
