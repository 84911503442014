import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { finalize } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { UntilDestroy, untilDestroyed } from "@core";
import { RecordStatusEnum } from "@app/@shared/enums/record-status.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { RecordService } from "@app/@shared/services/record.service";
import RecordModel from "@app/@shared/models/record/record.model";
import { environment } from "@env/environment";
import { SessionService } from "@app/@shared/services/session.service";

@UntilDestroy()
@Component({
  selector: "record-actions",
  templateUrl: "./record-actions.component.html",
  styleUrls: ["./record-actions.component.scss"],
})
export class RecordActionsComponent implements OnInit {
  _record: RecordModel;

  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  @Input() containerClass?: string;

  isClosing: boolean = false;
  isCancelling: boolean = false;
  showShareDialog: boolean = false;

  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private sessionService: SessionService,
    private recordService: RecordService,
  ) {}

  ngOnInit(): void {}

  get isOwner(): boolean {
    return this._record.isOwner(this.sessionService.currentUser.userIdentifier);
  }

  get recordUrl(): string {
    let baseUrl = this.sessionService.unit.baseUrl || environment.applicationUrl;

    if (this.record.type == "ONEWAY") {
      return `${baseUrl}/oneway-view/${this.record.recordIdentifier}`;
    } else {
      return `${baseUrl}/record-view/${this.record.recordIdentifier}`;
    }
  }

  doShareRecord() {
    this.showShareDialog = true;
  }

  doCloseRecord() {
    this.confirmationService.confirm({
      header: this.translateService.instant("RECORD.actions.close.header", {
        default: "Close record",
      }),
      message: this.translateService.instant("RECORD.actions.close.body", {
        default: "Are you sure you want to close this record ?",
      }),
      acceptLabel: this.translateService.instant("COMMON.buttons.yes"),
      rejectLabel: this.translateService.instant("COMMON.buttons.no"),
      acceptButtonStyleClass: "bg-primary-linear p-button-rounded",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.isClosing = true;

        this.recordService
          .updateRecordStatus(this.record.recordIdentifier, RecordStatusEnum.CLOSED, [ResponseLevelEnum.ALL])
          .pipe(
            finalize(() => (this.isClosing = false)),
            untilDestroyed(this),
          )
          .subscribe((record) => {
            this.record = record;
          });
      },
    });
  }

  doCancelRecord() {
    this.confirmationService.confirm({
      header: this.translateService.instant("RECORD.actions.cancel.header", {
        default: "Revoke record",
      }),
      message: this.translateService.instant("RECORD.actions.cancel.body", {
        default: "Are you sure you want to revoke this record ?",
      }),
      acceptLabel: this.translateService.instant("COMMON.buttons.yes"),
      rejectLabel: this.translateService.instant("COMMON.buttons.no"),
      acceptButtonStyleClass: "bg-primary-linear p-button-rounded",
      rejectButtonStyleClass: "p-button-link",
      accept: () => {
        this.isCancelling = true;

        this.recordService
          .updateRecordStatus(this.record.recordIdentifier, RecordStatusEnum.CANCELED, [ResponseLevelEnum.ALL])
          .pipe(
            finalize(() => (this.isCancelling = false)),
            untilDestroyed(this),
          )
          .subscribe((record) => {
            this.record = record;
          });
      },
    });
  }
}
