import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import SessionModel from "@shared/models/session/session.model";
import { SessionService } from "@shared/services/session.service";
import { KeycloakService } from "keycloak-angular";
import { MenuItem } from "primeng/api";
import { UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "@shared/services/local-storage.service";
import { LocalStorageKeysEnum } from "@shared/enums/local-storage-keys.enum";

@UntilDestroy()
@Component({
  selector: "session-user-menu",
  templateUrl: "./user-menu.view.html",
  styleUrls: ["./user-menu.view.scss"],
})
export class UserMenuView implements OnInit {
  _session: SessionModel = new SessionModel();
  @Input()
  set session(session: SessionModel) {
    this._session = session;
  }
  get session(): SessionModel {
    return this._session;
  }
  // public sessionModel: SessionModel;

  /**
   * Array of menu items TODO: i18n
   */
  items: MenuItem[] = [];

  constructor(
    private translateService: TranslateService,
    private readonly keycloakService: KeycloakService,
    private sessionService: SessionService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        label: this.translateService.instant("SESSION.user-menu.transfers.label", { default: "Transfers" }),
        items: [
          {
            label: this.translateService.instant("SESSION.user-menu.transfers.new.label", {
              default: "New transfer",
            }),
            icon: "pi pi-fw pi-plus",
            routerLink: "oneway-send",
            visible: this.sessionService.unit.isPublic || this.sessionService.userRole != "GUEST",
          },
          {
            label: this.translateService.instant("SESSION.user-menu.transfers.summary.label", {
              default: "Summary",
            }),
            icon: "pi pi-fw pi-history",
            routerLink: "record-summary/records_sent",
          },
        ],
      },
      {
        label: this.translateService.instant("SESSION.user-menu.account.label", { default: "My account" }),
        items: [
          {
            label: this.translateService.instant("SESSION.user-menu.account.profile.label", { default: "My profile" }),
            icon: "pi pi-fw pi-user",
            routerLink: "user-profile",
          },
          {
            label: this.translateService.instant("SESSION.user-menu.account.logout.label", { default: "Disconnect" }),
            icon: "pi pi-fw pi-power-off",
            command: () => this.doLogout(),
          },
        ],
      },
    ];

    this.sessionService.session$.pipe(untilDestroyed(this)).subscribe((data) => {
      this._session = data;
    });
  }

  doLogout(): void {
    this.localStorageService.clear(LocalStorageKeysEnum.CURRENT_UNIT);

    this.keycloakService.logout();
  }
}
