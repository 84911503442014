<div class="padded-container measured-container py-4 stack" style="--space: var(--space-s, 1rem)">
  <ng-container *ngIf="record">
    <record-actions
      [(record)]="record"
      containerClass="p-0 flex flex-wrap-reverse gap-2 mt-2 justify-content-between"
    ></record-actions>
  </ng-container>

  <div class="with-sidebar mt-3" style="--space: var(--space-s, 1rem); --sidebar-width-value: 350">
    <main class="with-sidebar__main">
      <div class="flex flex-column gap-5 justify-content-center" *ngIf="record">
        <div class="postcard-container">
          <div class="postcard">
            <div class="switcher flex-wrap-reverse">
              <div class="postcard__recipients postcard__cell stack">
                <span class="postcard__cell__label">{{
                  "RECORD.fields.recipients.label" | translate: { "default": "Recipients" }
                }}</span>
                <span class="postcard__cell__content">
                  <record-recipients id="recipients" [recipients]="record?.recipients"></record-recipients>
                </span>
              </div>
              <div class="postcard__cell stack">
                <span class="postcard__cell__content md:align-self-end">
                  {{ "COMMON.text.At" | translate: { "default": "At" } }}
                  {{ record.createdAt | date: "mediumDate" }}</span
                >
                <span class="postcard__cell__content md:align-self-end">
                  {{ "RECORD.fields.expire.label" | translate: { "default": "Expire" } }}
                  {{ record.expirationDate | humanDatePipe }}
                  <i class="pi pi-calendar text-xs" [tooltip]="record.expirationDate | date: 'medium'"></i
                ></span>
              </div>
            </div>
            <record-conversation
              *ngIf="record.messages && record.messages.length > 0"
              [messages]="[record.messages[0]]"
              [record]="record"
              [showReply]="false"
              variant="postcard"
              (recordChange)="record = $event"
            ></record-conversation>
          </div>
        </div>
        <p-accordion *ngIf="record.messages && record.messages.length > 0">
          <p-accordionTab [selected]="true">
            <ng-template pTemplate="header">
              <span class="flex align-items-center justify-content-between w-full">
                <p class="font-bold m-0">
                  {{ "RECORD.record-conversation.titles.conversation" | translate: { "default": "Conversation" } }}
                </p>
                <p-badge
                  *ngIf="record.messages.length > 1"
                  [value]="
                    'RECORD.fields.responses.count'
                      | translate: { 'default': '+ responses', 'count': record.messages.length - 1 }
                  "
                ></p-badge>
              </span>
            </ng-template>
            <ng-template pTemplate="content">
              <div id="conversation">
                <record-conversation
                  [messages]="record.messages"
                  [record]="record"
                  [showReply]="!record.isClosed"
                  (recordChange)="record = $event"
                ></record-conversation>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </main>

    <aside class="with-sidebar__sidebar centered-content align-self-baseline">
      <div class="switcher">
        <p-panel
          *ngIf="record"
          [header]="'LAYOUTS.record-events.header.title' | translate: { 'default': 'Events' }"
          [toggleable]="true"
          [collapsed]="true"
          toggler="header"
        >
          <div class="record-events-container">
            <div class="events-container">
              <record-events [(record)]="record"></record-events>
            </div>
            <div class="view-more-container">
              <!-- TODO: Desactiver le temps d'ajouter la method côté back -->
              <button
                pButton
                icon="pi pi-paperclip"
                iconPos="right"
                [label]="'LAYOUTS.record-events.download.label' | translate: { 'default': 'Download logs' }"
                class="p-button-text ml-auto"
                *ngIf="false"
              ></button>
            </div>
          </div>
        </p-panel>
        <p-panel
          [header]="'RECORD.record-all-files.header.title' | translate: { 'default': 'All files' }"
          *ngIf="record"
          [toggleable]="true"
          [collapsed]="true"
          toggler="header"
        >
          <div class="record-files-container">
            <record-all-files [(record)]="record"></record-all-files>
          </div>
        </p-panel>
      </div>
    </aside>
  </div>
</div>
