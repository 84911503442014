import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import BillingAccountModel from "../models/billing/billing-account.model";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  // billing account observable
  private _billingAccount$: BehaviorSubject<BillingAccountModel> = new BehaviorSubject(undefined);
  billingAccount$: Observable<BillingAccountModel> = this._billingAccount$.asObservable();

  get billingAccount(): BillingAccountModel {
    return this._billingAccount$.getValue();
  }
  set billingAccount(billingAccount: BillingAccountModel) {
    this._billingAccount$.next(billingAccount);
  }

  // isLoaded observable
  private _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoaded$ = this._isLoaded$.asObservable();

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getBillingAccount(
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getBillingAccount;
    const correlationId = v4();

    this.loaderService.addOperation("getBillingAccount");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    this._isLoaded$.next(false);
    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          this._isLoaded$.next(true);
          if (response["billingAccount"]) {
            let billingAccount = new BillingAccountModel().deserialize(response["billingAccount"]);
            this._billingAccount$.next(billingAccount);

            return billingAccount;
          }

          return null;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getBillingAccount");
        }),
      );
  }
}
