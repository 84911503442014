import { ISerializable } from "@shared/models/iserializable";
import HeaderModel from "@shared/models/header.model";

export default class PaginationModel implements ISerializable {
  public limitOffset?: number;
  public limitCount?: number;
  public totalCount?: number;

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toJson() {
    return JSON.parse(this.serialize());
  }

  addPagination(response: any): this {
    if (response.header) {
      this.limitOffset = response.header.limitOffset;
      this.limitCount = response.header.limitCount;
      this.totalCount = response.header.totalCount;
    }
    return this;
  }
}
