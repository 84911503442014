<div *ngIf="user" class="stack">
  <div class="flex gap-3 align-items-center">
    <p-avatar [label]="user | getUserInitials" shape="circle" size="large" styleClass="mr-2"></p-avatar>
    <div class="stack">
      <span><b>{{user.emailAddress}}</b></span>
      <div class="flex flex-wrap gap-2" *ngIf="false">
        <button
          pButton
          class="p-button-sm p-button-outlined p-button-rounded"
          [label]="'COMMON.buttons.change' | translate: {'default': 'Change'}"
          data-attr="user-profile-avatar-change"
        ></button>
        <button
          pButton
          class="p-button-sm p-button-outlined p-button-rounded"
          icon="pi pi-trash"
          data-attr="user-profile-avatar-remove"
        ></button>
      </div>
    </div>
  </div>
  <div class="field" style="margin-top: 1.2rem">
    <label for="userGivenName" class="block"
      >{{('COMMON.fields.first-name.label' | translate : {'default': 'First name'})}}</label
    >
    <input
      id="userGivenName"
      type="text"
      (change)="onGivenNameChange($event)"
      [value]="user.givenName"
      [readonly]="isLoading"
      [ngClass]="{'ng-invalid ng-dirty' : userGivenNameError}"
      pInputText
    />
    <small class="p-error block">{{userGivenNameError}}</small>
  </div>
  <div class="field" style="margin-top: 1.2rem">
    <label for="userFamilyName" class="block"
      >{{('COMMON.fields.last-name.label' | translate : {'default': 'Last name'})}}</label
    >
    <input
      id="userFamilyName"
      type="text"
      (change)="onFamilyNameChange($event)"
      [value]="user.familyName"
      [readonly]="isLoading"
      [ngClass]="{'ng-invalid ng-dirty' : userFamilyNameError}"
      pInputText
    />
    <small class="p-error block">{{userFamilyNameError}}</small>
  </div>
  <div class="field" style="margin-top: 1.2rem">
    <label for="userLocale" class="block"
      >{{('COMMON.fields.locale.label' | translate : {'default': 'Language'})}}</label
    >
    <p-selectButton
      id="userLocale"
      optionLabel="labelAbbr"
      optionValue="value"
      [options]="localeOptions"
      [ngModel]="user.locale"
      (onChange)="onLocaleChange($event)"
    ></p-selectButton>
  </div>
  <div class="field" style="margin-top: 1.2rem">
    <span class="w-full font-medium">{{('USER.fields.consents.label' | translate : {'default': 'Consents'})}}</span>
    <session-user-consents-edit [(user)]="user" (userChange)="isChanged=true"></session-user-consents-edit>
  </div>
  <button
    class="p-button p-button-rounded ml-auto"
    [label]="('COMMON.buttons.submit' | translate : {'default': 'Submit'})"
    (click)="doUpdateUser()"
    [disabled]="isLoading || !isValid || !isChanged"
    data-attr="user-profile-submit"
    pButton
  ></button>
  <p-confirmDialog></p-confirmDialog>
</div>
