<ng-container *ngIf="!isLoading;else loadingTemplate">
  <record-header [(record)]="record"></record-header>
  <record-oneway-view [(record)]="record"></record-oneway-view>
</ng-container>

<div *ngIf="loadingError" class="measured-container cover" style="--measure: 50rem">
  <div class="cover__centered border-round py-5 px-3 bg-white shadow-1">
    <div class="stack text-center" style="--space: 1.5rem; --align: center">
      <div
        class="flex align-items-center justify-content-center surface-100 border-round"
        style="width: 3rem; height: 3rem"
      >
        <i class="pi pi-file-excel text-color text-2xl"></i>
      </div>
      <h2>{{loadingError.title | translate: {'default': 'Error occured'} }}</h2>
      <p class="text-color" style="--space: 0">{{loadingError.subtitle | translate: {'default': 'Error occured'} }}</p>
      <p class="text-color" style="--space: 0">
        {{'ERRORS.current-account' | translate: {'default': 'You are connected with'} }}
        <b>{{sessionService.currentUser.emailAddress}}</b>
      </p>
      <button pButton (click)="doLogout()" class="p-button-outlined" data-attr="consents-update">
        {{('COMMON.buttons.disconnect' | translate : {'default': 'Disconnect'})}}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <ui-page-spinner></ui-page-spinner>
</ng-template>
