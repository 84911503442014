<div class="flex align-items-center justify-content-between" style="--space: 0.1rem; min-height: 3rem">
  <div class="flex flex-wrap gap-1 align-items-center">
    <p-avatar
      *ngIf="session?.user"
      [label]="session.user | getUserInitials"
      shape="circle"
      styleClass="mr-2"
    ></p-avatar>
    <p-skeleton data-skeleton--circle *ngIf="!session?.user" shape="circle" size="2rem" class="mr-2"></p-skeleton>
    <div class="stack" style="--space: 0.1rem; --align: start">
      <span *ngIf="showRole && session?.userRole" data-avatar--role>{{
        "USER.roles." + session.userRole + ".label" | translate: { default: session.userRole }
      }}</span>
      <p-skeleton data-skeleton--text *ngIf="showRole && !session?.userRole" width="3rem" height="1.15rem"></p-skeleton>
      <span data-avatar--name>{{ session.user | getUserFullName }}</span>
      <p-skeleton data-skeleton--text *ngIf="!session?.user" height="1.15rem" width="8rem"></p-skeleton>
    </div>
  </div>
</div>
