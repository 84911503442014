import { Pipe, PipeTransform } from "@angular/core";
import * as filesize_ from "filesize";
import { FilesizeConfig } from "../config/filesize";
import { I18nService } from "../i18n/i18n.service";

@Pipe({
  name: "filesize",
})
export class FileSizePipe implements PipeTransform {
  constructor(private i18nService: I18nService, private fileSizeConfig: FilesizeConfig) {}

  private transformOne(value: number, options: any): string {
    const filesize = filesize_;
    return filesize(value, options);
  }

  transform(value: number | number[], options?: any) {
    if (!options) {
      options = this.getConfig();
    }

    if (Array.isArray(value)) {
      return value.map((val) => this.transformOne(val, options));
    }

    return this.transformOne(value, options);
  }

  getConfig() {
    return this.fileSizeConfig.getConfig(this.i18nService.language);
  }
}
