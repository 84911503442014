import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StringHelper {
  constructor() {}

  static splitClassValue(value: string): string[] {
    return value.split(".");
  }

  static normalizeClass(...args: string[]): string {
    return args.reduce((classes: string[], arg: string) => [...classes, arg], []).join(".");
  }

  static isEmail(value: string): boolean {
    const EMAIL_VALIDATOR = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    return EMAIL_VALIDATOR.test(value);
  }

  static isEmpty(value: string): boolean {
    return value == null || value == "";
  }
}
