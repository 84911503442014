<p-chips
  #chips
  class="recipients-custom-chips"
  [(ngModel)]="emailAddresses"
  [placeholder]="'RECORD.recipients.input.placeholder' | translate: { 'default': 'john.doe@domain.com' }"
  (onAdd)="doAdd($event)"
  (onRemove)="doRemove($event)"
  [addOnBlur]="true"
  [max]="maxRecipient"
  [allowDuplicate]="false"
  separator=","
  [addOnTab]="true"
  [inputId]="recipientsInputId"
  [disabled]="disabled"
>
  <ng-template let-email pTemplate="chip">
    <span class="recipients-custom-chips-text">{{ email }}</span>
  </ng-template>
</p-chips>
<div *ngIf="invalidRecipientsCount > 0" class="flex flex-wrap text-color gap-2 ml-2 mt-1">
  <span class="flex align-items-center gap-2 text-sm">
    <i class="pi pi-exclamation-circle" style="color: var(--red-500)"></i>
    <div>
      {{
        "RECORD.recipients.input.errors.invalid"
          | translate: { "default": invalidRecipientsCount + " invalid(s)", "count": invalidRecipientsCount }
      }}
    </div>
  </span>
  <button
    (click)="doSanitizeRecipients()"
    type="button"
    pButton
    class="p-button-link w-max py-0 px-1 p-button-sm"
    [label]="'RECORD.recipients.input.remove-invalid' | translate: { 'default': 'Remove all invalid' }"
    data-attr="record-recipients-sanitize"
  ></button>
</div>
