import { Pipe, PipeTransform } from "@angular/core";
import UserModel from "../models/user/user.model";
import UnitModel from "@shared/models/domain/unit.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { I18nService } from "@shared/i18n/i18n.service";

@Pipe({
  name: "humanDatePipe",
})
export class HumanDatePipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: Date) {
    if (value) {
      return DateHelper.humanizeDate(value, this.i18nService.language);
    } else {
      return "";
    }
  }
}
