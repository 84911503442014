import RecordModel from "@shared/models/record/record.model";
import PaginationModel from "@shared/models/pagination.model";

export default class CellSummaryModel extends PaginationModel {
  // MINIMIZE (default)
  public cellType: string | null = null;
  public cellValueType?: string | null = null;
  public cellValueFormat: string | null = "";
  public cellValue?: string = "";

  public cellDetails?: RecordModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.cellDetails && Array.isArray(input.cellDetails))
      this.cellDetails = input.cellDetails.map((record: any) => new RecordModel().deserialize(record));

    return this;
  }
}
