<main-sub-header
  [title]="'LAYOUTS.user-profile.header.title' | translate : {'default': 'Update your profile'}"
  [subTitle]="'LAYOUTS.user-profile.header.subtitle' | translate : {'default': 'Change your information & preferences'}"
></main-sub-header>
<div class="padded-container measured-container py-3 justify-content-center" style="--measure: 50rem">
  <session-user-profile-edit-view
    [user]="sessionService.currentUser$ | async"
    [identity]="(sessionService.currentUser$ | async).identities[0]"
    [isLoading]="sessionService.isLoading$ | async"
  ></session-user-profile-edit-view>
</div>
