import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

import { UserProfileLayout } from "@app/layouts/user-profile/user-profile.layout";
import { OnewaySendLayout } from "@app/layouts/oneway-send/oneway-send.layout";
import { RecordSummaryLayout } from "@app/layouts/record-summary/record-summary.layout";
import { OnewayViewLayout } from "@app/layouts/oneway-view/oneway-view.layout";
import { SubscribeLayout } from "@app/layouts/subscribe/subscribe.layout";
import { CanAccessOnewayGuard, CanAccessOnewaySendGuard } from "./@shared/guards";
import { HomePageLayout } from "./layouts/home-page/home-page.layout";

const routes: Routes = [
  { path: "", redirectTo: "/oneway-send", pathMatch: "full" },
  { path: "home", component: HomePageLayout, data: { title: marker("TITLES.home") } },
  { path: "user-profile", component: UserProfileLayout, data: { title: marker("TITLES.user-profile") } },
  {
    path: "oneway-send",
    component: OnewaySendLayout,
    data: { title: marker("TITLES.oneway-send") },
    canActivate: [CanAccessOnewayGuard, CanAccessOnewaySendGuard],
  },
  { path: "oneway-view/:recordIdentifier", component: OnewayViewLayout, data: { title: marker("TITLES.oneway-view") } },
  {
    path: "record-summary/:cellType",
    component: RecordSummaryLayout,
    data: { title: marker("TITLES.record-summary") },
    canActivate: [CanAccessOnewayGuard],
  },
  { path: "subscribe", component: SubscribeLayout, data: { title: marker("TITLES.subscribe") } },
  {
    path: "errors",
    loadChildren: () => import("./errors/errors.module").then((m) => m.ErrorsModule),
  },
  { path: "**", redirectTo: "/oneway-send" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
