import BaseModel from "@shared/models/base.model";
import FileModel from "@shared/models/file/file.model";

export default class RecordMessageAttachmentModel extends BaseModel {
  // DEFAULT (default)
  public attachmentIdentifier: string | null = null;
  public file: FileModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.file) this.file = new FileModel().deserialize(input.file);

    return this;
  }
}
