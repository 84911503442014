import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { ServiceWorkerModule } from "@angular/service-worker";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MissingTranslationHandler, TranslateCompiler, TranslateModule } from "@ngx-translate/core";
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";
import { NgxTranslateRoutesModule } from "ngx-translate-routes";
import { environment } from "@env/environment";
import { CoreModule } from "@core";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "@shared/shared.module";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import * as Sentry from "@sentry/angular";
import { ConfirmationService, MessageService } from "primeng/api";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserProfileLayout } from "@app/layouts/user-profile/user-profile.layout";
import { OnewaySendLayout } from "@app/layouts/oneway-send/oneway-send.layout";
import { RecordSummaryLayout } from "@app/layouts/record-summary/record-summary.layout";
import { OnewayViewLayout } from "@app/layouts/oneway-view/oneway-view.layout";
import { UiModule } from "./@ui/ui.module";
import { MainModule } from "./main/main.module";
import { SessionModule } from "./session/session.module";
import { RecordModule } from "./record/record.module";
import { MissingI18nHandler } from "@shared/i18n/missing-i18n.handler";
import { SubscribeLayout } from "@app/layouts/subscribe/subscribe.layout";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingI18nHandler,
      },
    }),
    NgxTranslateRoutesModule.forRoot({
      enableTitleTranslate: true,
      enableRouteTranslate: false,
    }),
    FormsModule,
    ReactiveFormsModule,
    // Needed to clean service worker from client
    ServiceWorkerModule.register("safety-worker.js", { enabled: true }),
    KeycloakAngularModule,

    CoreModule,
    SharedModule,
    AppRoutingModule,

    UiModule,
    MainModule,
    SessionModule,
    RecordModule,
  ],
  declarations: [
    AppComponent,
    UserProfileLayout,
    OnewaySendLayout,
    RecordSummaryLayout,
    OnewayViewLayout,
    SubscribeLayout,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Sentry.TraceService],
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: environment.supportedLanguages },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId,
      },
      initOptions: {
        onLoad: "login-required",
      },
    });
}
