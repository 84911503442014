import BaseModel from "@shared/models/base.model";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import { DateHelper } from "@shared/helpers/date.helper";

export default class CreateRecordModel extends BaseModel {
  public title: string | null = "";
  public topic?: string = "";
  public type: string = "";

  public dueDate: Date | null = null;
  public expirationDate: Date | null = null;

  public locale?: string;

  public message: RecordMessageModel | null = null;
  public recipients: RecordRecipientModel[] = [];

  public tags: Object = [];

  public notify: boolean = true;

  public parentIdentifier?: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.dueDate) this.dueDate = DateHelper.parseDate(input.dueDate);

    if (input.expirationDate) this.expirationDate = DateHelper.parseDate(input.expirationDate);

    if (input.recipients && Array.isArray(input.recipients))
      this.recipients = input.recipients.map((recipient: any) => new RecordRecipientModel().deserialize(recipient));

    this.message = new RecordMessageModel().deserialize(input.message);

    return this;
  }
}
