import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";

@Injectable({
  providedIn: "root",
})
export class MessageHelper {
  constructor() {}

  static createErrorMessage(severity: MessageSeverityEnum, error: HttpErrorResponse): any {
    if (error) {
      return { severity: severity || MessageSeverityEnum.SEVERITY_INFO, summary: error.status, detail: error.message };
    }
    return undefined;
  }

  static createTextMessage(severity: MessageSeverityEnum, summary: string, detail: string): any {
    return { severity: severity || MessageSeverityEnum.SEVERITY_INFO, summary: summary, detail: detail };
  }
}
