<main-sub-header
  [title]="'LAYOUTS.oneway-send.header.title' | translate: {'default': 'Send files/messages'}"
  [subTitle]="'LAYOUTS.oneway-send.header.subtitle' | translate: {'default': 'Transfer large files or open a discussion'}"
></main-sub-header>
<div class="py-3 padded-container flex flex-column measured-container">
  <record-oneway-form-view
    *ngIf="record == undefined"
    [(record)]="record"
    [defaultRecipient]="defaultRecipient"
    [defaultTitle]="defaultTitle"
  ></record-oneway-form-view>
  <record-oneway-review
    *ngIf="(record != undefined && record.status == RecordStatusEnum.CREATED)"
    [(record)]="record"
  ></record-oneway-review>
  <record-confirmation
    *ngIf="(record != undefined && record.status == RecordStatusEnum.SENT)"
    [(record)]="record"
  ></record-confirmation>
</div>
