import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";

@Component({
  selector: "record-recipients",
  templateUrl: "./recipients.component.html",
  styleUrls: ["./recipients.component.scss"],
})
export class RecipientsComponent implements OnInit, OnDestroy {
  @Input() recipients: RecordRecipientModel[];

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
