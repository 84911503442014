import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { StringHelper } from "@shared/helpers/string.helper";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { ChannelEnum } from "@shared/enums/channel.enum";
import { RecordRoleEnum } from "@shared/enums/record-role.enum";
import { Chips } from "primeng/chips";

@Component({
  selector: "record-recipients-chips",
  templateUrl: "./recipients-chips.component.html",
  styleUrls: ["./recipients-chips.component.scss"],
})
export class RecipientsChipsComponent implements OnInit, OnDestroy {
  @Input() maxRecipient: number = 3;

  emailAddresses: string[];
  invalidRecipientsCount: number = 0;

  _recipients: RecordRecipientModel[];
  @Output() recipientsChange = new EventEmitter<RecordRecipientModel[]>();
  @Input()
  set recipients(recipients: RecordRecipientModel[]) {
    this._recipients = recipients;
    this.checkInvalidRecipients();
    this.emailAddresses = this._recipients.map((recipient) => {
      return recipient.emailAddress;
    });
    this.recipientsChange.emit(this._recipients);
  }
  get recipients(): RecordRecipientModel[] {
    return this._recipients;
  }
  @Input() recipientsInputId: string = "";
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit() {}
  @ViewChild("chips", { static: true }) chips: Chips;
  ngAfterViewInit() {
    (this.chips.inputViewChild.nativeElement as HTMLInputElement).type = "email";
  }

  ngOnDestroy() {}

  isValidEmail(value) {
    return StringHelper.isEmail(value.trim());
  }

  doSanitizeRecipients() {
    this._recipients = this._recipients.filter((recipient) => {
      return StringHelper.isEmail(recipient.emailAddress);
    });

    this.emailAddresses = this._recipients.map((recipient) => {
      return recipient.emailAddress;
    });

    this.checkInvalidRecipients();
  }

  doAdd(event) {
    let recipient: RecordRecipientModel = new RecordRecipientModel();
    recipient.emailAddress = event.value.trim();
    recipient.channel = ChannelEnum.EMAIL;
    recipient.role = RecordRoleEnum.WRITER;
    this._recipients.push(recipient);
    this.checkInvalidRecipients();
    this.recipientsChange.emit(this._recipients);
  }

  doRemove(event) {
    this._recipients = this._recipients.filter((recipient) => {
      return recipient.emailAddress != event.value.trim();
    });
    this.checkInvalidRecipients();
    this.recipientsChange.emit(this._recipients);
  }

  checkInvalidRecipients() {
    this.invalidRecipientsCount = 0;
    this._recipients.forEach((recipient) => {
      if (!this.isValidEmail(recipient.emailAddress)) {
        this.invalidRecipientsCount++;
      }
    });

    setTimeout(() => {
      this._recipients?.forEach((recipient, index) => {
        document
          .querySelector(`.recipients-custom-chips li.p-chips-token:nth-child(${index + 1})`)
          ?.classList.toggle("data-chip-invalid", !this.isValidEmail(recipient.emailAddress));
      });
    }, 0);
  }
}
