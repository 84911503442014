import { Component, OnInit } from "@angular/core";
import CellSummaryModel from "@shared/models/record/cell-summary.model";
import { RecordService } from "@shared/services/record.service";
import { DateHelper } from "@shared/helpers/date.helper";
import { ActivatedRoute, Router } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@core";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";
import { SessionService } from "@shared/services/session.service";

@UntilDestroy()
@Component({
  selector: "app-record-summary",
  templateUrl: "./record-summary.layout.html",
  styleUrls: ["./record-summary.layout.scss"],
})
export class RecordSummaryLayout implements OnInit {
  cells: CellSummaryModel[];

  hiddenCells: string[] = ["RECORDS_INVITATION", "RECORDS_SIGNATURE"];
  selectedCellType: string = "RECORDS_SENT";
  showConsents: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public sessionService: SessionService,
    private recordService: RecordService,
  ) {}

  ngOnInit(): void {
    let now = new Date();

    this.recordService.getRecordsSummary(null, [RecordTypeEnum.ONEWAY]).subscribe((value) => {
      this.cells = value;
    });

    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      this.selectedCellType = params["cellType"].toUpperCase();
    });

    if (this.sessionService.currentUser) {
      this.showConsents = this.sessionService.currentUser.properties["consent-analytics"] === undefined;
    }
  }
}
