<p-dataView [value]="recipients" emptyMessage="No recipients">
  <ng-template let-item pTemplate="listItem">
    <div *ngIf="item.role != 'BOT'" class="col-12 mb-1" style="border: 0px; color: var(--text-color-secondary)">
      <i
        class="pi"
        [ngClass]="{
          'pi-send': item.role === 'OWNER',
          'pi-eye': item.role === 'READER',
          'pi-envelope': item.role !== 'OWNER' && item.role !== 'READER'
        }"
      ></i>
      {{ item.emailAddress }}
    </div>
  </ng-template>
</p-dataView>
