import BaseModel from "@shared/models/base.model";
import UnitModel from "@shared/models/domain/unit.model";
import UserModel from "@shared/models/user/user.model";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordEventModel from "@shared/models/record/record-event.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";

export default class RecordModel extends BaseModel {
  // MINIMIZE (default)
  public recordIdentifier: string | null = null;
  public parentIdentifier?: string | null = null;
  public title: string | null = "";
  public topic?: string = "";
  public type: RecordTypeEnum = RecordTypeEnum.TRANSFER;
  public status: RecordStatusEnum = RecordStatusEnum.CREATED;
  public isCancelled: boolean = false;
  public isClosed: boolean = false;
  public updatedAt: Date | null = null;
  public createdAt: Date | null = null;
  public dueDate: Date | null = null;
  public expirationDate: Date | null = null;
  public locale?: string;

  public unit: UnitModel | null = null;
  public owner: UserModel | null = null;
  public recipients: RecordRecipientModel[] = [];

  // RECORD_MESSAGES
  public messages: RecordMessageModel[] = [];

  // RECORD_DETAILS
  public tags: string[] = [];
  public events: RecordEventModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.dueDate) this.dueDate = DateHelper.parseDate(input.dueDate);

    if (input.expirationDate) this.expirationDate = DateHelper.parseDate(input.expirationDate);

    if (input.unit) this.unit = new UnitModel().deserialize(input.unit);
    if (input.owner) this.owner = new UserModel().deserialize(input.owner);

    if (input.recipients && Array.isArray(input.recipients))
      this.recipients = input.recipients.map((recipient: any) => new RecordRecipientModel().deserialize(recipient));

    if (input.messages && Array.isArray(input.messages))
      this.messages = input.messages.map((message: any) => new RecordMessageModel().deserialize(message));

    if (input.events && Array.isArray(input.events))
      this.events = input.events.map((event: any) => new RecordEventModel().deserialize(event));

    return this;
  }

  isOwner(userIdentifier: string): boolean {
    return this.owner && this.owner.userIdentifier === userIdentifier;
  }
}
