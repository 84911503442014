import { ContentChild, Directive, HostBinding, HostListener } from "@angular/core";
import { fromEvent } from "file-selector";
import { UploadxDirective, UploadxService } from "ngx-uploadx";

@Directive({ selector: "[uploadDrop]" })
export class UploadDropDirective {
  @HostBinding("class.uploadx-drop-active")
  active = false;

  @ContentChild(UploadxDirective, { static: false })
  fileInput?: UploadxDirective;

  constructor(private uploadService: UploadxService) {}

  @HostListener("drop", ["$event"])
  async dropHandler(event: DragEvent): Promise<void> {
    // https://github.com/react-dropzone/file-selector/blob/master/src/file-selector.ts
    // event not assigned to a variable, function will run a reader that will flatten files from a directory
    // into an array of files. Directories are now supported in dropzone and button
    await fromEvent(event);

    this._stopEvents(event);
    this.active = false;
    if (event.dataTransfer && event.dataTransfer.files.item(0)) {
      this.fileInput
        ? this.fileInput.fileListener(event.dataTransfer.files)
        : this.uploadService.handleFiles(event.dataTransfer.files);
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent): void {
    this._stopEvents(event);
    if (event.dataTransfer && event.dataTransfer.types.length > 0) {
      event.dataTransfer.dropEffect = "copy";
      this.active = true;
    }
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event: DragEvent): void {
    this._stopEvents(event);
    this.active = false;
  }

  protected _stopEvents(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
