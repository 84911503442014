import { Component, OnInit } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordStatusEnum } from "@app/@shared/enums/record-status.enum";

@Component({
  selector: "app-oneway-send",
  templateUrl: "./oneway-send.layout.html",
  styleUrls: ["./oneway-send.layout.scss"],
})
export class OnewaySendLayout implements OnInit {
  public record: RecordModel;

  defaultRecipient: string;
  defaultTitle: string;
  RecordStatusEnum = RecordStatusEnum;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.defaultRecipient = params.get("recipient");
      this.defaultTitle = params.get("title");
    });
  }
}
