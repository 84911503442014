import { Component, OnInit } from "@angular/core";
import { SessionService } from "@shared/services/session.service";
import SessionModel from "@shared/models/session/session.model";
import { Subscription } from "rxjs";
import UserModel from "@shared/models/user/user.model";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.layout.html",
  styleUrls: ["./user-profile.layout.scss"],
})
export class UserProfileLayout implements OnInit {
  constructor(public sessionService: SessionService) {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
