import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { finalize } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@core";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@shared/services/record.service";
import posthog from "posthog-js";

@UntilDestroy()
@Component({
  selector: "record-oneway-review",
  templateUrl: "./record-oneway-review.view.html",
  styleUrls: ["./record-oneway-review.view.scss"],
})
export class RecordOnewayReviewView implements OnInit {
  _record: RecordModel = new RecordModel();
  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordMainMessage = this._record.messages[0];
  }
  get record(): RecordModel {
    return this._record;
  }

  recordMainMessage: RecordMessageModel;
  isConfirming: boolean = false;
  isCancelling: boolean = false;

  constructor(private recordService: RecordService) {}

  ngOnInit(): void {}

  doConfirm() {
    this.isConfirming = true;

    this.recordService
      .updateRecordStatus(this._record.recordIdentifier, RecordStatusEnum.SENT, [ResponseLevelEnum.ALL])
      .pipe(
        finalize(() => (this.isConfirming = false)),
        untilDestroyed(this),
      )
      .subscribe((record) => {
        this._record = record;
        this.recordChange.emit(this._record);

        posthog.capture("record-submitted", { recipientsCount: record.recipients.length });
      });
  }

  doCancel() {
    this.isCancelling = true;

    this.recordService
      .updateRecordStatus(this._record.recordIdentifier, RecordStatusEnum.CANCELED, [ResponseLevelEnum.MINIMIZE])
      .pipe(
        finalize(() => (this.isCancelling = false)),
        untilDestroyed(this),
      )
      .subscribe((record) => {
        this._record = undefined;
        this.recordChange.emit(this._record);
      });
  }

  get displayMainMessage() {
    return (
      this.recordMainMessage &&
      (this.recordMainMessage.attachments.length > 0 || this.recordMainMessage.body?.length > 0)
    );
  }

  getAttachmentsSize() {
    return this.recordMainMessage?.attachments.reduce((acc, { file }) => acc + file.size, 0);
  }
}
