import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ui-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
})
export class BadgeComponent implements OnInit {
  @Input() variant: string = "info";
  @Input() label: string;
  constructor() {}

  ngOnInit(): void {}
}
