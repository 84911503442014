<div class="my-2">
  <div>{{ "RECORD.record-confirmation.record-link" | translate: { "default": "Share this link" } }}</div>
  <div class="p-inputgroup my-1">
    <input readonly [id]="id" [name]="name" type="text" [value]="value" class="text-color" pInputText />
    <button
      class="p-button"
      [ngClass]="{ 'p-button-success': copySuccess }"
      type="button"
      pButton
      pRipple
      [icon]="copySuccess ? 'pi pi-check' : 'pi pi-copy'"
      (click)="doCopyToClipboard()"
      [label]="label"
      data-attr="record-link-to-clipboard"
    ></button>
  </div>
</div>
