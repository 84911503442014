import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, finalize, Observable, throwError, catchError, map, mergeMap, forkJoin } from "rxjs";
import { LoaderService } from "@core/loader";
import SessionModel from "@shared/models/session/session.model";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import UnitModel from "@shared/models/domain/unit.model";
import { UserRoleEnum } from "../enums/user-role.enum";
import UserModel from "../models/user/user.model";
import { KeyvaultService } from "./keyvault.service";
import { Logger } from "@app/@core";
import { I18nService } from "../i18n/i18n.service";
import IdentityModel from "../models/user/identity.model";
import { BillingService } from "./billing.service";
import { LocalStorageService } from "./local-storage.service";
import { LocalStorageKeysEnum } from "@shared/enums/local-storage-keys.enum";
import chroma from "chroma-js";
import { StylesHelper } from "../helpers/styles.helper";

const log = new Logger("SessionService");

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  // session observable
  private _session$: BehaviorSubject<SessionModel> = new BehaviorSubject(undefined);
  session$: Observable<SessionModel> = this._session$.asObservable();

  get session(): SessionModel {
    return this._session$.getValue();
  }
  set session(session: SessionModel) {
    this._session$.next(session);
  }

  // current user observable
  private _currentUser$: BehaviorSubject<UserModel> = new BehaviorSubject(undefined);
  currentUser$: Observable<UserModel> = this._currentUser$.asObservable();

  get currentUser() {
    return this._currentUser$.getValue();
  }
  set currentUser(user: UserModel) {
    this._currentUser$.next(user);
  }

  // current identity observable
  private _currentIdentity$: BehaviorSubject<IdentityModel> = new BehaviorSubject(undefined);
  currentIdentity$: Observable<IdentityModel> = this._currentIdentity$.asObservable();

  get currentIdentity() {
    return this._currentIdentity$.getValue();
  }
  set currentIdentity(user: IdentityModel) {
    this._currentIdentity$.next(user);
  }

  // unit observable
  private _unit$: BehaviorSubject<UnitModel> = new BehaviorSubject(undefined);
  unit$: Observable<UnitModel> = this._unit$.asObservable();

  get unit(): UnitModel {
    return this._unit$.getValue();
  }
  set unit(unit: UnitModel) {
    this._unit$.next(unit);
  }

  // user role observable
  private _userRole$: BehaviorSubject<UserRoleEnum> = new BehaviorSubject(undefined);
  userRole$: Observable<UserRoleEnum> = this._userRole$.asObservable();

  get userRole() {
    return this._userRole$.getValue();
  }
  set userRole(userRole: UserRoleEnum) {
    this._userRole$.next(userRole);
  }

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isLoading$ = this._isLoading$.asObservable();

  // isLoaded observable
  private _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoaded$ = this._isLoaded$.asObservable();

  constructor(
    private readonly keycloakService: KeycloakService,
    private i18nService: I18nService,
    private localStorageService: LocalStorageService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private keyvaultService: KeyvaultService,
    private billingService: BillingService,
  ) {}

  loadSessionInfo(
    unitSlug?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<SessionModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.session.getSessionInfo.replace("{unitSlug}", unitSlug || environment.defaultUnit);
    const correlationId = v4();

    this.loaderService.addOperation("loadSessionInfo");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    this._isLoading$.next(true);
    this._isLoaded$.next(false);
    log.info("Main session initialization");
    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let session: SessionModel = new SessionModel().deserialize(response["session"]);
          let mainIdentity = session.user.identities.find((identity: IdentityModel) => identity.isMain);
          this._session$.next(session);
          this._unit$.next(session.unit);
          this._currentUser$.next(session.user);
          this._currentIdentity$.next(mainIdentity || session.user.identities[0]);
          this._userRole$.next(session.userRole);
          this._isLoaded$.next(true);

          // Set locale from session
          log.info("Setting locale " + session.locale + " from session");
          this.i18nService.language =
            session.locale && environment.supportedLanguages.includes(session.locale)
              ? session.locale
              : environment.defaultLanguage;

          // Set unit in local storage from session
          this.localStorageService.setCurrentUnit(session.user.userIdentifier, session.unit.unitSlug);

          return session;
        }),
        mergeMap((session: SessionModel) => {
          log.info("Keyvault initialization");
          log.info("Billing account loading");
          return forkJoin({
            vaultSession: this.keyvaultService.initSession(),
            billingAccount: this.billingService.getBillingAccount([ResponseLevelEnum.ALL]),
          }).pipe(
            map(({ vaultSession, billingAccount }) => {
              log.info("Keyvault initialized");
              log.info("Billing account loaded");
              return session;
            }),
          );
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("loadSessionInfo");
          this._isLoading$.next(false);
        }),
      );
  }

  // Another proxy, for development comfort
  reloadSession(): Observable<SessionModel> {
    return this.loadSessionInfo(this.unit.unitSlug, [ResponseLevelEnum.ALL]);
  }
}
