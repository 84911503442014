import { BillingAccountScopeEnum } from "@app/@shared/enums/billing-account-scope.enum";
import BaseModel from "@shared/models/base.model";
import BillingAccountMemberModel from "./billing-account-member.model";

export default class BillingAccountModel extends BaseModel {
  // MINIMIZE (default)
  public billingAccountIdentifier: string | null = null;
  public scope?: BillingAccountScopeEnum;
  public firstName?: string;
  public lastName?: string;
  public companyName?: string;
  public emailAddress?: string;
  public phone?: string;
  public addressCity?: string;
  public addressPostalCode?: string;
  public addressState?: string;
  public addressCountry?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public metadata: JSONObject = {};

  // BILLING_ACCOUNT_MEMBERS
  public members: BillingAccountMemberModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.members)
      this.members = input.members.map((member: any) => new BillingAccountMemberModel().deserialize(member));

    return this;
  }
}
