import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 } from "uuid";
import FileModel from "@shared/models/file/file.model";

@Injectable({
  providedIn: "root",
})
export class FilesService {
  private _baseUrl: string = environment.services.baseUrls.filesApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  recordStartUpload(fileName: string, fileMimeType: string, fileSize: number): Observable<string> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordStartUpload;
    const correlationId = v4();

    this.loaderService.addOperation("recordStartUpload");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("fileMimeType", fileMimeType);
    formData.append("fileSize", fileSize.toString());

    return this.httpService.post(url, formData, { headers, observe: "response" }).pipe(
      map((response: any) => {
        const fileIdentifier: string = response.headers.get(HttpHeadersEnum.FILE_IDENTIFIER);
        return fileIdentifier;
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordStartUpload");
      }),
    );
  }

  recordFinishUpload(fileIdentifier: string, recordIdentifier: string, fileHash: string): Observable<FileModel> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordFinishUpload;
    const correlationId = v4();

    this.loaderService.addOperation("recordFinishUpload");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("fileIdentifier", fileIdentifier);
    formData.append("recordIdentifier", recordIdentifier);
    formData.append("fileHash", fileHash);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return new FileModel().deserialize(response);
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordFinishUpload");
      }),
    );
  }

  recordDownloadFile(file: FileModel, recordIdentifier: string): Observable<Blob> {
    this.loaderService.addOperation("recordDownloadFile");
    const headers = new HttpHeaders().set(
      HttpHeadersEnum.AUTHORIZATION,
      AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken(),
    );
    const recordUrl = new URL(file.url);
    recordUrl.searchParams.append("recordIdentifier", recordIdentifier);
    return this.httpService.get(recordUrl.href, { headers, responseType: "blob" }).pipe(
      map((response: any) => {
        return new File([response], file.name, {
          type: file.mimeType,
        });
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordDownloadFile");
      }),
    );
  }
}
