import { Component, OnInit } from "@angular/core";
import { LoaderService } from "@core/loader";
import { UntilDestroy, untilDestroyed } from "@core";

@UntilDestroy()
@Component({
  selector: "ui-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  showSpinner: boolean = false;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.visible$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.showSpinner = data;
    });
  }
}
