<p-button styleClass="p-button-text p-button-sm py-1 px-3" (click)="userMenu.toggle($event)">
  <ng-template pTemplate="content">
    <div class="flex align-items-center" style="min-height: 3rem">
      <p-avatar [label]="session?.user | getUserInitials" shape="circle" styleClass="mr-2 lg:hidden"></p-avatar>
    </div>
    <session-user-role-card
      [showRole]="false"
      class="mr-3 hidden lg:block"
      [session]="this.session"
    ></session-user-role-card>
    <i class="pi pi-angle-down text-500"></i>
  </ng-template>
</p-button>
<p-menu appendTo="body" #userMenu [popup]="true" [model]="items"></p-menu>
