import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@shared/services/record.service";
import { DateHelper } from "@shared/helpers/date.helper";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { RecordOrderByEnum } from "@shared/enums/record-order-by.enum";
import { TranslateService } from "@ngx-translate/core";
import { UntilDestroy, untilDestroyed } from "@core";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";

@UntilDestroy()
@Component({
  selector: "record-summary-details",
  templateUrl: "./record-summary-details.component.html",
  styleUrls: ["./record-summary-details.component.scss"],
})
export class RecordSummaryDetailsComponent implements OnInit {
  limitOffset = 0;
  limitCount = 10;
  totalCount = 0;

  _cellType: string;
  selectedOrderBy: RecordOrderByEnum = RecordOrderByEnum.RECORDS_UPDATED_DESC;

  @Output() cellTypeChange = new EventEmitter<string>();
  @Input()
  set cellType(cellType: string) {
    this._cellType = cellType;
    this.loadDetails(cellType, 0, this.selectedOrderBy);
    this.cellTypeChange.emit(this._cellType);
  }
  get cellType(): string {
    return this._cellType;
  }

  @Output() onDetailsLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean = false;
  cellDetails: RecordModel[];
  orderByOptions = [];

  constructor(private recordService: RecordService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.orderByOptions = [
      {
        label: this.translateService.instant("RECORD.sort-by.update", {
          default: "Last updated",
        }),
        value: RecordOrderByEnum.RECORDS_UPDATED_DESC,
      },
      {
        label: this.translateService.instant("RECORD.sort-by.creation", {
          default: "Creation date",
        }),
        value: RecordOrderByEnum.RECORDS_CREATION_DESC,
      },
      {
        label: this.translateService.instant("RECORD.sort-by.expiration", {
          default: "First expiration",
        }),
        value: RecordOrderByEnum.RECORDS_EXPIRATION_ASC,
      },
    ];
  }

  loadDetails(cellType: string, limitOffset: number, orderBy: string = RecordOrderByEnum.RECORDS_UPDATED_DESC) {
    let now = new Date();
    this.isLoading = true;
    this.onDetailsLoading.emit(true);
    this.recordService
      .getRecordsSummaryCell(null, this._cellType, [RecordTypeEnum.ONEWAY], orderBy, limitOffset, this.limitCount, [
        ResponseLevelEnum.MINIMIZE,
      ])
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.totalCount = value.totalCount;
        this.limitOffset = value.limitOffset;
        this.limitCount = value.limitCount;

        this.cellDetails = value.cellDetails;
        this.isLoading = false;
        this.onDetailsLoading.emit(false);
      });
  }

  next() {
    if (this.limitOffset + this.limitCount < this.totalCount) {
      this.loadDetails(this._cellType, this.limitOffset + this.limitCount, this.selectedOrderBy);
    }
  }

  prev() {
    if (this.limitOffset != 0) {
      if (this.limitOffset - this.limitCount < 0) {
        this.loadDetails(this._cellType, 0, this.selectedOrderBy);
      } else {
        this.loadDetails(this._cellType, this.limitOffset - this.limitCount, this.selectedOrderBy);
      }
    }
  }

  reset() {
    this.loadDetails(this._cellType, 0, this.selectedOrderBy);
  }

  handleSorting(event): void {
    this.selectedOrderBy = event.value;
    this.loadDetails(this.cellType, 0, this.selectedOrderBy);
  }
}
